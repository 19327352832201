import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Box, Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { routes } from '../../../../constants/routes';

const useStyles = makeStyles(() => ({
  footerLinkStyle: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#666666',
    lineHeight: '22px',
    marginBottom: '8px',
  },
}));

function FooterEndMenu() {
  const classes = useStyles();
  return (
    <Box width={1}>
      <Divider />
      <Box
        paddingTop={3}
        display="flex"
        alignItems="flex-start"
        flexDirection="space-between"
        width={1}
      >
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <Typography
              className={classes.footerLinkStyle}
              component="p"
              variant="subtitle1"
            >
              © Darmowe webinary
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Link to={routes.REGULATIONS.url}>
                  <Typography
                    className={classes.footerLinkStyle}
                    component="p"
                    variant="h5"
                  >
                    Regulamin
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link to={routes.PRIVACY_POLICY.url}>
                  <Typography
                    className={classes.footerLinkStyle}
                    component="p"
                    variant="h5"
                  >
                    Polityka prywatności
                  </Typography>
                </Link>
              </Grid>
              {/* TODO: create page B2B */}
              {/* <Grid item> */}
              {/*  <Link to={routes.B2B.url}> */}
              {/*    <Typography className={classes.footerLinkStyle} component="p" variant="h5"> */}
              {/*      Współpraca B2B */}
              {/*    </Typography> */}
              {/*  </Link> */}
              {/* </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default FooterEndMenu;
