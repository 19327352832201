import styled from 'styled-components';
import { Chip } from '@material-ui/core';

export const StyledChip = styled(Chip)`
  &.MuiChip-outlined {
    border-color: #cdd9fa;
  }

  &.active {
    &.MuiChip-root {
      color: #336bf6;
    }
    &.MuiChip-outlined {
      border-color: #cdd9fa;
      background-color: #ecf1fd;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    margin: 8px;
  }

  & > *:first-child {
    margin-left: 0;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;
