import { TProcessState } from '../../types/state/IAppState';

export enum AuthActionTypes {
  AUTH_INIT = 'AUTH_INIT',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
  AUTH_CLEAN = 'AUTH_CLEAN',
  AUTH_LOGIN_USER = 'AUTH_LOGIN_USER',
  AUTH_LOGIN_USER_SUCCESS = 'AUTH_LOGIN_USER_SUCCESS',
  AUTH_SET_PROCESS = 'AUTH_SET_PROCESS',
  AUTH_SET_STATUS = 'AUTH_SET_STATUS',
  AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN',
  AUTH_GOOGLE = 'AUTH_GOOGLE',
}

export interface IAuthState {
  process?:
  | undefined
  | {
    authUserLogin: TProcessState;
  };
  status?:
  | undefined
  | {
    isUserLogin: boolean;
  };
}

export interface IAuthUserLogin {
  username: string;
  password: string;
}

export interface ITokenObject {
  accessToken: string | null;
  refreshToken: string | null;
  accessTokenExpiration?: string | undefined | null;
}

export interface IUserAuthTokenObject {
  userAuthToken: string | null;
}

export interface IAuthLoginResponse extends ITokenObject {}

export interface IAuthUserLoginPayload {
  payload: IAuthUserLogin;
}
export interface IAuthLoginUserSuccessPayload {
  payload: IAuthLoginResponse;
}

export enum TypeProcess {
  authUserLogin = 'authUserLogin',
}

export type TTypeProcess = TypeProcess;

export interface IAuthSetProcess {
  typeProcess: TTypeProcess;
  value: TProcessState;
}

export interface IAuthSetProcessPayload {
  payload: IAuthSetProcess;
}

export enum TypeStatus {
  isUserLogin = 'isUserLogin',
}

export type TTypeStatus = TypeStatus;
export type TStatusState = boolean;

export interface IAuthSetStatus {
  typeStatus: TTypeStatus;
  value: TStatusState;
}

export interface IAuthSetStatusPayload {
  payload: IAuthSetStatus;
}

export interface IAuthGoogle {
  accessToken: string;
}

export interface IAuthGooglePayload {
  payload: IAuthGoogle;
}
