import React from 'react';
import { Field, useField } from 'formik';
import { Box, TextField } from '@material-ui/core';

const StyledTextField = ({
  helperText,
  field,
  label,
  type,
  size,
  defaultValue,
  ...rest
}: any) => {
  const [hookField, meta] = useField(field);

  const isError = () => !!(meta.error && meta.touched);

  return (
    <TextField
      fullWidth
      {...hookField}
      defaultValue={defaultValue}
      label={label}
      type={type}
      variant="outlined"
      size={size}
      error={isError()}
      helperText={isError() ? meta.error : helperText}
      {...rest}
    />
  );
};

function Textarea({
  type,
  label,
  name,
  paddingBottom,
  size,
  defaultValue,
  rows,
  fullWidth,
  multiline,
  variant,
  helperText,
  ...rest
}: any) {
  return (
    <Box paddingBottom={paddingBottom}>
      <Field
        fullWidth
        name={name}
        component={StyledTextField}
        type={type}
        label={label}
        defaultValue={defaultValue}
        rows={rows}
        multiline={multiline}
        variant={variant}
        helperText={helperText}
        {...rest}
      />
    </Box>
  );
}

export default Textarea;
