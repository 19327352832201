import React, { FC } from 'react';
import {
  Avatar,
  Box,
  Button,
  Grid,
  MenuItem,
  useMediaQuery,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import { IUserMenuProps } from './index';
import MenuList, { TmenuRef } from '../../common/MenuList';
import { routes } from '../../constants/routes';
import theme from '../../scss/themes';

const UserMenu: FC<IUserMenuProps> = ({ username, userImage }) => {
  const [anchorEl, setAnchorEl] = React.useState<TmenuRef>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <Box>
      {isSm ? (
        <>
          <Link to={routes.USER.url}>
            <ListItem>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Avatar src={userImage || ''} />
                </Grid>
                <Grid item>
                  <Typography variant="body2">{username}</Typography>
                </Grid>
              </Grid>
            </ListItem>
          </Link>
          <Divider />
          <Link to={routes.USER.url}>
            <ListItem>
              <ListItemText>Twój profil</ListItemText>
            </ListItem>
          </Link>

          <Divider />
          <Link to={routes.ADD_WEBINAR.url}>
            <ListItem>
              <ListItemText>Dodaj webinar</ListItemText>
            </ListItem>
          </Link>

          <Divider />
          <Link to={routes.LOGOUT.url}>
            <ListItem>
              <ListItemText>Wyloguj</ListItemText>
            </ListItem>
          </Link>
        </>
      ) : (
        <>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Avatar src={userImage || ''} />
              </Grid>
              <Grid item>
                <Typography variant="body2">{username}</Typography>
              </Grid>
              <Grid item>
                <Box display="flex">
                  <ExpandMoreIcon />
                </Box>
              </Grid>
            </Grid>
          </Button>
          <MenuList menuRef={anchorEl} setRef={setAnchorEl}>
            <Link to={routes.USER.url}>
              <MenuItem>Twój profil</MenuItem>
            </Link>
            <Link to={routes.ADD_WEBINAR.url}>
              <MenuItem>Dodaj webinar</MenuItem>
            </Link>
            <Link to={routes.LOGOUT.url}>
              <MenuItem>Wyloguj</MenuItem>
            </Link>
          </MenuList>
        </>
      )}
    </Box>
  );
};

export default UserMenu;
