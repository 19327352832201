export enum CategoriesActionTypes {
  GET_CATEGORIES_DATA = 'GET_CATEGORIES_DATA',
  GET_CATEGORIES_DATA_FAILED = 'GET_CATEGORIES_DATA_FAILED',
  CATEGORIES_DATA_DONE = 'CATEGORIES_DATA_DONE',
  CATEGORIES_SET_ACTIVE = 'CATEGORIES_SET_ACTIVE',
  CATEGORIES_CLEAN_ACTIVE = 'CATEGORIES_CLEAN_ACTIVE',
}

export type ICategoriesSetActive = null | number;

export interface ICategoriesSetActivePayload {
  payload: {
    id: ICategoriesSetActive;
  };
}
