import styled from 'styled-components';
import theme from '../../../../../scss/themes';

const getColor = (isFocus: boolean, isError: boolean) => {
  if (isError) {
    return 'red';
  }
  if (isFocus) {
    return '#336BF6';
  }
  return 'rgba(0, 0, 0, 0.23)';
};

export const StyledEditor = styled.div<{ isFocus: boolean; isError: boolean }>`
  margin-bottom: 20px;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';

  .rdw-editor-main {
    border-radius: 4px;
    color: ${theme.palette.text.primary};
    border-style: solid;
    border-color: ${({ isFocus, isError }) => getColor(isFocus, isError)};

    border-width: ${({ isFocus }) => (isFocus ? 2 : 1)}px;
    padding: ${({ isFocus }) => (isFocus ? '17.5px 13px' : '18.5px 14px')};

    .public-DraftEditor-content {
      min-height: 350px;
    }

    .public-DraftStyleDefault-block {
      margin: 0 0 1em 0;
    }
  }
`;
