/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useEffect, useState, FocusEvent } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import SocialButtonAuth from 'common/SocialButtonAuth';
import CenterTitle from 'common/CenterTitle';
import FormikControl, {
  EControlType,
} from 'components/Form/common/FormikControl';
import FormikStep, {
  IFormikStepProps,
} from 'components/Form/common/FormikStep';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { userCheckExist } from 'store/user/actions';
import { TRootState } from 'types/reduxTypes';
import { getUserRegister } from 'store/user/selectors';
import { FormikErrors } from 'formik/dist/types';
import { TProcessState } from '../../../../types/state/IAppState';
import CallbackIcon from '../../../../common/CallbackIcon';
import { ErrorValidation } from '../../common/const';
import { TUserCheck } from '../../../../store/user/types';
import RegisterAgreement from '../../common/RegisterAgreement';

const DetailsUserStep: FC<IFormikStepProps> = ({ validationSchema }) => {
  const [userCheckEmailState, setUerCheckEmailState] = useState<TProcessState>(
    null
  );
  const [emailIsAvailable, setEmailIsAvailable] = useState<null | boolean>(
    null
  );
  const [
    userCheckUsernameState,
    setUerCheckUsernameState,
  ] = useState<TProcessState>(null);
  const [usernameIsAvailable, setUsernameIsAvailable] = useState<
    null | boolean
  >(null);
  const registerValidatedObject = useSelector((state: TRootState) =>
    getUserRegister(state)
  );
  const dispatch = useDispatch();
  const { validateField, setTouched, errors, touched } = useFormikContext();

  useEffect(() => {
    if (registerValidatedObject) {
      const { email, username } = registerValidatedObject;

      if (email) {
        setUerCheckEmailState(email.userCheckExistState);
        setEmailIsAvailable(email.isAvailable);
        validateField('email');
      }

      if (username) {
        setUerCheckUsernameState(username.userCheckExistState);
        setUsernameIsAvailable(username.isAvailable);
        validateField('username');
      }
    }
  }, [registerValidatedObject]);

  const handleCheckFieldAvailable = (
    e: FocusEvent<HTMLInputElement>,
    typeCheck: TUserCheck,
    fieldName: string,
    errorMessage: string,
    errorObject: FormikErrors<any>
  ) => {
    validateField(fieldName);
    const fieldError = errorObject[fieldName];
    const currentEmail = registerValidatedObject?.[typeCheck]?.value;
    if (
      currentEmail !== e.target.defaultValue &&
      (fieldError === undefined || fieldError === errorMessage) &&
      Object.keys(errorObject).length !== 0
    ) {
      dispatch(
        userCheckExist({
          typeCheck,
          value: e.target.defaultValue.toLowerCase(),
        })
      );
    }
    setTouched({ ...touched, [typeCheck]: true });
  };

  return (
    <FormikStep validationSchema={validationSchema}>
      <CenterTitle title="Dołącz do nas już dziś" />
      <Grid container spacing={2} alignItems="center" direction="column">
        <Grid item xs>
          <SocialButtonAuth title="Zarejestruj się za pomocą" />
        </Grid>
        <Grid item xs>
          <Box
            margin="auto"
            alignItems="center"
            display="flex"
            justifyContent="center"
          >
            <Typography variant="h4">Lub uzupełnij dane</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box width={1} display="flex" alignItems="center">
        <Box flex={1}>
          <FormikControl
            onBlur={(event: FocusEvent<HTMLInputElement>) => {
              handleCheckFieldAvailable(
                event,
                'email',
                'email',
                ErrorValidation.emailIsUnAvailable,
                errors
              );
            }}
            controlType={EControlType.INPUT}
            type="text"
            label="Adres email"
            name="email"
            lowerCase
          />
        </Box>
        {emailIsAvailable !== null && (
          <CallbackIcon
            state={userCheckEmailState}
            isAvailable={emailIsAvailable}
          />
        )}
      </Box>

      <Box width={1} display="flex" alignItems="center">
        <Box flex={1}>
          <FormikControl
            onBlur={(event: FocusEvent<HTMLInputElement>) => {
              handleCheckFieldAvailable(
                event,
                'username',
                'username',
                ErrorValidation.usernameIsUnAvailable,
                errors
              );
            }}
            controlType={EControlType.INPUT}
            type="text"
            label="Nazwa użytkownika"
            name="username"
            lowerCase
          />
        </Box>
        {usernameIsAvailable !== null && (
          <CallbackIcon
            state={userCheckUsernameState}
            isAvailable={usernameIsAvailable}
          />
        )}
      </Box>

      <FormikControl
        controlType={EControlType.INPUT}
        type="password"
        label="Hasło użytkownika"
        name="password"
        helperText="Hasło powinno mieć min. 8 znaków i jedną wielką literę"
      />
      <FormikControl
        controlType={EControlType.INPUT}
        type="password"
        label="Powtórz hasło użytkownika"
        name="passwordConfirmation"
      />
      <RegisterAgreement />
    </FormikStep>
  );
};

export default DetailsUserStep;
