import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import CenterTitle from 'common/CenterTitle';
import FormikControl, {
  EControlType,
} from 'components/Form/common/FormikControl';
import FormikStep, {
  IFormikStepProps,
} from 'components/Form/common/FormikStep';
import RegisterAgreement from '../../common/RegisterAgreement';

const DetailsUserStep: FC<IFormikStepProps> = ({ validationSchema }) => (
  <FormikStep validationSchema={validationSchema}>
    <Box margin={2}>
      <CenterTitle
        title="Już prawie skończyone!"
        subtitle="Zamierzasz utworzyć nowe konto używając loginu z Google"
      />
    </Box>
    <FormikControl
      controlType={EControlType.INPUT}
      type="text"
      label="Imię"
      name="name"
    />

    <FormikControl
      controlType={EControlType.INPUT}
      type="text"
      label="Nazwisko"
      name="surname"
    />
    <RegisterAgreement />
  </FormikStep>
);

export default DetailsUserStep;
