export const ErrorValidation = {
  required: 'To pole jest wymagane',
  emailIncorrectValidateForm: 'Nieprawidłowy format wiadomości e-mail',
  passwordIncorrectValidate: 'Nieprawidłowe hasło',
  passwordPatternError:
    'Hasło powinno mieć min. 8 znaków i jedną wielką literę',
  passwordMatch: 'Hasła muszą być zgodne',
  checkAgreement: 'Zaznacz zgodę',
  emailIsUnAvailable: 'Ten email jest już używany',
  usernameIsUnAvailable: 'Ta nazwa użytkownika jest już zajęta',
};
