/* eslint-disable react/jsx-props-no-spreading */
import { Button, Grid } from '@material-ui/core';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import AlreadyAccount from 'common/AlreadyAccount';
import { LoginFormInitialValues } from './index';
import ButtonCallback from '../../../common/ButtonCallback';
import { TRootState } from '../../../types/reduxTypes';
import { selectAuthProcess } from '../../../store/auth/selectors';
import { TypeProcess } from '../../../store/auth/types';

export interface FormikStepperProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  initialValues: LoginFormInitialValues;
  onSubmit: any;
  step?: number;
  setStep?: any;
  startButton: string;
  endButton?: string;
}

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStepper({
  step = 0,
  setStep = 0,
  children,
  onSubmit,
  startButton,
  endButton = 'Zarejestruj',
  ...props
}: FormikStepperProps) {
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<FormikStepProps>[];
  const currentChild = childrenArray[step];
  const authUserLogin = useSelector((state: TRootState) =>
    selectAuthProcess(state, TypeProcess.authUserLogin)
  );
  const isLastStep = () => step === childrenArray.length - 1;

  const ActualButtonContent = () => {
    switch (step) {
      case 0:
        return startButton;
      case childrenArray.length - 1:
        return endButton;
      default:
        return 'Dalej';
    }
  };

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await onSubmit(values, helpers);
        } else {
          setStep((s: number) => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          {currentChild}
          <Grid container spacing={2} justify="center">
            {step > 0 ? (
              <Grid item>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  onClick={() => setStep((s: number) => s - 1)}
                >
                  Back
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              {(isSubmitting || isLastStep()) && (
                <ButtonCallback
                  state={authUserLogin}
                  variant="contained"
                  color="primary"
                  type={authUserLogin !== 'done' ? 'submit' : 'button'}
                >
                  {ActualButtonContent()}
                </ButtonCallback>
              )}
            </Grid>
          </Grid>
          <AlreadyAccount />
        </Form>
      )}
    </Formik>
  );
}
