import WebinarRowList from './WebinarRowList';
import { IWebinar } from '../../types/webinar/IWebinar';

export interface IWebinarRowListProps {
  webinarsData: IWebinar[] | [];
}

export interface IDayListProps {
  length: number;
  mapArray: any;
}

export default WebinarRowList;
