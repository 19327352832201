import React, { FC } from 'react';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ICategoryCardProps } from './index';
import theme from '../../scss/themes';
import { StyledPaper, StyledTypography } from './styled';
import { routes } from '../../constants/routes';

const CategoryCard: FC<ICategoryCardProps> = ({
  categoryName,
  categoryLink,
  setCardSize,
}) => {
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const cardSize = () => {
    if (isMd) {
      return 3;
    }
    if (isSm) {
      return 6;
    }
    return 12;
  };

  return (
    <Grid item xs={cardSize()} sm={setCardSize || cardSize()}>
      <Link to={`${routes.CATEGORY.url}/${categoryLink}`}>
        <Box margin="auto" maxWidth="280px" maxHeight="90px">
          <StyledPaper>
            <Box margin="auto" padding={isSm ? '32px 0' : '20px 0'}>
              <StyledTypography
                noWrap
                align="center"
                variant={isSm ? 'subtitle1' : 'subtitle2'}
              >
                {categoryName}
              </StyledTypography>
            </Box>
          </StyledPaper>
        </Box>
      </Link>
    </Grid>
  );
};
export default CategoryCard;
