import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { formatTimeHoursMinutes } from 'utils/formatTimeHoursMinutes';
import { IWebinarRowProps } from './index';
import { LayoutBlock, RowOutside, RowContent, StyledDivider } from './styled';
import ButtonSigninWebinar from '../ButtonSigninWebinar';
import { routes } from '../../constants/routes';

const WebinarRow = ({ webinar }: IWebinarRowProps) => {
  const {
    id,
    start_time: startTime,
    created_by: createdBy,
    title,
    slug,
    event_page: eventPage,
  } = webinar;
  const formattedTime = formatTimeHoursMinutes(startTime);
  const webinarLink = `${routes.WEBINAR.url}/${slug || id}`;

  return (
    <LayoutBlock>
      <Link to={webinarLink}>
        <RowOutside>
          <Typography variant="subtitle2" color="secondary">
            {formattedTime}
          </Typography>
          {/* TODO: dodać funkcje serduszka */}
          {/*  <IconButton aria-label="add favorite" color="primary"> */}
          {/*    <FavoriteBorderOutlinedIcon /> */}
          {/*  </IconButton> */}
        </RowOutside>
        <RowContent>
          <Typography variant="caption">
            {`Organizator: ${createdBy}`}
          </Typography>
          <Typography variant="h4">{title}</Typography>
          {/* TODO: dodać funkcje kategorii */}
          {/* <ClipContainer tags={tags} /> */}
        </RowContent>
      </Link>
      <RowOutside>
        <Link to={webinarLink}>
          <Typography variant="subtitle2" color="primary">
            Szczegóły wydarzenia
          </Typography>
        </Link>
        <ButtonSigninWebinar
          title="Zapisz się"
          webinarID={id}
          fullWidth
          eventPage={eventPage}
        />
      </RowOutside>
      <StyledDivider light />
    </LayoutBlock>
  );
};

export default WebinarRow;
