import { useParams } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categoriesSetActive } from 'store/categories/actions';
import { TRootState } from 'types/reduxTypes';
import { getCategoryBySlug } from 'store/categories/selectors';
import WebinarList from './index';

export interface RouterParams {
  categoryParams?: string | undefined;
}

const WebinarListRouterParams = () => {
  const { categoryParams } = useParams<{ categoryParams: string }>();

  const currentCategory = useSelector((state: TRootState) =>
    getCategoryBySlug(state, categoryParams)
  );

  if (currentCategory) {
    const dispatch = useDispatch();
    dispatch(categoriesSetActive(currentCategory?.id));
  }

  return <WebinarList categoryName={currentCategory?.name} />;
};

export default WebinarListRouterParams;
