import constLocalStorage from 'constants/localStorage';
import APILogin from 'api/login';

const getFreshToken = async () => {
  const accessTokenExpiration: string | null = localStorage.getItem(
    constLocalStorage.userAccessTokenExpiration,
  );
  const userAuthToken: string | null = localStorage.getItem(
    constLocalStorage.userAuthToken,
  );
  if (!accessTokenExpiration) {
    return new Error('accessTokenExpiration Error');
  }
  const dateNow = Date.now();
  const dateToken: Date = new Date(accessTokenExpiration);
  if (dateNow > dateToken.getTime() || !userAuthToken) {
    try {
      const resp = await APILogin.refreshToken();
    } catch (error) {
      return new Error(`Error refresh token: ${error}`);
    }
  }
  return localStorage.getItem(constLocalStorage.userAuthToken);
};

export default {
  getFreshToken,
};
