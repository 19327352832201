import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';
import { ITimeDetailsProps } from './index';
import { StyledFiberManualRecordIcon } from './styled';

const TimeDetails: FC<ITimeDetailsProps> = ({
  icon,
  date,
  startTime,
  endTime,
}) => (
  <Grid container spacing={1} alignItems="center">
    {icon && (
      <Grid item>
        <Typography component="div" variant="h5" color="textSecondary">
          <EventIcon color="inherit" />
        </Typography>
      </Grid>
    )}
    {date && (
      <Grid item>
        <Typography component="p" variant="h5" color="textSecondary">
          {date}
        </Typography>
      </Grid>
    )}
    {startTime && (
      <>
        <Grid item>
          <StyledFiberManualRecordIcon />
        </Grid>
        <Grid item>
          <Typography component="p" variant="h5" color="textSecondary">
            {startTime}
          </Typography>
        </Grid>
      </>
    )}
    {endTime && (
      <>
        <Grid item>
          <Typography component="p" variant="h5" color="textSecondary">
            -
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="p" variant="h5" color="textSecondary">
            {/* TODO:// czas rozpoczęcia  */}
            {endTime}
          </Typography>
        </Grid>
      </>
    )}
  </Grid>
);
export default TimeDetails;
