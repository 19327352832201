import { connect } from 'react-redux';
import Component from './NewSlider';
import { IWebinar } from '../../types/webinar/IWebinar';
import { getWebinarsData } from '../../store/webinars/actions';
import { getMapWebinars } from '../../store/webinars/selectors';
import { IWebinarCardProps } from '../WebinarCard';
import { TRootState } from '../../types/reduxTypes';

export interface INewSliderProps {
  mapWebinarsProps?: IWebinar[] | [];
  mapWebinars: IWebinar[] | [];
  getDataWebinarsRequested: any;
  buttonLink?: string | false;
  alignButton?: 'right' | 'left';
}

export interface ImapWebinar extends IWebinarCardProps {
  id: number;
}

const mapStateToProps = (state: TRootState, ownProps: any) => {
  console.log(ownProps.buttonAlign);
  if (ownProps.mapWebinarsProps) {
    return {
      mapWebinars: ownProps.mapWebinarsProps,
      alignButton: ownProps.buttonAlign,
    };
  }

  return {
    mapWebinars: getMapWebinars(state),
    alignButton: ownProps.buttonAlign,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getDataWebinarsRequested: () => dispatch(getWebinarsData()),
});

const NewSlider = connect(mapStateToProps, mapDispatchToProps)(Component);

export default NewSlider;
