import React from 'react';
import LayoutFeature from './LayoutFeature';

export interface ILayoutFeatureProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

export default LayoutFeature;
