/* eslint-disable react/jsx-props-no-spreading */
import { Grid, useMediaQuery } from '@material-ui/core';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { NewsletterFormInitialValues } from './index';
import theme from '../../../scss/themes';
import DisplayFormikState from '../common/DisplayFormikState';
import ButtonCallback from '../../../common/ButtonCallback';
import { getUserState } from '../../../store/user/selectors';
import { TRootState } from '../../../types/reduxTypes';
import { TypeProcess } from '../../../store/user/types';

export interface FormikStepperProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  initialValues: NewsletterFormInitialValues;
  onSubmit: any;
  step: number;
  setStep: any;
}

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStepper({
  step,
  setStep,
  children,
  onSubmit,
  ...props
}: FormikStepperProps) {
  const childrenArray = React.Children.toArray(
    children,
  ) as React.ReactElement<FormikStepProps>[];
  const currentChild = childrenArray[step];
  const addToNewsletter = useSelector((state: TRootState) =>
    getUserState(state, TypeProcess.addToNewsletter),
  );
  const isFirstStep = () => step === 0;

  const isXs = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  });

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isFirstStep()) {
          await onSubmit(values, helpers);
        } else {
          setStep((s: number) => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      {(propsIN) => (
        <>
          <Form autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs>
                {currentChild}
              </Grid>
              <Grid item>
                <ButtonCallback
                  state={addToNewsletter}
                  variant="contained"
                  color="primary"
                  type={addToNewsletter !== 'done' ? 'submit' : 'button'}
                >
                  {isXs ? 'Zapisz' : 'Zapisz do newslettera'}
                </ButtonCallback>
              </Grid>
            </Grid>
          </Form>
          {process.env.NODE_ENV !== 'production' ? (
            <DisplayFormikState {...propsIN} />
          ) : null}
        </>
      )}
    </Formik>
  );
}
