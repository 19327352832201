import React from 'react';
import { Box } from '@material-ui/core';
import Logo from '../../../common/Logo';
import FooterMenu from './FooterMenu';
import FooterEndMenu from './FooterEndMenu';

function Footer() {
  return (
    <Box
      paddingTop={5}
      paddingBottom={5}
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      width={1}
    >
      <Logo />

      <FooterMenu />
      <FooterEndMenu />
    </Box>
  );
}

export default Footer;
