import React from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from 'common/FormCard';
import { routes } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { authLoginUser } from 'store/auth/actions';
import { TRootState } from 'types/reduxTypes';
import { selectAuthStatus } from 'store/auth/selectors';
import { TypeStatus } from 'store/auth/types';
import { FormikStepper } from './FormikStepper';
import { LoginFormInitialValues } from './index';
import FormFirstStep from './FormStep/FormFirstStep';

const LoginForm = () => {
  const dispatch = useDispatch();
  const isUserLogin = useSelector((state: TRootState) =>
    selectAuthStatus(state, TypeStatus.isUserLogin),
  );
  const initialValues: LoginFormInitialValues = {
    username: '',
    password: '',
  };

  if (process.env.NODE_ENV !== 'production') {
    initialValues.username = 'seba';
    initialValues.password = 'Blizard32';
  }

  const handleLogin = (values: LoginFormInitialValues) => {
    dispatch(authLoginUser(values));
  };
  if (isUserLogin) {
    return <Redirect to={routes.USER.url} />;
  }

  return (
    <FormCard maxWidth={570}>
      <FormikStepper
        initialValues={initialValues}
        startButton="Zaloguj"
        onSubmit={handleLogin}
      >
        <FormFirstStep />
      </FormikStepper>
    </FormCard>
  );
};

export default LoginForm;
