import React, { useState } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { Box } from '@material-ui/core';
import moment from 'moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const StyledDatePickerField = ({ field, label, size }: any) => {
  const [hookField, meta] = useField(field);
  const [selectedDate, setSelectedDate] = useState<
  moment.Moment | null | string
  >(moment());
  const { setFieldValue } = useFormikContext();

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      date.set('hour', 0);
      date.set('minute', 0);
      date.set('second', 0);
      date.set('millisecond', 0);
    }

    if (selectedDate && date) {
      setSelectedDate(date.format());
      setFieldValue(field.name, date.format());
    }
  };

  const isError = () => Boolean(meta.error && meta.touched);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        {...field}
        fullWidth
        label={label}
        disableToolbar
        size={size}
        variant="inline"
        autoOk
        format="DD MM, yyyy"
        inputVariant="outlined"
        value={selectedDate}
        onChange={handleDateChange}
        error={isError()}
        helperText={isError() ? meta.error : ''}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

function DatePicker({
  type,
  label,
  name,
  paddingBottom,
  size,
  helperText,
  ...rest
}: any) {
  return (
    <Box paddingBottom={paddingBottom}>
      <Field
        fullWidth
        name={name}
        component={StyledDatePickerField}
        type={type}
        label={label}
        helperText={helperText}
        {...rest}
      />
    </Box>
  );
}

export default DatePicker;
