import React, { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, Paper } from '@material-ui/core';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { routes } from 'constants/routes';
import AppLayout from 'layout/AppLayout';
import CenterTitle from 'common/CenterTitle';
import Title from 'common/Title';
import NewSlider from 'common/NewSlider';
import SidebarLayout from 'layout/SidebarLayout';
import CalendarMenu from 'components/CalendarMenu';
import CalendarDatePicker from 'components/CalendarDatePicker';
import CategoryClip from 'common/CategoryClip';
import { IWebinar } from 'types/webinar/IWebinar';
import WebinarRowList from 'components/WebinarRowList';
import { useSelector } from 'react-redux';
import { TRootState } from 'types/reduxTypes';
import { selectWebinarsProcess } from 'store/webinars/selectors';
import { WebinarsTypeProcess } from 'store/webinars/types';
import CategoryList from 'components/CategoryList';
import { IMenuObject, IWebinarListProps } from './index';
import { menuInitialContent, menuItemAllWebinar } from './menuConst';

export interface CategoryRouterParams {
  categoryParams?: string | undefined;
}

const WebinarList = ({
  mapWebinars,
  getDataWebinarsRequested,
  lastSelectedWebinars,
  selectWebinar,
  webinarCurrentSelector,
  webinarsRequestState,
  webinarSelectedState,
  categoryName,
}: IWebinarListProps) => {
  const [webinarsData, setWebinarsData] = useState<IWebinar[] | []>([]);
  const [menuContent, setMenuContent] = useState<IMenuObject[]>([
    ...menuInitialContent,
  ]);

  const webinarProcess = useSelector((state: TRootState) =>
    selectWebinarsProcess(state, WebinarsTypeProcess.webinarLoading)
  );

  useEffect(() => {
    if (mapWebinars.length === 0) {
      getDataWebinarsRequested();
    }
  }, []);

  useEffect(() => {
    const { startTimeMin, startTimeMax, category } = webinarCurrentSelector;
    const possibleFilters = [startTimeMin, startTimeMax, category];
    if (possibleFilters.some((filter) => filter !== null)) {
      selectWebinar(startTimeMin, startTimeMax, null, category);
    }
    if (
      menuContent[0].name !== menuItemAllWebinar.name &&
      possibleFilters.some((filter) => filter !== null)
    ) {
      setMenuContent([menuItemAllWebinar, ...menuContent]);
    } else if (possibleFilters.every((filter) => filter === null)) {
      setMenuContent([...menuInitialContent]);
      setWebinarsData(mapWebinars);
    }
  }, [webinarCurrentSelector]);

  useEffect(() => {
    if (webinarSelectedState === 'done') {
      setWebinarsData(lastSelectedWebinars);
    }
  }, [webinarSelectedState]);

  useEffect(() => {
    if (webinarsRequestState === 'done') {
      setWebinarsData(mapWebinars);
    }
  }, [webinarsRequestState]);

  const isAllCategory = useRouteMatch(routes.CATEGORY.url);
  const isCategory = useRouteMatch(routes.CATEGORY_ROUTER.url);
  const isCalendar = useRouteMatch(routes.CALENDAR.url);
  const isTag = useRouteMatch(routes.TAG.url);

  const getCurrentData = () => {
    const dataSet = {
      title: '',
      subtitle: '',
      contentTitle: '',
    };

    if (isAllCategory || isTag) {
      dataSet.title = 'Lista Wszystkich Kategorii';
    }

    if (isCategory) {
      dataSet.title = `Lista Webinarów z Kategorii ${categoryName}`;
      dataSet.contentTitle = `Nadchodzące wydarzenia:  ${webinarsData.length}`;
    }

    if (isCalendar) {
      dataSet.title = 'Pora na porcję nowej wiedzy';
      dataSet.subtitle =
        'Zdobywaj fachową wiedzę, nie wychodząc z domu. W dodatku zupełnie za darmo!';
      dataSet.contentTitle = `Nadchodzące wydarzenia:  ${webinarsData.length}`;
    }

    return dataSet;
  };

  const dataContent = getCurrentData();
  return (
    <AppLayout>
      <Box display="flex" flexDirection="column">
        <CenterTitle
          title={dataContent.title || ''}
          subtitle={dataContent.subtitle || ''}
        />
      </Box>
      <Title title="Popularne webinary" component="h3" variant="h2" />
      <NewSlider buttonLink={false} />
      <SidebarLayout
        contentTitle={`${dataContent.contentTitle}`}
        layoutContent={
          <Paper elevation={3}>
            <Box
              display="flex"
              height="100vh"
              flexDirection="column"
              alignItems="stretch"
              padding="32px"
              overflow="hidden"
            >
              <Switch>
                <Route
                  exact
                  path={[routes.CATEGORY_ROUTER.url, routes.CALENDAR.url]}
                >
                  <CategoryClip />
                  {webinarProcess === 'done' ? (
                    <>
                      {webinarsData.length ? (
                        <WebinarRowList webinarsData={webinarsData} />
                      ) : (
                        <>
                          <Box height="50px" />
                          <CenterTitle
                            title="Brak webinarów"
                            subtitle="Spróbuj użyć innych filtrów"
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Box height="50px" />
                      <CenterTitle title="Daj nam chwilę, już ładuję webinary" />
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <CircularProgress />
                      </Box>
                    </>
                  )}
                </Route>
                <Route path={[routes.CATEGORY.url, routes.TAG.url]}>
                  <CategoryList setCardSize={6} />
                </Route>
              </Switch>
            </Box>
          </Paper>
        }
        layoutSidebar={
          <>
            <CalendarMenu menuContent={menuContent} />
            <Route
              exact
              path={[routes.CATEGORY_ROUTER.url, routes.CALENDAR.url]}
            >
              <CalendarDatePicker />
            </Route>
          </>
        }
      />
    </AppLayout>
  );
};

export default WebinarList;
