import React, { FC, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Modal from '@material-ui/core/Modal';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { CookiesPopupProps } from './index';
import { EmojiIcon } from '../../routes/PageHome/HomeInformation/styled';
import { routes } from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    margin: theme.spacing(2),
  },
}));

const CookiesPopup: FC<CookiesPopupProps> = ({ children }) => {
  const { pathname } = useLocation();
  const excludePath = [routes.PRIVACY_POLICY.url, routes.REGULATIONS.url];
  if (excludePath.includes(pathname)) {
    return null;
  }

  const [cookies, setCookie] = useCookies([]);

  useEffect(() => {}, []);

  const [open, setOpen] = React.useState(!cookies['dw-cookie']);
  const classes = useStyles();

  const handleAccept = () => {
    const valueCookie = {
      accept: true,
      date: new Date(),
    };

    const expires = new Date();
    expires.setDate(expires.getDate());

    setCookie('dw-cookie', valueCookie, {
      path: '/',
      maxAge: 60 * 60 * 24 * 30,
    });

    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className={classes.paper}>
      <Typography variant="h4">
        U nas są słodkie ciasteczka <EmojiIcon symbol="🍪" label="cookie" />
      </Typography>
      <Box paddingTop={2} paddingBottom={2}>
        <Typography variant="body1">
          Ta strona używa plików Cookies.
          <Link to={routes.PRIVACY_POLICY.url}>{' Dowiedz się więcej '}</Link> o
          celu ich używania i możliwości zmiany ustawień Cookies.
        </Typography>
      </Box>

      <Grid container spacing={2} justify="flex-end">
        <Grid item>
          <Button
            onClick={handleAccept}
            variant="contained"
            size="small"
            color="primary"
          >
            Akceptuję
          </Button>
        </Grid>
        <Grid item>
          <Link to={routes.PRIVACY_POLICY.url}>
            <Button variant="text" size="small" color="primary">
              Dowiedz się więcej
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      {children}
    </>
  );
};

export default CookiesPopup;
