import { MouseEventHandler } from 'react';
import ProgressBar from './ProgressBar';

export interface IProgressBarProps {
  progress: number;
  stepText?: string;
  onClick?: MouseEventHandler;
  isSkip?: boolean;
}

export default ProgressBar;
