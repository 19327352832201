import { AuthActionTypes, IAuthState } from './types';

const initialAuth: IAuthState = {};

const auth = (state = initialAuth, action: any) => {
  switch (action.type) {
    case AuthActionTypes.AUTH_SET_PROCESS:
      return {
        ...state,
        process: {
          ...state.process,
          [action.payload.typeProcess]: action.payload.value,
        },
      };
    case AuthActionTypes.AUTH_SET_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          [action.payload.typeStatus]: action.payload.value,
        },
      };
    case AuthActionTypes.AUTH_LOGOUT:
      return {};
    default:
      return state;
  }
};

export default auth;
