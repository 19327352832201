import { IMenuObject } from './index';
import { routes } from '../../constants/routes';
import { webinarCleanCurrentSelector } from '../../store/webinars/actions';

export const menuInitialContent: IMenuObject[] = [
  {
    name: 'Zapisane webinary',
    href: routes.SAVED_WEBINAR.url,
  },
];

export const menuItemAllWebinar: IMenuObject = {
  name: 'Wszystkie webinary',
  href: null,
  callback: webinarCleanCurrentSelector(),
};
