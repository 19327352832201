import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from 'store';
import { HelmetProvider } from 'react-helmet-async';
import WebinarListRouterParams from 'routes/WebinarList/WebinarListRouterParams';
import PageHome from './PageHome';
import ScrollToTop from './ScrollToTop';
import PageLogin from './PageLogin';
import PageRegister from './PageRegister';
// import PageSavedWebinar from './PageSavedWebinar';
// import PageLikedWebinar from './PageLikedWebinar';

import { routes } from '../constants/routes';
import UserProvider from '../common/UserProvider';

const PageWebinarRouterParams = lazy(
  () => import('./PageWebinar/PageWebinarRouterParams')
);
const PageLogout = lazy(() => import('./PageLogout'));
const PageB2B = lazy(() => import('./PageB2B'));
const PageAdd = lazy(() => import('./PageAdd'));
const PageRegulations = lazy(() => import('./PageRegulations'));
const PagePrivacyPolicy = lazy(() => import('./PagePrivacyPolicy'));
const PageBlog = lazy(() => import('./PageBlog'));
const PageBlogRouterParams = lazy(
  () => import('./PageBlogPost/PageBlogRouterParams')
);
const PagePlayer = lazy(() => import('./PagePlayer'));
const PageEmailVerificationRouterParams = lazy(
  () => import('./PageEmailVerification/PageEmailVerificationRouterParams')
);
const PageEmailVerificationInformation = lazy(
  () =>
    import(
      './PageEmailVerificationInformation/PageEmailVerificationInformation'
    )
);
const PageUser = lazy(() => import('./PageUser'));
const PageUserDeactivate = lazy(() => import('./PageUserDeactivate'));

export default function Router() {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <UserProvider>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            <Switch>
              <Route exact path={routes.HOME.url} render={() => <PageHome />} />
              <Route
                exact
                path={routes.B2B.url}
                render={() => (
                  <Suspense fallback="...">
                    <PageB2B />
                  </Suspense>
                )}
              />
              <Route
                exact
                path={[
                  routes.CATEGORY.url,
                  routes.CATEGORY_ROUTER.url,
                  routes.CALENDAR.url,
                  routes.TAG.url,
                ]}
                render={() => <WebinarListRouterParams />}
              />
              <Route
                exact
                path={routes.WEBINAR.url}
                render={() => (
                  <Suspense fallback="...">
                    <PageWebinarRouterParams />
                  </Suspense>
                )}
              />
              <Route
                exact
                path={routes.WEBINAR_ROUTER.url}
                render={() => (
                  <Suspense fallback="...">
                    <PageWebinarRouterParams />
                  </Suspense>
                )}
              />
              {/* <Route */}
              {/*  exact */}
              {/*  path={routes.SAVED_WEBINAR.url} */}
              {/*  render={() => ( */}
              {/*    <PageSavedWebinar /> */}
              {/*  )} */}
              {/* /> */}
              {/* <Route */}
              {/*  exact */}
              {/*  path={routes.LIKED_WEBINAR.url} */}
              {/*  render={() => ( */}
              {/*    <PageLikedWebinar /> */}
              {/*  )} */}
              {/* /> */}
              <Route
                exact
                path={routes.LOGIN.url}
                render={() => <PageLogin />}
              />
              <Route
                exact
                path={routes.EMAIL_VERIFICATION.url}
                render={() => (
                  <Suspense fallback="...">
                    <PageEmailVerificationInformation />
                  </Suspense>
                )}
              />

              <Route
                exact
                path={routes.EMAIL_VERIFICATION_ROUTER.url}
                render={() => (
                  <Suspense fallback="...">
                    <PageEmailVerificationRouterParams />
                  </Suspense>
                )}
              />
              <Route
                exact
                path={routes.LOGOUT.url}
                render={() => (
                  <Suspense fallback="...">
                    <PageLogout />
                  </Suspense>
                )}
              />
              <Route
                path={routes.REGISTER.url}
                render={() => <PageRegister />}
              />
              <Route
                exact
                path={routes.ADD_WEBINAR.url}
                render={() => (
                  <Suspense fallback="...">
                    <PageAdd />
                  </Suspense>
                )}
              />
              <Route
                exact
                path={routes.BLOG.url}
                render={() => (
                  <Suspense fallback="...">
                    <PageBlog />
                  </Suspense>
                )}
              />
              <Route
                exact
                path={routes.BLOG_ROUTER.url}
                render={() => (
                  <Suspense fallback="...">
                    <PageBlogRouterParams />
                  </Suspense>
                )}
              />
              <Route
                exact
                path={routes.REGULATIONS.url}
                render={() => (
                  <Suspense fallback="...">
                    <PageRegulations />
                  </Suspense>
                )}
              />
              <Route
                exact
                path={routes.PRIVACY_POLICY.url}
                render={() => (
                  <Suspense fallback="...">
                    <PagePrivacyPolicy />
                  </Suspense>
                )}
              />
              <Route
                exact
                path={routes.USER.url}
                render={() => (
                  <Suspense fallback="...">
                    <PageUser />
                  </Suspense>
                )}
              />
              <Route
                exact
                path={routes.USER_DEACTIVATE.url}
                render={() => (
                  <Suspense fallback="...">
                    <PageUserDeactivate />
                  </Suspense>
                )}
              />
              <Route
                exact
                path={routes.PLAYER.url}
                render={() => (
                  <Suspense fallback="...">
                    <PagePlayer />
                  </Suspense>
                )}
              />
            </Switch>
          </BrowserRouter>
        </UserProvider>
      </HelmetProvider>
    </Provider>
  );
}
