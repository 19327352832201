import React from 'react';
import { connect } from 'react-redux';
import {
  getLastSelectedWebinars,
  getMapWebinars,
  getWebinarCurrentSelector,
  getWebinarSelectedState,
  getWebinarsRequestState,
} from 'store/webinars/selectors';
import { TRootState } from 'types/reduxTypes';
import { ICategories } from 'types/category/ICategories';
import Component from './WebinarList';
import {
  IWebinar,
  IWebinarCurrentSelector,
} from '../../types/webinar/IWebinar';
import { TProcessState } from '../../types/state/IAppState';
import { getWebinarsData, selectWebinar } from '../../store/webinars/actions';

export interface IWebinarListProps {
  mapWebinars: IWebinar[] | [];
  getDataWebinarsRequested: any;
  lastSelectedWebinars: IWebinar[] | [];
  selectWebinar: any;
  webinarCurrentSelector: IWebinarCurrentSelector;
  webinarsRequestState: TProcessState;
  webinarSelectedState: TProcessState;
  categoryName: string | undefined;
}

export interface IMenuObject {
  name: string;
  href: null | string;
  callback?: null | any | undefined;
}

export interface CategoryRouterParams {
  categoryName?: string | undefined;
}

const mapStateToProps = (state: TRootState, ownProps: CategoryRouterParams) => {
  const { categoryName } = ownProps;

  return {
    categoryName,
    mapWebinars: getMapWebinars(state),
    lastSelectedWebinars: getLastSelectedWebinars(state),
    webinarCurrentSelector: getWebinarCurrentSelector(state),
    webinarsRequestState: getWebinarsRequestState(state),
    webinarSelectedState: getWebinarSelectedState(state),
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: CategoryRouterParams) => {
  const { categoryName } = ownProps;

  return {
    getDataWebinarsRequested: () => dispatch(getWebinarsData()),
    selectAllWebinar: () => dispatch(selectWebinar({})),
    selectWebinar(
      startTimeMin = '',
      startTimeMax = '',
      author = '',
      category = categoryName || '',
      tags = []
    ) {
      return dispatch(
        selectWebinar({
          startTimeMin,
          startTimeMax,
          author,
          category,
          tags,
        })
      );
    },
  };
};

const WebinarList = connect(mapStateToProps, mapDispatchToProps)(Component);

export default WebinarList;
