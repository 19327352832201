import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import { IButtonCallbackProps } from './index';
import { TProcessState } from '../../types/state/IAppState';

const statusIcon = (state: TProcessState) => {
  if (state === 'pending') {
    return <CircularProgress size="1rem" />;
  }

  if (state === 'done') {
    return <DoneIcon />;
  }

  if (state === 'error') {
    return <ErrorIcon />;
  }

  return null;
};

const ButtonCallback: FC<IButtonCallbackProps> = ({
  handleClick,
  state,
  variant = 'outlined',
  color = 'primary',
  type = 'button',
  children,
}) => {
  const [icon, setIcon] = useState<null | ReactElement>(null);
  useEffect(() => {
    setIcon(statusIcon(state));
    let interval: NodeJS.Timeout;

    if (state) {
      interval = setInterval(() => setIcon(null), 4000);
    }

    return () => clearInterval(interval);
  }, [state]);
  return (
    <Button
      startIcon={icon}
      disabled={state === 'pending'}
      variant={variant}
      color={color}
      onClick={handleClick || null}
      type={type}
    >
      {children}
    </Button>
  );
};

export default ButtonCallback;
