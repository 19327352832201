import React, { FC } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { ILayoutFeatureProps } from './index';
import theme from '../../scss/themes';

const LayoutFeature: FC<ILayoutFeatureProps> = ({
  icon,
  title,
  description,
}) => {
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <Grid item xs={isSm ? 12 : 4}>
      <Grid container spacing={3} alignItems="center" direction="column">
        <Grid item>
          <Box>{icon}</Box>
        </Grid>
        <Grid item>
          <Typography variant="h4">{title}</Typography>
        </Grid>
        <Box maxWidth="75%">
          <Grid item>
            <Typography align="center" variant="body2">
              {description}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LayoutFeature;
