import styled from 'styled-components';
import { Button, ButtonGroup } from '@material-ui/core';
import theme from '../../scss/themes';

export const StyledButtonGroup = styled(ButtonGroup)`
  width: 100%;

  ${theme.breakpoints.down('xs')} {
    button {
      border-radius: ${theme.spacing(0.5)}px;
      width: 100%;
      margin-bottom: ${theme.spacing(2)}px;
    }
  }
`;

export const FirstButton = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;
export const CenterButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;
export const LastButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
