import React, { ReactElement, useEffect, useState } from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import { ICallbackIconProps } from './index';

const isAvailableStatusIcon = (isAvailable: boolean) => {
  if (isAvailable) {
    return <DoneIcon color="primary" />;
  }

  if (!isAvailable) {
    return (
      <Box paddingBottom="17px">
        <ErrorIcon style={{ color: '#f44336' }} />
      </Box>
    );
  }

  return null;
};

const CallbackIcon = ({ state, isAvailable }: ICallbackIconProps) => {
  const [icon, setIcon] = useState<null | ReactElement>(null);
  useEffect(() => {
    setIcon(isAvailableStatusIcon(isAvailable));
    let interval: NodeJS.Timeout;

    if (state) {
      interval = setInterval(() => setIcon(null), 4000);
    }

    return () => clearInterval(interval);
  }, [state]);
  return (
    <Box
      paddingLeft={icon !== null ? '13px' : '' || state === 'pending'}
      paddingBottom="13px"
    >
      {state === 'pending' && <CircularProgress size="1rem" />}
      {state === 'done' && icon}
    </Box>
  );
};

export default CallbackIcon;
