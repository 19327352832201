import styled from 'styled-components';
import theme from '../../scss/themes';

export const Layout = styled.div`
  width: 100vw;

  ${theme.breakpoints.down('sm')} {
    padding-top: 80px;
  }
`;
export const MaxContentWrapper = styled.div`
  max-width: 1248px;
  padding: 0 16px;
  margin: 0 auto;
`;

export const BackgroundColor = styled('div')<{ bgColor: string }>`
  background: ${(props) => props.bgColor};
`;
