import React, { FC, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import CategoryCard from 'common/CategoryCard';
import { ICategoryListProps } from './index';

const CategoryList: FC<ICategoryListProps> = ({
  mapCategories,
  getCategoryDataRequested,
  quantityToShow = mapCategories.length,
  setCardSize,
}) => {
  useEffect(() => {
    getCategoryDataRequested();
  }, []);

  const popularCategories = mapCategories.slice(0, quantityToShow);
  return (
    <Box>
      <Grid container spacing={4}>
        {popularCategories.map(({ id, name, slug }) => (
          <CategoryCard
            categoryName={name}
            categoryLink={slug || String(id)}
            setCardSize={setCardSize}
            key={`${id}-${name}`}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default CategoryList;
