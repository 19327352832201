import React, { FC, useState } from 'react';
import EventIcon from '@material-ui/icons/Event';
import { Button, MenuItem } from '@material-ui/core';
import MenuList, { TmenuRef } from '../../common/MenuList';

const CalendarButton: FC = () => {
  const [anchorEl, setAnchorEl] = useState<TmenuRef>(null);
  const [nameButton, setNameButton] = useState<null | string>('Ten miesiąc');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getName = (
    event: React.MouseEvent<HTMLLIElement> | React.MouseEvent<HTMLAnchorElement>,
  ) => {
    const button = event.target as HTMLElement;
    setNameButton(button.textContent);
    setAnchorEl(null);
  };

  const menuItemList = [
    {
      name: 'Ten miesiąc',
    },
    {
      name: 'Poprzedni miesiąc',
    },
    {
      name: 'Wybierz datę',
    },
  ];

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleClick}
        startIcon={<EventIcon />}
      >
        {nameButton}
      </Button>

      <MenuList menuRef={anchorEl} setRef={setAnchorEl}>
        {menuItemList.map(({ name }) => (
          <MenuItem
            key={name}
            onClick={(event) => {
              getName(event);
            }}
          >
            {name}
          </MenuItem>
        ))}
      </MenuList>
    </>
  );
};

export default CalendarButton;
