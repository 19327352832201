import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import APIBlog from '../../api/blog';

export function* getDataPostAsync() {
  try {
    const response = yield call(APIBlog.getBlogPost);

    if (response.status === 200) {
      yield put(actions.getBlogPostDataDone(response.data));
    }
  } catch (error) {
    yield put(actions.getBlogPostDataFailed(error));
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default [takeEvery(String(actions.getBlogPostData), getDataPostAsync)];
