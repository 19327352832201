import { endPoint } from '../constants/apiEndpoint';
import { axiosInstance } from './axiosInstance';
import { IUserPushDetailsPayload } from '../store/user/types';

const userGetDetails = () =>
  axiosInstance
    .post(endPoint.user)
    .then((response) => response)
    .catch((err) => err);

const userEditDetails = ({ payload }: any) => {
  const {
    name,
    surname,
    location,
    webpage,
    biography,
    linkedin,
    facebook,
    twitter,
    webinars,
    idInterest,
    image,
    agreementRegulations,
    agreementNewsletter,
    agreementPersonalised,
  } = payload;

  const formData = new FormData();

  if (name) {
    formData.append('name', name);
  }
  if (typeof surname !== 'undefined') {
    formData.append('surname', surname);
  }
  if (typeof location !== 'undefined') {
    formData.append('location', location);
  }
  if (typeof idInterest !== 'undefined') {
    console.log(idInterest);
    idInterest.forEach((id: number | any, index: number) => {
      formData.append(`interests[${index}]`, id);
    });
  }
  if (typeof webpage !== 'undefined') {
    formData.append('webpage', webpage);
  }
  if (typeof facebook !== 'undefined') {
    formData.append('facebook', facebook);
  }
  if (typeof twitter !== 'undefined') {
    formData.append('twitter', twitter);
  }
  if (typeof linkedin !== 'undefined') {
    formData.append('linkedin', linkedin);
  }
  if (typeof webinars !== 'undefined') {
    formData.append('webinars', webinars);
  }
  if (typeof biography !== 'undefined') {
    formData.append('biography', biography);
  }
  if (image) {
    if (typeof image !== 'object') {
      // TODO block by backend
      // formData.append('image', image);
    } else {
      formData.append('image', image.file, '[PROXY]');
    }
  }
  if (agreementRegulations) {
    formData.append('agreementRegulations', agreementRegulations);
  }
  if (agreementNewsletter) {
    formData.append('agreementNewsletter', agreementNewsletter);
  }
  if (agreementPersonalised) {
    formData.append('agreementPersonalised', agreementPersonalised);
  }

  return axiosInstance
    .post(endPoint.user, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const userPushDetails = ({ payload }: IUserPushDetailsPayload) => {
  const { userProperty, value } = payload;
  const formData = new FormData();

  if (typeof value !== 'undefined') {
    if (typeof value === 'object' && value.length > 1) {
      value.forEach((id: number | any, index: number) => {
        formData.append(`${userProperty}[${index}]`, id);
      });
    } else {
      formData.append(userProperty, String(value));
    }
  }

  return axiosInstance
    .post(endPoint.user, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

//   return axiosInstance
//     .post(endPoint.user, { [userProperty]: value })
//     .then((response) => response)
//     .catch((err) => err);
// };

const userEditImage = ({ payload }: any) => {
  const { image } = payload;

  const formData = new FormData();
  formData.append('image', image.file, '[PROXY]');

  return axiosInstance
    .post(endPoint.user, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const signToWebinar = ({ payload }: any) => {
  const { webinarID } = payload;

  return axiosInstance
    .post(endPoint.join, {
      id: webinarID,
    })
    .then((response) => response)
    .catch((err) => err);
};

const leaveWebinar = ({ payload }: any) => {
  const { webinarID } = payload;

  return axiosInstance
    .post(endPoint.leave, { id: webinarID })
    .then((response) => response)
    .catch((err) => err);
};

export default {
  userEditDetails,
  userPushDetails,
  userEditImage,
  signToWebinar,
  leaveWebinar,
  userGetDetails,
};
