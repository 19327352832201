import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

export const StyledPaper = styled(Paper)`
  border-radius: 56px;
`;
export const StyledTypography = styled(Typography)`
  margin: 0;
`;
