import React from 'react';
import { Field } from 'formik';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox as CheckboxMUI,
  FormGroup,
} from '@material-ui/core';
import { StyledBox } from './styled';

function Checkbox({
  name,
  paddingBottom,
  label,
  helperText,
  onChange,
  ...rest
}: any) {
  return (
    <StyledBox paddingBottom={paddingBottom} alignItems="start">
      <Field name={name} {...rest}>
        {({ field, meta, form }: any) => {
          const { value } = field;
          const { error, touched } = meta;
          const { setFieldValue, errors } = form;
          const isError = () => !!(error && touched);
          const handleChange = () => {
            setFieldValue(name, !value);
          };
          return (
            <FormControl required error={isError()} component="fieldset">
              <FormGroup row>
                <FormControlLabel
                  control={(
                    <CheckboxMUI
                      checked={value}
                      onChange={onChange || handleChange}
                      name={name}
                    />
                  )}
                  label={label}
                />
              </FormGroup>
              <FormHelperText>{errors[name]}</FormHelperText>
            </FormControl>
          );
        }}
      </Field>
    </StyledBox>
  );
}

export default Checkbox;
