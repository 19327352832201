import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import DEBUGSetOnline from 'utils/DEBUGSetOnline';
import * as userActions from 'store/user/actions';
import * as actionsCategories from 'store/categories/actions';
import { AxiosResponse } from 'axios';
import * as actions from './actions';
import APIWebinars from '../../api/webinars';
import {
  ICreateWebinarsPayload,
  ISelectAuthorWebinarsPayload,
  ISelectedWebinarsPayload,
  IWebinarSelectDatePayload,
  IWebinarsRefreshDataPayload,
  IWebinarsRefreshVideoIdPayload,
  WebinarsTypeProcess,
} from './types';
import { getWebinarsById, getWebinarsRequestState } from './selectors';
import { TProcessState } from '../../types/state/IAppState';
import { HEARTBEAT_REFRESH_TIMEOUT } from '../../constants/webinarSettings';
import { IWebinar } from '../../types/webinar/IWebinar';

export function* postCreateWebinars({ payload }: ICreateWebinarsPayload) {
  try {
    const response = yield call(APIWebinars.createWebinars, payload);
    if (response.status === 200 || response.status === 201) {
      yield put(actions.createWebinarDone(response.data.token));
    }
  } catch (error) {
    yield put(actions.createWebinarFailed(error));
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* getDataWebinarsAsync() {
  yield put(
    actions.webinarsSetProcess({
      typeProcess: WebinarsTypeProcess.webinarLoading,
      value: 'pending',
    })
  );
  try {
    const webinarRequestState: TProcessState = yield select(
      getWebinarsRequestState
    );
    if (webinarRequestState !== 'pending') {
      yield put(
        actions.webinarSetState({
          typeState: 'webinarRequestState',
          value: 'pending',
        })
      );
      const response = yield call(APIWebinars.getWebinars);
      if (response.status === 200) {
        yield put(
          actions.webinarsSetProcess({
            typeProcess: WebinarsTypeProcess.webinarLoading,
            value: 'done',
          })
        );
        let expandData = null;
        if (process.env.NODE_ENV !== 'production') {
          expandData = DEBUGSetOnline(response.data);
        }

        yield put(actions.webinarsDataDone(expandData || response.data));
      }
    }
  } catch (error) {
    yield put(actions.createWebinarFailed(error));
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* getSelectedWebinarsAsync({
  payload,
}: ISelectedWebinarsPayload) {
  yield put(
    actions.webinarsSetProcess({
      typeProcess: WebinarsTypeProcess.webinarLoading,
      value: 'pending',
    })
  );
  try {
    const response = yield call(APIWebinars.getSelectedWebinars, payload);
    if (response.status === 200) {
      yield put(actions.selectWebinarDone(response.data));
      yield put(
        actions.webinarsSetProcess({
          typeProcess: WebinarsTypeProcess.webinarLoading,
          value: 'done',
        })
      );
    }
  } catch (error) {
    yield put(actions.selectWebinarFailed(error));
    yield put(
      actions.webinarsSetProcess({
        typeProcess: WebinarsTypeProcess.webinarLoading,
        value: 'error',
      })
    );
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* setAuthorWebinarsAsync({
  payload,
}: ISelectAuthorWebinarsPayload) {
  try {
    const response = yield call(APIWebinars.getSelectedWebinars, payload);
    if (response.status === 200) {
      yield put(actions.resetSelectionWebinar());
      yield put(
        userActions.userDataWebinarSet(response.data, 'organizedByYou')
      );
    }
  } catch (error) {
    yield put(actions.selectWebinarFailed(error));
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* webinarSelectDateMiddleware({
  payload,
}: IWebinarSelectDatePayload) {
  yield put(
    actions.webinarSetCurrentSelector({
      typeState: 'startTimeMin',
      value: payload.startTimeMin,
    })
  );
  yield put(
    actions.webinarSetCurrentSelector({
      typeState: 'startTimeMax',
      value: payload.startTimeMax,
    })
  );
}

export function* webinarCleanCurrentSelectorMiddleware() {
  yield put(actionsCategories.categoriesCleanActive());
}

export function* webinarsRefreshDataSaga({
  payload,
  payload: { id },
}: IWebinarsRefreshDataPayload) {
  yield put(
    actions.webinarsSetProcess({
      typeProcess: WebinarsTypeProcess.webinarRefreshingData,
      value: 'pending',
    })
  );

  try {
    const response: AxiosResponse<any> = yield call(
      APIWebinars.getSelectedWebinars,
      { id }
    );

    if (response.status === 200) {
      yield put(
        actions.webinarsSetProcess({
          typeProcess: WebinarsTypeProcess.webinarRefreshingData,
          value: 'done',
        })
      );
      const { data } = response;
      yield put(actions.webinarsRefreshDataDone(data[0]));
    }
  } catch (error) {
    yield put(actions.createWebinarFailed(error));
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* webinarsRefreshVideoIdSaga({
  payload: {
    selectedWebinar,
    selectedWebinar: { id, yt_id },
  },
}: IWebinarsRefreshVideoIdPayload) {
  let currentWebinar: IWebinar | null = selectedWebinar;
  yield put(
    actions.webinarsSetProcess({
      typeProcess: WebinarsTypeProcess.webinarRefreshingVideoId,
      value: 'pending',
    })
  );

  do {
    yield put(actions.webinarsRefreshData({ id }));
    currentWebinar = yield select(getWebinarsById, id);

    if (currentWebinar?.yt_id) {
      yield put(
        actions.webinarsSetProcess({
          typeProcess: WebinarsTypeProcess.webinarRefreshingVideoId,
          value: 'done',
        })
      );
    }

    //   // TODO:// remove for prod
    console.log({ heartbeat: HEARTBEAT_REFRESH_TIMEOUT, yt_id });
    yield delay(HEARTBEAT_REFRESH_TIMEOUT);
  } while (currentWebinar?.yt_id === null);
}

export default [
  takeEvery(actions.getWebinarsData, getDataWebinarsAsync),
  takeEvery(actions.createWebinar, postCreateWebinars),
  takeEvery(actions.selectWebinar, getSelectedWebinarsAsync),
  takeEvery(actions.selectAuthorWebinar, setAuthorWebinarsAsync),
  takeEvery(
    actions.webinarCleanCurrentSelector,
    webinarCleanCurrentSelectorMiddleware
  ),
  takeEvery(actions.webinarSelectDate, webinarSelectDateMiddleware),
  takeEvery(actions.webinarsRefreshData, webinarsRefreshDataSaga),
  takeEvery(actions.webinarsRefreshVideoId, webinarsRefreshVideoIdSaga),
];
