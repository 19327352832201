import { createAction } from '@reduxjs/toolkit';
import { ISnackbarShowPayload, SnackbarTypes } from './types';

export const snackbarShow = createAction<ISnackbarShowPayload>(
  SnackbarTypes.SNACKBAR_SHOW,
);

export const popSnackbar = createAction(SnackbarTypes.SNACKBAR_POP);

export const clearSnackbar = createAction(SnackbarTypes.SNACKBAR_CLEAR);
