import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const StyledDatePicker = styled(Box)`
  .MuiPickersBasePicker-pickerViewLandscape {
    margin: 0 auto;
  }

  .MuiPickersStaticWrapper-staticWrapperRoot,
  .MuiPickersBasePicker-pickerView {
    max-width: 100%;
    width: 100%;
    min-width: unset;
  }
`;
