import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#336BF6',
      contrastText: '#fff',
    },
    secondary: {
      main: '#516570',
    },
    text: {
      primary: '#1E3440',
      secondary: '#516570',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '36px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '48px',
      letterSpacing: '-1px',
      textAlign: 'left',
      marginBottom: '24px',
    },

    h3: {
      fontSize: '21px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '29px',
      marginBottom: '24px',
    },

    h5: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      color: '#516570',
    },
    body1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '140%',
    },

    subtitle1: {
      fontSize: '17px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: '22px',
      marginBottom: '16px',
    },
    subtitle2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'bolder',
      lineHeight: '17px',
      marginBottom: '2px',
    },
    caption: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '17px',
      color: '#767676',
    },
  },

  overrides: {
    MuiButton: {
      root: {
        padding: '11px 16px',
      },

      containedSecondary: {
        backgroundColor: '#fff',
        color: '#336BF6',
        '&:hover': {
          backgroundColor: '#ccdaff',
        },
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      label: {
        textTransform: 'initial',
        fontSize: '15px',
        fontWeight: 'bold',
      },
      text: {
        textTransform: 'initial',
      },
      sizeLarge: {
        textTransform: 'initial',
        padding: '8px 16px',
        fontWeight: 'bold',
        lineHeight: '32px',
      },
      sizeSmall: {
        fontSize: '14px',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '4px',
      },
    },
    MuiChip: {
      root: {
        borderRadius: '4px',
        fontWeight: 'bolder',
        padding: '4px 8px',
        fontSize: '10px',
        lineHeight: '13px',
        textTransform: 'uppercase',
      },
      sizeSmall: {
        height: '21px',
        background: '#ffffff',
      },
      labelSmall: {
        paddingLeft: '0',
        paddingRight: '0',
      },
      outlined: {
        borderRadius: '24px',
        padding: '17px 3px',
        borderColor: '#1E3440',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 400,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 400,
        color: '#A9AFB2',
      },

      outlined: {
        transform: 'translate(20px, 14px) scale(1)',
      },
    },
    MuiFormControlLabel: {
      root: {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
      },
    },
    MuiOutlinedInput: {
      root: {
        marginBottom: '5px',
      },
      input: {
        padding: '15px 20px',
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#ECF1FD',
      },
    },
  },
});

theme.typography.h2 = {
  fontFamily: [
    'Montserrat',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontStyle: 'normal',
  fontWeight: 'bold',
  letterSpacing: '-1px',
  textAlign: 'left',
  marginBottom: '24px',
  fontSize: '20px',
  lineHeight: '28px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '32px',
    lineHeight: '40px',
  },
};

theme.typography.h4 = {
  fontFamily: [
    'Montserrat',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '22.4px',
  marginBottom: '2px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '18px',
    lineHeight: '28.8px',
  },
};

export default theme;
