import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import APIChat from 'api/chat';
import * as actions from './actions';
import { getChatWebinarSlug, getNextMessagesId } from './selectors';
import { HEARTBEAT_TIMEOUT } from '../../constants/chatSettings';
import { ChatTypeProcess } from './types';

export function* chatWebinarsDataAsync({ payload }: any) {
  try {
    const { status, data } = yield call(APIChat.requestWebinarChat, payload);
    if (status === 200) {
      yield put(actions.chatWebinarsDataDone(data));
    }
  } catch (error) {
    yield put(actions.chatWebinarsDataFailed(error));
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* chatInitSaga({ payload }: any) {
  try {
    yield put(
      actions.chatSetProcess({
        typeProcess: ChatTypeProcess.chatLoading,
        value: 'pending',
      })
    );
    const { status, data } = yield call(APIChat.initChat, payload);
    if (status === 200) {
      yield put(actions.chatWebinarsDataDone(data || []));
      yield put(
        actions.chatSetProcess({
          typeProcess: ChatTypeProcess.chatLoading,
          value: 'done',
        })
      );
    }
  } catch (error) {
    yield put(actions.chatWebinarsDataFailed(error));
    yield put(
      actions.chatSetProcess({
        typeProcess: ChatTypeProcess.chatLoading,
        value: 'error',
      })
    );
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* chatSendMessageAsync({ payload }: any) {
  try {
    const { status, data } = yield call(APIChat.sendMessage, payload);
    if (status === 200) {
      yield put(actions.chatSendMessageDone(data));
    }
  } catch (error) {
    yield put(actions.chatWebinarsDataFailed(error));
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* chatRefreshDataAsync() {
  do {
    const webinarSlug = yield select(getChatWebinarSlug);
    const nextMessageId = yield select(getNextMessagesId);
    try {
      const { status, data } = yield call(APIChat.nextChatMessage, {
        webinarSlug,
        nextMessageId: nextMessageId === null ? undefined : nextMessageId,
      });

      if (data !== null && status === 200) {
        yield put(
          actions.chatSetProcess({
            typeProcess: ChatTypeProcess.chatUpdating,
            value: 'pending',
          })
        );
        const { text, time, user } = data;
        yield put(actions.chatUpdate(text, time, user));
        yield put(
          actions.chatSetProcess({
            typeProcess: ChatTypeProcess.chatUpdating,
            value: 'done',
          })
        );
        // yield put(actions.chatWebinarsDataDone(data));
      }
    } catch ({ status, data }) {
      // if (data === null && status === 200) {
      //   yield delay(2000);
      // } else {
      //   yield put(actions.chatWebinarsDataFailed(data));
      // }
      // // eslint-disable-next-line no-console
      // console.error(status, data);
    }
    // TODO:// remove for prod
    console.log({ heartbeat: HEARTBEAT_TIMEOUT });
    yield delay(HEARTBEAT_TIMEOUT);
  } while (yield select(getChatWebinarSlug));
}

export default [
  takeEvery(actions.chatInit, chatInitSaga),
  takeEvery(actions.chatWebinarsData, chatWebinarsDataAsync),
  takeEvery(actions.chatRefreshData, chatRefreshDataAsync),
  takeEvery(actions.chatSendMessage, chatSendMessageAsync),
];
