import { createAction } from '@reduxjs/toolkit';
import {
  ChatActionTypes,
  IChatSetProcess,
  IChatSetProcessPayload,
  IChatSetStatus,
  IChatSetStatusPayload,
} from './types';

export const chatWebinarsData = createAction(
  ChatActionTypes.CHAT_WEBINARS_DATA,
  (webinarSlug: string, messageId?: number | undefined) => ({
    payload: {
      webinarSlug,
      messageId,
    },
  })
);

export const chatInit = createAction(
  ChatActionTypes.CHAT_INIT,
  (webinarSlug: string) => ({
    payload: {
      webinarSlug,
    },
  })
);

export const chatSendMessage = createAction(
  ChatActionTypes.CHAT_SEND_MESSAGE,
  (message: string, webinar: string) => ({
    payload: {
      webinar,
      message,
    },
  })
);

export const chatUpdate = createAction(
  ChatActionTypes.CHAT_UPDATE,
  (text: any, time: any, user: any) => ({
    payload: {
      text,
      time,
      user,
    },
  })
);

export const chatSendMessageDone = createAction(
  ChatActionTypes.CHAT_SEND_MESSAGE_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  })
);

export const chatSendMessageFailed = createAction(
  ChatActionTypes.CHAT_SEND_MESSAGE_FAILED,
  (data = {}) => ({
    payload: {
      data,
    },
  })
);

export const chatRefreshData = createAction(ChatActionTypes.CHAT_REFRESH_DATA);

export const chatSetDetailsData = createAction(
  ChatActionTypes.CHAT_SET_DETAILS_DATA,
  (webinarSlug: string | null = null, lastMessageId: number | null = null) => ({
    payload: {
      webinarSlug,
      lastMessageId,
    },
  })
);

export const chatWebinarsDataDone = createAction(
  ChatActionTypes.CHAT_WEBINARS_DATA_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  })
);

export const chatWebinarsDataFailed = createAction(
  ChatActionTypes.CHAT_WEBINARS_DATA_FAILED,
  (data = {}) => ({
    payload: {
      data,
    },
  })
);

export const chatResetData = createAction(ChatActionTypes.CHAT_RESET_DATA);

export const chatSetProcess = createAction(
  ChatActionTypes.CHAT_SET_PROCESS,
  ({ typeProcess, value }: IChatSetProcess): IChatSetProcessPayload => ({
    payload: { typeProcess, value },
  })
);

export const chatSetStatus = createAction(
  ChatActionTypes.CHAT_SET_STATUS,
  ({ typeStatus, value }: IChatSetStatus): IChatSetStatusPayload => ({
    payload: { typeStatus, value },
  })
);
