import styled from 'styled-components';
import { Chip } from '@material-ui/core';

export const StyledChip = styled(Chip)`
  &.MuiChip-outlined {
    border-color: #cdd9fa;
  }

  &.active {
    &.MuiChip-root {
      color: #336bf6;
    }
    &.MuiChip-outlined {
      border-color: #cdd9fa;
      background-color: #ecf1fd;
    }
  }
`;
