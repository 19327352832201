/* eslint-disable react/jsx-props-no-spreading */
import { Button, Grid } from '@material-ui/core';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSelector } from 'react-redux';
import AlreadyAccount from 'common/AlreadyAccount';
import { RegisterFormInitialValues } from './index';
import ButtonCallback from '../../../common/ButtonCallback';
import { getUserState } from '../../../store/user/selectors';
import DisplayFormikState from '../common/DisplayFormikState';
import { TRootState } from '../../../types/reduxTypes';
import { TypeProcess } from '../../../store/user/types';

export interface FormikStepperProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  initialValues: RegisterFormInitialValues;
  onSubmit: any;
  step: number;
  setStep: any;
}

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStepper({
  step,
  setStep,
  children,
  onSubmit,
  ...props
}: FormikStepperProps) {
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<FormikStepProps>[];
  const currentChild = childrenArray[step];
  const userRegisterState = useSelector((state: TRootState) =>
    getUserState(state, TypeProcess.userRegisterState)
  );

  const isLastStep = () => step === childrenArray.length - 1;
  const isFirstStep = () => step === 0;

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await onSubmit(values, helpers);
        } else {
          setStep((s: number) => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      {/* @ts-ignore */}
      {(propsIN) => {
        const { isSubmitting } = propsIN;
        return (
          <>
            <Form autoComplete="off">
              {currentChild}

              <Grid container spacing={2} justify="center">
                {step > 0 && !isLastStep() ? (
                  <Grid item>
                    <Button
                      disabled={isSubmitting}
                      variant="text"
                      size="large"
                      color="primary"
                      onClick={() => setStep((s: number) => s - 1)}
                      startIcon={<ArrowBackIcon />}
                    >
                      Wstecz
                    </Button>
                  </Grid>
                ) : null}
                <Grid item>
                  {(isSubmitting || isLastStep()) && (
                    <ButtonCallback
                      state={userRegisterState}
                      variant="contained"
                      color="primary"
                      type={userRegisterState !== 'done' ? 'submit' : 'button'}
                    >
                      Zarejestruj
                    </ButtonCallback>
                  )}

                  {!isFirstStep() && !isLastStep() && (
                    <Button variant="contained" color="primary" type="submit">
                      Dalej
                    </Button>
                  )}

                  {isFirstStep() && (
                    <Button variant="contained" color="primary" type="submit">
                      Załóż darmowe konto
                    </Button>
                  )}
                </Grid>
              </Grid>
              <AlreadyAccount />
            </Form>
            {process.env.NODE_ENV !== 'production' ? (
              <DisplayFormikState {...propsIN} />
            ) : null}
          </>
        );
      }}
    </Formik>
  );
}
