import { ICategories } from 'types/category/ICategories';
import UserInterestsStep from './UserInterestsStep';
import { IFormikStepProps } from '../../FormikStep';

export interface IUserInterestsStepProps extends IFormikStepProps {
  mapCategories?: ICategories[];
  title: string;
  subtitle: string;
  isMultiselect?: boolean;
}

export default UserInterestsStep;
