import { TProcessState } from 'types/state/IAppState';
import {
  IWebinar,
  IWebinarCurrentSelector,
  IWebinarTags,
} from '../../types/webinar/IWebinar';

export enum WebinarActionTypes {
  GET_WEBINARS_DATA = 'GET_WEBINARS_DATA',
  GET_WEBINARS_DATA_FAILED = 'GET_WEBINARS_DATA_FAILED',
  WEBINARS_DATA_DONE = 'WEBINARS_DATA_DONE',
  WEBINARS_CREATE = 'WEBINARS_CREATE',
  WEBINARS_CREATE_DONE = 'WEBINARS_CREATE_DONE',
  WEBINARS_CREATE_FAILED = 'WEBINARS_CREATE_FAILED',
  WEBINARS_CREATE_SET_STATE = 'WEBINARS_CREATE_SET_STATE',
  WEBINARS_SELECT = 'WEBINARS_SELECT',
  WEBINARS_SELECT_AUTHOR = 'WEBINARS_SELECT_AUTHOR',
  WEBINARS_SELECT_DONE = 'WEBINARS_SELECT_DONE',
  WEBINARS_SELECT_FAILED = 'WEBINARS_SELECT_FAILED',
  RESET_SELECTION_WEBINAR = 'RESET_SELECTION_WEBINAR',
  WEBINARS_SET_STATE = 'WEBINARS_SET_STATE',
  WEBINARS_SET_CURRENT_SELECTOR = 'WEBINARS_SET_CURRENT_SELECTOR',
  WEBINARS_CLEAN_CURRENT_SELECTOR = 'WEBINARS_CLEAN_CURRENT_SELECTOR',
  WEBINARS_SELECT_DATE = 'WEBINARS_SELECT_DATE',
  WEBINARS_SET_PROCESS = 'WEBINARS_SET_PROCESS',
  WEBINARS_SET_STATUS = 'WEBINARS_SET_STATUS',
  WEBINARS_REFRESH_DATA = 'WEBINARS_REFRESH_DATA',
  WEBINARS_REFRESH_DATA_DONE = 'WEBINARS_REFRESH_DATA_DONE',
  WEBINARS_REFRESH_VIDEO_ID = 'WEBINARS_REFRESH_VIDEO_ID',
}

export interface IWebinarState {
  webinarRequestState: TProcessState;
  isError: boolean;
  webinarAddingState: TProcessState;
  webinarSelectedState: TProcessState;
  mapWebinars: IWebinar[];
  lastSelect: IWebinar[];
  currentMapWebinars: IWebinar[];
  currentSelector: IWebinarCurrentSelector;
  process?:
    | undefined
    | {
        webinarLoading: TProcessState;
        webinarRefreshingData: TProcessState;
        webinarRefreshingVideoId: TProcessState;
      };
  status?:
    | undefined
    | {
        isWebinarLoading: boolean;
      };
}

export enum WebinarsTypeProcess {
  webinarLoading = 'webinarLoading',
  webinarRefreshingData = 'webinarRefreshingData',
  webinarRefreshingVideoId = 'webinarRefreshingVideoId',
}

export type TWebinarsTypeProcess = WebinarsTypeProcess;

export interface IWebinarsSetProcess {
  typeProcess: TWebinarsTypeProcess;
  value: TProcessState;
}

export interface IWebinarsSetProcessPayload {
  payload: IWebinarsSetProcess;
}

export enum WebinarsTypeStatus {
  isWebinarLoading = 'isWebinarLoading',
}

export type TWebinarsTypeStatus = WebinarsTypeStatus;
export type TWebinarsStatusState = boolean;

export interface IWebinarsSetStatus {
  typeStatus: TWebinarsTypeStatus;
  value: TWebinarsStatusState;
}

export interface IWebinarsSetStatusPayload {
  payload: IWebinarsSetStatus;
}

export interface IWebinarsRefreshData {
  id: number;
}
export interface IWebinarsRefreshDataPayload {
  payload: IWebinarsRefreshData;
}
export interface IWebinarsRefreshVideoId {
  selectedWebinar: IWebinar;
}
export interface IWebinarsRefreshVideoIdPayload {
  payload: IWebinarsRefreshVideoId;
}
export interface ICreateWebinarsPayload {
  payload: {
    description: string;
    title: string;
    idInterest: string;
    startTime: string;
    endTime: string;
    eventPage: string;
    mainImageSrc: string;
    originInternal: boolean;
  };
}
export interface ISelectedWebinars {
  id?: string | number | undefined;
  startTimeMin?: string | undefined;
  startTimeMax?: string | undefined;
  author?: string | undefined;
  category?: string | undefined;
  tags?: IWebinarTags[] | undefined;
}

export interface IWebinarSetCurrentSelector {
  typeState: 'category' | 'startTimeMin' | 'startTimeMax';
  value: number | string | null;
}

export interface ISelectedAuthorWebinars {
  author: string;
}

export type IWebinarSelectDate = {
  startTimeMin: string | null;
  startTimeMax: string | null;
};

export interface IWebinarSelectDatePayload {
  payload: IWebinarSelectDate;
}

export interface ISelectedWebinarsPayload {
  payload: ISelectedWebinars;
}
export interface IWebinarSetCurrentSelectorPayload {
  payload: IWebinarSetCurrentSelector;
}

export interface ISelectAuthorWebinarsPayload {
  payload: ISelectedAuthorWebinars;
}
