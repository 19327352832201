import React, { useEffect } from 'react';
import { Snackbar as MaterialSnackbar } from '@material-ui/core';
import {
  CheckCircleOutlineOutlined as SuccessOutlinedIcon,
  ErrorOutlined as ErrorOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  WarningOutlined as WarningOutlinedIcon,
} from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { AlertIcon, TAlertIconType } from 'constants/alertIcon';
import { ISnackbarProps } from './index';

function getIcon(icon: TAlertIconType | undefined) {
  switch (icon) {
    case AlertIcon.INFO:
      return <InfoOutlinedIcon />;
    case AlertIcon.WARNING:
      return <WarningOutlinedIcon />;
    case AlertIcon.ERROR:
      return <ErrorOutlinedIcon />;
    case AlertIcon.SUCCESS:
      return <SuccessOutlinedIcon />;
    default:
      return undefined;
  }
}

function Alert(props: any) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Snackbar = ({ snacks, popSnackbar, clearSnackbar }: ISnackbarProps) => {
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (snacks.length > 0) {
      interval = setInterval(() => popSnackbar(), 4000);
    }

    return () => clearInterval(interval);
  }, [snacks]);

  return (
    <MaterialSnackbar open={snacks.length > 0} onClose={clearSnackbar}>
      <div>
        {snacks.map(({ key, message, severity, icon }) => (
          <Alert key={key} severity={severity} icon={getIcon(icon)}>
            {message}
          </Alert>
        ))}
      </div>
    </MaterialSnackbar>
  );
};

export default Snackbar;
