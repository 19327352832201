import { IRegisterUserValueForm } from '../../components/Form/RegisterForm';
import { IWebinarID } from '../../types/webinar/IWebinar';
import { TUserProperty } from '../../types/user/IUser';
import { TProcessState } from '../../types/state/IAppState';

export enum UserActionTypes {
  USER_REGISTER = 'USER_REGISTER',
  USER_REGISTER_DONE = 'USER_REGISTER_DONE',
  USER_REGISTER_FAILED = 'USER_REGISTER_FAILED',
  USER_GET_DETAILS = 'USER_GET_DETAILS',
  USER_GET_DETAILS_DONE = 'USER_GET_DETAILS_DONE',
  USER_GET_DETAILS_FAILED = 'USER_GET_DETAILS_FAILED',
  USER_EDIT_DETAILS = 'USER_EDIT_DETAILS',
  USER_EDIT_DETAILS_DONE = 'USER_EDIT_DETAILS_DONE',
  USER_EDIT_DETAILS_FAILED = 'USER_EDIT_DETAILS_FAILED',
  USER_DATA_WEBINAR_SET = 'USER_DATA_WEBINAR_SET',
  USER_LOGOUT = 'USER_LOGOUT',
  USER_LOGOUT_DONE = 'USER_LOGOUT_DONE',
  USER_SING_TO_NEWSLETTER = 'USER_SING_TO_NEWSLETTER',
  USER_SING_TO_NEWSLETTER_DONE = 'USER_SING_TO_NEWSLETTER_DONE',
  USER_SING_TO_NEWSLETTER_FAILED = 'USER_SING_TO_NEWSLETTER_FAILED',
  USER_SIGNIN_WEBINAR = 'USER_SIGNIN_WEBINAR',
  USER_SIGNIN_WEBINAR_DONE = 'USER_SIGNIN_WEBINAR_DONE',
  USER_SIGNIN_WEBINAR_FAILED = 'USER_SIGNIN_WEBINAR_FAILED',
  USER_LEAVE_WEBINAR = 'USER_LEAVE_WEBINAR',
  USER_LEAVE_WEBINAR_DONE = 'USER_LEAVE_WEBINAR_DONE',
  USER_LEAVE_WEBINAR_FAILED = 'USER_LEAVE_WEBINAR_FAILED',
  USER_FACEBOOK_AUTHENTICATE = 'USER_FACEBOOK_AUTHENTICATE',
  USER_FACEBOOK_AUTHENTICATE_DONE = 'USER_FACEBOOK_AUTHENTICATE_DONE',
  USER_FACEBOOK_AUTHENTICATE_FAILED = 'USER_FACEBOOK_AUTHENTICATE_FAILED',
  USER_GOOGLE_AUTHENTICATE = 'USER_GOOGLE_AUTHENTICATE',
  USER_GOOGLE_AUTHENTICATE_DONE = 'USER_GOOGLE_AUTHENTICATE_DONE',
  USER_GOOGLE_AUTHENTICATE_FAILED = 'USER_GOOGLE_AUTHENTICATE_FAILED',
  USER_EDIT_IMAGE = 'USER_EDIT_IMAGE',
  USER_EDIT_IMAGE_DONE = 'USER_EDIT_IMAGE_DONE',
  USER_EDIT_IMAGE_FAILED = 'USER_EDIT_IMAGE_FAILED',
  USER_VERIFYING_EMAIL = 'USER_VERIFYING_EMAIL',
  USER_VERIFYING_EMAIL_DONE = 'USER_VERIFYING_EMAIL_DONE',
  USER_VERIFYING_EMAIL_FAILED = 'USER_VERIFYING_EMAIL_FAILED',
  USER_CHECK_EXIST = 'USER_CHECK_EXIST',
  USER_CHECK_EXIST_DONE = 'USER_CHECK_EXIST_DONE',
  USER_CHECK_EXIST_FAILED = 'USER_CHECK_EXIST_FAILED',
  USER________ = 'USER________',
  USER_SET_DETAILS = 'USER_SET_DETAILS',
  USER_PUSH_DETAILS = 'USER_PUSH_DETAILS',
  USER_CLEAN = 'USER_CLEAN',
  USER_SET_PROCESS = 'USER_SET_PROCESS',
  USER_SOCIAL_REGISTER = 'USER_SOCIAL_REGISTER',
}

export interface IUserEditDetailsPayload {
  payload: {
    name?: string | undefined | any;
    surname?: string | undefined | any;
    location?: string | undefined | any;
    webpage?: string | undefined | any;
    biography?: string | undefined | any;
    linkedin?: string | undefined | any;
    facebook?: string | undefined | any;
    twitter?: string | undefined | any;
    idInterest?: number[] | undefined | any;
    image?: string | any;
    agreementRegulations?: boolean | undefined | any;
    agreementNewsletter?: boolean | undefined | any;
    agreementPersonalised?: boolean | undefined | any;
  };
}

export interface IUserEditImagePayload {
  payload: {
    image: string;
  };
}

export interface ISocialAuthenticatePayload {
  payload: {
    token: string;
  };
}

export interface IUserRegisterPayload {
  payload: IRegisterUserValueForm;
}

export interface IVerifyingEmailRequest {
  verificationKey: string;
}

export interface IVerifyingEmailRequestPayload {
  payload: IVerifyingEmailRequest;
}

export interface IUserSignToNewsletterPayload {
  payload: { email: string };
}

export interface IUserWebinarSignupRequestPayload {
  payload: IWebinarID;
}

export interface IUserWebinarLEaveRequestPayload {
  payload: IWebinarID;
}
export type TUserCheck = 'username' | 'email';

export interface IUserCheckExist {
  typeCheck: TUserCheck;
  value: string;
}

export interface IUserCheckExistDone extends IUserCheckExist {
  isAvailable: boolean;
}

export interface IUserCheckExistPayload {
  payload: IUserCheckExist;
}

export interface IUserCheckExistPayloadDone {
  payload: IUserCheckExistDone;
}

export interface IUserPushDetails {
  userProperty: TUserProperty;
  value: string | number[] | boolean;
}

export interface IUserPushDetailsPayload {
  payload: IUserPushDetails;
}

export enum TypeProcess {
  userRegisterState = 'userRegisterState',
  userRegister = 'userRegister',
  addToNewsletter = 'addToNewsletter',
  editUserDetails = 'editUserDetails',
  userImageChange = 'userImageChange',
}

export type TTypeProcess = TypeProcess;

export interface IUserSetProcess {
  typeProcess: TTypeProcess;
  value: TProcessState;
}

export interface IUSerSetProcessPayload {
  payload: IUserSetProcess;
}
