import { FormikConfig, FormikValues } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import NewsletterEmail from './FormStep/NewsletterEmail';
import ThanksPage from './FormStep/ThanksPage';
import { NewsletterFormInitialValues } from './index';
import { FormikStepper } from './FormikStepper';
import { ErrorValidation } from '../common/const';
import { userSignToNewsletter } from '../../../store/user/actions';
import { TRootState } from '../../../types/reduxTypes';
import { getUserState } from '../../../store/user/selectors';
import { TypeProcess } from '../../../store/user/types';
import LoggedIn from '../../../common/Guard/LoggedIn';

const NewsletterForm = () => {
  const addToNewsletter = useSelector((state: TRootState) =>
    getUserState(state, TypeProcess.addToNewsletter),
  );
  const dispatch = useDispatch();
  const initialValues: NewsletterFormInitialValues | any = {
    email: '',
  };

  const [step, setStep] = useState(0);

  const handleSubmit = (values: { email: string }) => {
    dispatch(userSignToNewsletter(values.email));
  };

  return (
    <LoggedIn
      LoggedOutChildren={
        addToNewsletter === 'done' ? (
          <>
            <Typography component="h3" variant="subtitle1" color="secondary">
              Będziemy w kontakcie
            </Typography>
            <Typography component="p" variant="body1" color="secondary">
              Dziękujemy za zostawienie e-maila
            </Typography>
          </>
        ) : (
          <FormikStepper
            step={step}
            setStep={setStep}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            <NewsletterEmail
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(ErrorValidation.emailIncorrectValidateForm)
                  .required(ErrorValidation.required),
              })}
            />
            <ThanksPage />
          </FormikStepper>
        )
      }
    />
  );
};

export default NewsletterForm;

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}
