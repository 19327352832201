import styled from 'styled-components';
import { Box, Divider } from '@material-ui/core/';
import theme from 'scss/themes';

export const LayoutBlock = styled(Box)`
  flex-direction: column;
  padding-bottom: ${theme.spacing(2)}px;
`;

export const RowOutside = styled(Box)`
  justify-content: space-between;
  display: flex;
  align-items: center;
`;
export const RowContent = styled(Box)`
  flex-direction: column;
  min-height: 60px;
`;

export const StyledDivider = styled(Divider)`
  margin-top: ${theme.spacing(2)}px;
`;
