import moment from 'moment';
import 'moment/locale/pl';

const getEndTimeInDay = (date: Date | string | moment.Moment): string => {
  const endTime = moment(date);
  endTime.set('hour', 23);
  endTime.set('minute', 59);
  endTime.set('second', 59);
  endTime.set('millisecond', 59);

  return `${endTime.format('YYYY-MM-DDTHH:mm:ss')}Z`;
};

export default getEndTimeInDay;
