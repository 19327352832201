import { IWebinar } from 'types/webinar/IWebinar';
// @ts-ignore
import pl from 'date-and-time/locale/pl';
import date from 'date-and-time';
import { capitalizeFirstLetter } from './capitalizeFirstLetter';

export type TWorkWebinarDayMap = {
  date: number;
  value: IWebinar[];
};

export type TWebinarDayMap = {
  date: string;
  value: IWebinar[];
};

export const sortedWebinarsByDay = (
  mapWebinar: IWebinar[],
): TWebinarDayMap[] => {
  date.locale(pl);

  const sortedWebinar = mapWebinar.reduce((res: any, curr) => {
    const { start_time: startTime } = curr;
    const timeStamp = new Date(startTime).setHours(0, 0, 0, 0);

    if (res[timeStamp]) {
      res[timeStamp].value.push(curr);
    } else {
      Object.assign(res, {
        [timeStamp]: {
          date: timeStamp,
          value: [curr],
        },
      });
    }

    return res;
  }, {});

  const arrayWebinar: TWorkWebinarDayMap[] = Object.values(sortedWebinar);
  arrayWebinar.sort(({ date: dateA }, { date: dateB }) => dateA - dateB);

  return arrayWebinar.map(({ date: arrayWebinarDate, value }) => ({
    date: capitalizeFirstLetter(
      date.format(new Date(arrayWebinarDate), 'dddd, DD MMMM'),
    ),
    value,
  }));
};
