import TimeDetails from './TimeDetails';

export interface ITimeDetailsProps {
  icon?: boolean;
  date?: string;
  startTime?: string;
  endTime?: string;
}

export default TimeDetails;
