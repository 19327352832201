import React, { FC } from 'react';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Title from '../../common/Title';
import theme from '../../scss/themes';

interface ISidebarLayout {
  contentTitle?: string;
  sidebarTitle?: string;
  layoutContent: React.ReactNode;
  layoutSidebar?: React.ReactNode;
}

const SidebarLayout: FC<ISidebarLayout> = ({
  contentTitle,
  sidebarTitle,
  layoutContent,
  layoutSidebar,
}) => {
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <Grid container spacing={3} direction={isSm ? 'column-reverse' : 'row'}>
      <Grid item sm={12} md={layoutSidebar ? 8 : 12}>
        <Box width={1} minHeight="64px">
          {contentTitle && (
            <Title title={contentTitle} component="h2" variant="h2" />
          )}
        </Box>
        {layoutContent}
      </Grid>
      <Grid item sm={12} md={4}>
        <Box minHeight="64px">
          {sidebarTitle && (
            <Typography component="h2" variant="h2">
              {sidebarTitle}
            </Typography>
          )}
        </Box>
        {layoutSidebar && (
          <Grid container spacing={sidebarTitle ? 3 : 0}>
            {layoutSidebar}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SidebarLayout;
