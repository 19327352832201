import React, { FC } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import theme from '../../scss/themes';
import { IContentLayoutProps } from '.';
import Title from '../../common/Title';

const ContentLayout: FC<IContentLayoutProps> = ({
  image,
  altImage,
  title,
  textContent,
  textButton,
  linkTo,
  layout,
  borderColor,
}) => {
  // TODO: dodać border do zdjecia
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const layoutDirection = () => {
    if (isSm) {
      return 'column';
    }
    return layout === 'left' ? 'row' : 'row-reverse';
  };

  return (
    <Box paddingTop={4} paddingBottom={4}>
      <Grid
        container
        wrap="nowrap"
        justify="space-between"
        direction={layoutDirection()}
        alignItems="center"
        spacing={3}
      >
        <Grid sm={12} md={6} item>
          <img src={image} alt={altImage} />
        </Grid>
        <Grid sm={12} md={6} item>
          <Grid container>
            <Grid item>
              <Title
                title={title}
                position="left"
                component="h2"
                variant="h2"
              />
            </Grid>
            <Grid item>
              <Typography component="p" variant="body1">
                {textContent}
              </Typography>
            </Grid>
            <Grid item>
              <Box paddingTop={2}>
                <Link to={linkTo}>
                  <Button variant="contained" color="primary">
                    {textButton}
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContentLayout;
