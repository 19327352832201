import { createAction } from '@reduxjs/toolkit';
import {
  AuthActionTypes,
  IAuthGoogle,
  IAuthGooglePayload,
  IAuthLoginResponse,
  IAuthLoginUserSuccessPayload,
  IAuthSetProcess,
  IAuthSetProcessPayload,
  IAuthSetStatus,
  IAuthSetStatusPayload,
  IAuthUserLogin,
  IAuthUserLoginPayload,
} from './types';

export const authLoginUser = createAction(
  AuthActionTypes.AUTH_LOGIN_USER,
  ({ username, password }: IAuthUserLogin): IAuthUserLoginPayload => ({
    payload: { username, password },
  }),
);

export const authLoginUserSuccess = createAction(
  AuthActionTypes.AUTH_LOGIN_USER_SUCCESS,
);

export const authSetProcess = createAction(
  AuthActionTypes.AUTH_SET_PROCESS,
  ({ typeProcess, value }: IAuthSetProcess): IAuthSetProcessPayload => ({
    payload: { typeProcess, value },
  }),
);

export const authSetStatus = createAction(
  AuthActionTypes.AUTH_SET_STATUS,
  ({ typeStatus, value }: IAuthSetStatus): IAuthSetStatusPayload => ({
    payload: { typeStatus, value },
  }),
);

export const authInit = createAction(AuthActionTypes.AUTH_INIT);
export const authLogout = createAction(AuthActionTypes.AUTH_LOGOUT);
export const authClean = createAction(AuthActionTypes.AUTH_CLEAN);

export const authRefreshToken = createAction(
  AuthActionTypes.AUTH_REFRESH_TOKEN,
  ({
    accessToken,
    refreshToken,
    accessTokenExpiration,
  }: IAuthLoginResponse): IAuthLoginUserSuccessPayload => ({
    payload: { accessToken, refreshToken, accessTokenExpiration },
  }),
);

export const authGoogle = createAction(
  AuthActionTypes.AUTH_GOOGLE,
  ({ accessToken }: IAuthGoogle): IAuthGooglePayload => ({
    payload: {
      accessToken,
    },
  }),
);
