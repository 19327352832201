import React, { useRef } from 'react';
import {
  CellMeasurer,
  AutoSizer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';
import { Box, Typography } from '@material-ui/core/';
import { IDayListProps } from './index';
import { IWebinar } from '../../types/webinar/IWebinar';
import WebinarRow from '../../common/WebinarRow';

const DayList = ({ length, mapArray }: IDayListProps) => {
  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    }),
  );
  return (
    <AutoSizer>
      {({ width, height }) => (
        <List
          width={width}
          height={height}
          rowHeight={cache.current.rowHeight}
          deferredMeasurementCache={cache.current}
          rowCount={length}
          rowRenderer={({ key, index, style, parent }) => {
            const { date, value } = mapArray[index];
            return (
              <CellMeasurer
                key={key}
                cache={cache.current}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
              >
                <div style={style}>
                  <Box marginTop="16px" marginBottom="16px">
                    <Typography variant="h4">{date}</Typography>
                  </Box>
                  {value.map((webinar: IWebinar) => (
                    <WebinarRow key={webinar.id} webinar={webinar} />
                  ))}
                </div>
              </CellMeasurer>
            );
          }}
        />
      )}
    </AutoSizer>
  );
};

export default DayList;
