import { TRootState } from '../../types/reduxTypes';
import { IAuthState, TTypeProcess, TTypeStatus, TStatusState } from './types';
import { TProcessState } from '../../types/state/IAppState';

export const selectAuth = ({ auth }: TRootState): IAuthState => auth;
export const selectAuthProcess = (
  state: TRootState,
  typeProcess: TTypeProcess
): TProcessState | undefined => {
  const auth = selectAuth(state);

  if (auth.process) {
    return auth.process[typeProcess];
  }

  return undefined;
};

export const selectAuthStatus = (
  state: TRootState,
  typeStatus: TTypeStatus
): TStatusState | undefined => {
  const auth = selectAuth(state);

  if (auth.status) {
    return auth.status[typeStatus];
  }

  return undefined;
};
