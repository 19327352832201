import { IBlogState } from 'types/blog/IBlog';
import { BlogpostTypes } from './types';

const initialBlogPost: IBlogState = {
  isLoading: false,
  isError: false,
  blogPostAddingState: null,
  mapBlogPost: [],
};
//
// const initialBlogPost: IBlogPost[] = [
//     {
//       id: 0,
//       publishDate: '29.08.2020',
//       lastEdited: '29.08.2020',
//       status: PostStatus.PUBLIC,
//       media: {
//         thumbnail: {
//           imageSrc: 'https://picsum.photos/id/103/280/180',
//           imageAlt: 'Woman paying for a purchase',
//         },
//         mainImage: {
//           imageSrc: 'https://picsum.photos/id/103',
//           imageAlt: 'Woman paying for a purchase',
//         },
//       },
//       createdBy: 0,
//       createdDate: '29.08.2020',
//       title: 'Świat permanentnej zmiany. Jak się uczyć?',
//       description: 'string',
//       tag: ['rozwój osobisty', 'programowanie'],
//       mainTag: 'rozwój osobisty',
//     },
//     {
//       id: 1,
//       publishDate: '29.08.2020',
//       lastEdited: '29.08.2020',
//       status: PostStatus.PUBLIC,
//       media: {
//         thumbnail: {
//           imageSrc: 'https://picsum.photos/id/102/280/180',
//           imageAlt: 'Woman paying for a purchase',
//         },
//         mainImage: {
//           imageSrc: 'https://picsum.photos/id/102',
//           imageAlt: 'Woman paying for a purchase',
//         },
//       },
//       createdBy: 0,
//       createdDate: '29.08.2020',
//       title: 'Najpopularniejsze webinary w sierpniu?',
//       description: 'string',
//       tag: ['rozwój osobisty', 'programowanie'],
//       mainTag: 'trendy',
//     },
//     {
//       id: 2,
//       publishDate: '29.08.2020',
//       lastEdited: '29.08.2020',
//       status: PostStatus.PUBLIC,
//       media: {
//         thumbnail: {
//           imageSrc: 'https://picsum.photos/id/101/280/180',
//           imageAlt: 'Woman paying for a purchase',
//         },
//         mainImage: {
//           imageSrc: 'https://picsum.photos/id/101',
//           imageAlt: 'Woman paying for a purchase',
//         },
//       },
//       createdBy: 0,
//       createdDate: '29.08.2020',
//       title: 'Testowanie produktu',
//       description: 'string',
//       tag: ['rozwój osobisty', 'programowanie'],
//       mainTag: 'Produkty',
//     },
//     {
//       id: 3,
//       publishDate: '29.08.2020',
//       lastEdited: '29.08.2020',
//       status: PostStatus.PUBLIC,
//       media: {
//         thumbnail: {
//           imageSrc: 'https://picsum.photos/id/100/280/180',
//           imageAlt: 'Woman paying for a purchase',
//         },
//         mainImage: {
//           imageSrc: 'https://picsum.photos/id/100',
//           imageAlt: 'Woman paying for a purchase',
//         },
//       },
//       createdBy: 0,
//       createdDate: '29.08.2020',
//       title: 'Jak się uczyć innych?',
//       description: 'string',
//       tag: ['nauka', 'programowanie'],
//       mainTag: 'nauka',
//     },
//   ];

const blogPost = (state = initialBlogPost, action: any) => {
  switch (action.type) {
    case BlogpostTypes.GET_BLOG_POST_DATA:
      return { ...state, isLoading: true };
    case BlogpostTypes.GET_BLOG_POST_DATA_DONE:
      return { ...state, isLoading: false, mapBlogPost: action.payload.data };
    case BlogpostTypes.GET_BLOG_POST_DATA_FAILED:
      return { ...state, isLoading: false, isError: true };
    default:
      return state;
  }
};

export default blogPost;
