import styled from 'styled-components';
import Emoji from 'a11y-react-emoji';
import { Box } from '@material-ui/core';
import theme from '../../../scss/themes';

export const EmojiIcon = styled(Emoji)`
  font-size: 24px;
`;
export const EmojiContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  p.MuiTypography-root {
    font-weight: 600;
    font-size: 10px;
    line-height: 32px;
    margin-left: 10px;
  }
`;

export const HomeInformationContainer = styled(Box)`
  margin: 30px 0;

  ${theme.breakpoints.up('sm')} {
    margin: 60px 0;
  }
`;

export const TitleContainer = styled(Box)`
  h1.MuiTypography-root {
    position: relative;
    font-weight: 800;
    font-size: 40px;
    line-height: 140%;
    letter-spacing: 1px;
    z-index: 1;

    span {
      z-index: 1;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 7px;
        left: 0;
        width: 100%;
        height: 10px;
        background: #fcc206;
        z-index: -1;
      }
    }
  }
`;
