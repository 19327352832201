import React, { FC } from 'react';
import { ITextErrorProps } from './index';

const TextError: FC<ITextErrorProps> = ({ children = null }) => (
  <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
    {children}
  </div>
);

export default TextError;
