import moment from 'moment';
import 'moment/locale/pl';

const getStatTimeInDay = (date: Date | string | moment.Moment): string => {
  const startTimeMin = moment(date);
  startTimeMin.set('hour', 0);
  startTimeMin.set('minute', 0);
  startTimeMin.set('second', 0);
  startTimeMin.set('millisecond', 0);

  return `${startTimeMin.format('YYYY-MM-DDTHH:mm:ss')}Z`;
};

export default getStatTimeInDay;
