import { IUserState } from '../../types/user/IUser';
import { UserActionTypes } from './types';

const initialUser: IUserState = {
  isLoading: false,
  isError: false,
  userAuthToken: null,
  userRegisterState: null,
  userGettingDataState: null,
  userEditDetailsState: null,
  userLoginState: null,
  userLogoutState: null,
  userImageUploadState: null,
  userSigningWebinarState: null,
  userDetails: null,
  newsletter: null,
  webinars: {
    organizedByYou: [],
    likedWebinars: [],
    chosenForYou: [],
  },
  register: null,
};

const user = (state = initialUser, action: any) => {
  switch (action.type) {
    case UserActionTypes.USER_REGISTER:
      return { ...state, isLoading: true, userRegisterState: 'pending' };
    case UserActionTypes.USER_REGISTER_DONE:
      return { ...state, isLoading: false, userRegisterState: 'done' };
    case UserActionTypes.USER_REGISTER_FAILED:
      return {
        ...state,
        isLoading: false,
        userRegisterState: 'error',
        isError: true,
      };
    case UserActionTypes.USER_GET_DETAILS:
      return { ...state, isLoading: true, userGettingDataState: 'pending' };
    case UserActionTypes.USER_GET_DETAILS_DONE:
      return {
        ...state,
        isLoading: false,
        userGettingDataState: 'done',
        userDetails: action.payload.data,
      };
    case UserActionTypes.USER_GET_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
        userGettingDataState: 'error',
        isError: true,
      };

    case UserActionTypes.USER_EDIT_DETAILS:
      return { ...state, isLoading: true, userEditDetailsState: 'pending' };
    case UserActionTypes.USER_EDIT_DETAILS_DONE:
      return {
        ...state,
        isLoading: false,
        userEditDetailsState: 'done',
        userDetails: action.payload.data,
      };
    case UserActionTypes.USER_EDIT_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
        userEditDetailsState: 'error',
        isError: true,
      };

    case UserActionTypes.USER_DATA_WEBINAR_SET:
      return {
        ...state,
        webinars: {
          ...state.webinars,
          [action.payload.typeWebinar]: action.payload.data,
        },
      };
    case UserActionTypes.USER_LOGOUT:
      return { ...state, isLoading: true, userLogoutState: 'pending' };
    case UserActionTypes.USER_LOGOUT_DONE:
      return {
        ...state,
        isLoading: false,
        userLogoutState: 'done',
        userRegisterState: null,
        userGettingDataState: null,
        userAuthToken: null,
        webinars: {
          organizedByYou: [],
          likedWebinars: [],
          chosenForYou: [],
        },
      };

    case UserActionTypes.USER_SING_TO_NEWSLETTER:
      return { ...state, newsletter: { userNewsletterState: 'pending' } };

    case UserActionTypes.USER_EDIT_IMAGE:
      return { ...state, userImageUploadState: 'pending' };
    case UserActionTypes.USER_EDIT_IMAGE_DONE:
      return { ...state, userImageUploadState: 'done' };
    case UserActionTypes.USER_EDIT_IMAGE_FAILED:
      return { ...state, userImageUploadState: 'error', isError: true };

    case UserActionTypes.USER_SIGNIN_WEBINAR:
      return { ...state, userSigningWebinarState: 'pending' };
    case UserActionTypes.USER_SIGNIN_WEBINAR_DONE:
      return { ...state, userSigningWebinarState: 'done' };
    case UserActionTypes.USER_SIGNIN_WEBINAR_FAILED:
      return { ...state, userSigningWebinarState: 'error', isError: true };

    case UserActionTypes.USER_CHECK_EXIST:
      return {
        ...state,
        register: {
          ...state.register,
          [action.payload.typeCheck]: {
            value: action.payload.value,
            userCheckExistState: 'pending',
          },
        },
      };
    case UserActionTypes.USER_CHECK_EXIST_DONE:
      return {
        ...state,
        register: {
          ...state.register,
          [action.payload.typeCheck]: {
            value: action.payload.value,
            isAvailable: action.payload.isAvailable,
            userCheckExistState: 'done',
          },
        },
      };
    case UserActionTypes.USER_CHECK_EXIST_FAILED:
      return {
        ...state,
        register: {
          ...state.register,
          [action.payload.typeCheck]: { userCheckExistState: 'error' },
        },
      };
    case UserActionTypes.USER_VERIFYING_EMAIL:
      return {
        ...state,
        register: { ...state.register, confirmEmail: { status: 'pending' } },
      };
    case UserActionTypes.USER_VERIFYING_EMAIL_DONE:
      return {
        ...state,
        register: {
          ...state.register,
          confirmEmail: {
            status: 'done',
            value: action.payload.detail === 'ok',
          },
        },
      };
    case UserActionTypes.USER_VERIFYING_EMAIL_FAILED:
      return {
        ...state,
        register: { ...state.register, confirmEmail: { status: 'error' } },
      };
    case UserActionTypes.USER_SET_DETAILS:
      return { ...state, userDetails: action.payload.data };
    case UserActionTypes.USER_PUSH_DETAILS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          [action.payload.userProperty]: action.payload.value,
        },
      };
    case UserActionTypes.USER_CLEAN:
      return { ...state, userDetails: null };
    case UserActionTypes.USER_SET_PROCESS:
      return {
        ...state,
        process: {
          ...state.process,
          [action.payload.typeProcess]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default user;
