import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';
import { routes } from '../../constants/routes';

const AlreadyAccount = () => {
  const isLoginPage = useRouteMatch(routes.LOGIN.url);
  const isRegisterPage = useRouteMatch(routes.REGISTER.url);
  return (
    <Box padding={2} paddingBottom={0}>
      {isRegisterPage ? (
        <Typography variant="body2" align="center">
          Masz już konto?
          <Link to={routes.LOGIN.url}>{' Zaloguj się '}</Link>
        </Typography>
      ) : null}
      {isLoginPage ? (
        <Typography variant="body2" align="center">
          Nie masz konta?
          <Link to={routes.REGISTER.url}>{' Zarejestruj się '}</Link>
        </Typography>
      ) : null}
    </Box>
  );
};
export default AlreadyAccount;
