import { connect } from 'react-redux';
import { ReactNode } from 'react';
import Component from './LoggedIn';
import { TRootState } from '../../../types/reduxTypes';
import { selectAuthStatus } from '../../../store/auth/selectors';
import { TypeStatus } from '../../../store/auth/types';

export interface LoggedInProps {
  isUserLogin?: boolean | undefined;
  LoggedInChildren?: ReactNode;
  LoggedOutChildren?: ReactNode;
}

const mapStateToProps = (state: TRootState) => ({
  isUserLogin: selectAuthStatus(state, TypeStatus.isUserLogin),
});

const LoggedIn = connect(mapStateToProps)(Component);

export default LoggedIn;
