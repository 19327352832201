import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useField } from 'formik';
import { StyledEditor } from './styled';
import TextError from '../../TextError';

const EditorField = ({ field, form }: any) => {
  const [hookField, meta] = useField(field);
  const isError = () => !!(meta.error && meta.touched);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isEditorFocus, setIsEditorFocus] = useState<boolean>(false);

  useEffect(() => {
    if (field.value.length) {
      const contentBlock = htmlToDraft(field.value);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );

        const state = EditorState.createWithContent(contentState);

        setEditorState(state);
      }
    }
  }, []);

  useEffect(() => {
    if (form.dirty) {
      return;
    }
    if (!field.value) {
      return;
    }
    const contentBlock = htmlToDraft(field.value);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );

      const state = EditorState.createWithContent(contentState);

      setEditorState(state);
    }
  }, [field.value, form.dirty]);

  useEffect(() => {
    setIsEditorFocus(editorState.getSelection().getHasFocus());
  }, [editorState]);

  const changeValue = (state: any) => {
    setEditorState(state);
    form.setFieldValue(
      field.name,
      draftToHtml(convertToRaw(state.getCurrentContent())),
    );
  };

  const onEditorStateChange = (state: any) => {
    changeValue(state);
  };

  return (
    <StyledEditor isFocus={isEditorFocus} isError={isError()}>
      <Editor
        editorState={editorState}
        onEditorStateChange={(state) => onEditorStateChange(state)}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'list',
            'textAlign',
            'link',
            'emoji',
          ],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
        }}
      />

      <TextError>{isError() ? meta.error : ''}</TextError>
    </StyledEditor>
  );
};

export default EditorField;
