import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Box, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import LoggedIn from 'common/Guard/LoggedIn';
import NewsletterForm from 'components/Form/NewsletterForm';
import theme from '../../../../scss/themes';
import { routes } from '../../../../constants/routes';

const useStyles = makeStyles(() => ({
  footerLinkStyle: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#666666',
    lineHeight: '22px',
    marginBottom: '8px',
  },
}));

function FooterMenu() {
  const classes = useStyles();

  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const variantHeader = () => (isSm ? 'subtitle1' : 'subtitle2');

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      width={1}
      paddingBottom={7}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography
            component="h3"
            color="textSecondary"
            variant={variantHeader()}
          >
            Darmowe Webinary sp. z o.o.
          </Typography>
          <Typography
            className={classes.footerLinkStyle}
            component="p"
            variant="h5"
          >
            Darłowska 1A lok. 13
            <br />
            04-091 Warszawa
          </Typography>
          <Typography
            className={classes.footerLinkStyle}
            component="p"
            variant="h5"
          >
            {/* tel.: 123456789 */}
            {/* <br /> */}
            e-mail: kontakt@darmowewebinary.pl
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography
            component="h3"
            color="textSecondary"
            variant={variantHeader()}
          >
            Twoje konto
          </Typography>
          <LoggedIn
            LoggedInChildren={(
              <Link to={routes.USER.url}>
                <Typography
                  className={classes.footerLinkStyle}
                  component="p"
                  variant="h5"
                >
                  Profil Użytkownika
                </Typography>
              </Link>
            )}
            LoggedOutChildren={(
              <Link to={routes.LOGIN.url}>
                <Typography
                  className={classes.footerLinkStyle}
                  component="p"
                  variant="h5"
                >
                  Zaloguj się
                </Typography>
              </Link>
            )}
          />
          <LoggedIn
            LoggedOutChildren={(
              <Link to={routes.REGISTER.url}>
                <Typography
                  className={classes.footerLinkStyle}
                  component="p"
                  variant="h5"
                >
                  Zarejestruj się
                </Typography>
              </Link>
            )}
          />
          <Link to={routes.ADD_WEBINAR.url}>
            <Typography
              className={classes.footerLinkStyle}
              component="p"
              variant="h5"
            >
              Dodaj wydarzenie
            </Typography>
          </Link>
          {/* <Link to={routes.B2B.url}> */}
          {/*  <Typography className={classes.footerLinkStyle} component="p" variant="h5"> */}
          {/*    Promuj wydarzenie */}
          {/*  </Typography> */}
          {/* </Link> */}
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography
            component="h3"
            color="textSecondary"
            variant={variantHeader()}
          >
            Odkrywaj
          </Typography>
          <Link to={routes.CALENDAR.url}>
            <Typography
              className={classes.footerLinkStyle}
              component="p"
              variant="h5"
            >
              Kalendarz webinarów
            </Typography>
          </Link>
          <Link to={routes.CATEGORY.url}>
            <Typography
              className={classes.footerLinkStyle}
              component="p"
              variant="h5"
            >
              Kategorie
            </Typography>
          </Link>
          <Link to={routes.BLOG.url}>
            <Typography
              className={classes.footerLinkStyle}
              component="p"
              variant="h5"
            >
              Blog
            </Typography>
          </Link>
        </Grid>
        <LoggedIn
          LoggedOutChildren={(
            <Grid item xs={12} md={5}>
              <Typography
                component="h3"
                color="textSecondary"
                variant={variantHeader()}
              >
                Bądźmy w kontakcie!
              </Typography>
              <NewsletterForm />
            </Grid>
          )}
        />
      </Grid>
    </Box>
  );
}

export default FooterMenu;
