import { TRootState } from 'types/reduxTypes';
import { IAppState, TProcessState } from '../../types/state/IAppState';
import { IMessageChat } from '../../types/chat/IChat';
import {
  IChatState,
  TChatStatusState,
  TChatTypeProcess,
  TChatTypeStatus,
} from './types';

export const selectChat = ({ chat }: IAppState): IChatState => chat;

export const getChatWebinarSlug = (state: IAppState): string | null => {
  const chat = selectChat(state);
  return chat.webinarSlug;
};

export const getChatLastMessageId = (state: IAppState): number | null => {
  const chat = selectChat(state);
  return chat.lastMessageId;
};

export const getChatMessages = (state: IAppState): IMessageChat[] => {
  const chat = selectChat(state);
  return chat.chat;
};

export const getNextMessagesId = (state: IAppState): number | null => {
  const chat = selectChat(state);
  return chat.chat.length;
};

export const getLastMessagesId = (state: IAppState): number | null => {
  const chat = selectChat(state);
  return chat.chat.length - 1;
};

export const selectChatProcess = (
  state: TRootState,
  typeProcess: TChatTypeProcess
): TProcessState | undefined => {
  const chat = selectChat(state);

  if (chat.process) {
    return chat.process[typeProcess];
  }

  return undefined;
};

export const selectChatStatus = (
  state: TRootState,
  typeStatus: TChatTypeStatus
): TChatStatusState | undefined => {
  const chat = selectChat(state);

  if (chat.status) {
    return chat.status[typeStatus];
  }

  return undefined;
};
