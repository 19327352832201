import React, { FC } from 'react';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import theme from '../../scss/themes';
import { IHeroLayoutProps } from './index';

const HeroLayout: FC<IHeroLayoutProps> = ({ layoutContent, layoutImage }) => {
  const isXs = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <Box display="flex" marginBottom={isXs ? 0 : 8}>
      <Grid
        container
        wrap="nowrap"
        justify="space-between"
        direction={isSm ? 'column' : 'row'}
        alignItems="stretch"
        spacing={2}
      >
        <Grid sm={12} md={6} item>
          {layoutContent}
        </Grid>
        <Grid sm={12} md={6} item>
          {layoutImage}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeroLayout;
