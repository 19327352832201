import { FormikConfig, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import DetailsUserStep from './FormStep/DetailsUserStep';
import PhotoUploadStep from './FormStep/PhotoUploadStep';
import {
  IRegisterSocialMediaFormProps,
  IRegisterUserValueForm,
  IUserSocialRegister,
  RegisterSocialMediaFormInitialValues,
} from './index';
import { FormikStepper } from './FormikStepper';
import ProgressBar from '../../../common/ProgressBar';
import FormCard from '../../../common/FormCard';
import { ErrorValidation } from '../common/const';
import UserInterestsStep from '../common/Step/UserInterestsStep';
import theme from '../../../scss/themes';
import { routes } from '../../../constants/routes';
import { TRootState } from '../../../types/reduxTypes';
import { getUserDetails } from '../../../store/user/selectors';
import { userSocialRegister } from '../../../store/user/actions';

const RegisterSocialMediaForm = ({
  userEditDetailsState,
  getCategoryDataRequested,
  mapCategories,
}: IRegisterSocialMediaFormProps) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state: TRootState) => getUserDetails(state));
  const initialValues: RegisterSocialMediaFormInitialValues = {
    email: '',
    username: '',
    name: '',
    surname: '',
    idInterest: [],
    image: '',
    allAgreement: false,
    agreementRegulations: false,
    agreementNewsletter: false,
    agreementPersonalised: false,
  };

  useEffect(() => {
    getCategoryDataRequested();
  }, []);

  useEffect(() => {
    if (userDetails) {
      const { image } = userDetails;

      initialValues.image = image;
    }
  }, [userDetails]);

  const [step, setStep] = useState(0);

  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const handleSubmit = (values: IRegisterUserValueForm) => {
    const newUserDetails: IUserSocialRegister = {};
    newUserDetails.image = values.image;
    newUserDetails.name = values.name;
    newUserDetails.surname = values.surname;
    newUserDetails.interests = values.idInterest;
    if (values.agreementRegulations) {
      newUserDetails.agreementRegulations = values.agreementRegulations;
    }
    if (values.agreementNewsletter) {
      newUserDetails.agreementNewsletter = values.agreementNewsletter;
    }
    if (values.agreementPersonalised) {
      newUserDetails.agreementPersonalised = values.agreementPersonalised;
    }
    dispatch(userSocialRegister(newUserDetails));
  };

  return (
    <FormCard
      maxWidth={732}
      layoutHeader={(
        <ProgressBar
          progress={(100 / 3) * (step + 1)}
          stepText={`Krok ${step + 1} z 3`}
          isSkip={!(step === 0 || step === 2)}
        />
      )}
    >
      {userEditDetailsState === 'done' ? (
        <Redirect to={routes.LOGIN.url} />
      ) : null}
      <Box width={isSm ? '100%' : '540px'}>
        <Grid container spacing={2} alignItems="center" direction="column">
          <Grid item xs>
            <FormikStepper
              step={step}
              setStep={setStep}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <DetailsUserStep
                validationSchema={Yup.object().shape({
                  agreementRegulations: Yup.bool().oneOf(
                    [true],
                    ErrorValidation.checkAgreement,
                  ),
                })}
              />
              <UserInterestsStep
                mapCategories={mapCategories}
                title="Wybierz kategorie"
                subtitle="Dzięki temu będziemy mogli lepiej dopasować webinary do twoich potrzeb i zainteresowań"
                isMultiselect
              />
              <PhotoUploadStep />
            </FormikStepper>
          </Grid>
        </Grid>
      </Box>
    </FormCard>
  );
};

export default RegisterSocialMediaForm;

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}
