import styled from 'styled-components';
import { Menu } from '@material-ui/core';

export const StyledMenu = styled(Menu)`
  .MuiMenuItem-root:not(last) {
    margin-bottom: 12px;
  }
  a:last-child > .MuiMenuItem-root,
  & .MuiMenuItem-root:last-child {
    margin-bottom: 0;
  }
`;
