import React, { ReactNode, FC } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import CenterTitle from 'common/CenterTitle';
import { StyledPaper } from './styled';
import theme from '../../scss/themes';

interface IFormCardProps {
  title?: string;
  subtitle?: string;
  layoutHeader?: ReactNode;
  children: ReactNode;
  addPadding?: number;
  maxWidth?: number | string;
}

const FormCard: FC<IFormCardProps> = ({
  maxWidth = '100%',
  addPadding = 6,
  title,
  subtitle,
  layoutHeader,
  children,
}) => {
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const getPadding = () => (isSm ? 1 : addPadding);

  return (
    <Box
      maxWidth={maxWidth}
      padding={getPadding()}
      paddingRight={isSm ? 0 : getPadding()}
      paddingLeft={isSm ? 0 : getPadding()}
      display="flex"
      alignItems="center"
      justifyContent="center"
      margin="auto"
    >
      <StyledPaper elevation={0}>
        <Box
          width={1}
          display="flex"
          flexDirection="column"
          padding={getPadding()}
          paddingTop={4}
          paddingBottom={4}
        >
          {layoutHeader}
          <CenterTitle title={title} subtitle={subtitle} />
          {children}
        </Box>
      </StyledPaper>
    </Box>
  );
};

export default FormCard;
