import { put } from 'redux-saga/effects';
import { snackbarShow } from '../snackbar/actions';
import { TAlertIconType } from '../../constants/alertIcon';

export function* snackError(
  isError: boolean,
  message: string,
  severity: string,
  icon: TAlertIconType,
) {
  if (isError) {
    yield put(
      snackbarShow({
        payload: {
          message,
          severity,
          icon,
        },
      }),
    );
  }
}
