import React, { useCallback, useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector, useDispatch } from 'react-redux';
import { getWebinarCurrentSelector } from 'store/webinars/selectors';
import { webinarsSetProcess } from 'store/webinars/actions';
import { WebinarsTypeProcess } from 'store/webinars/types';
import { ClipContainer } from './styled';
import ChipCategory from '../ChipCategory';
import { getMapCategories } from '../../store/categories/selectors';
import { TRootState } from '../../types/reduxTypes';
import {
  categoriesSetActive,
  getCategoriesData,
} from '../../store/categories/actions';

const CategoryClip = () => {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const mapCategory = useSelector((state: TRootState) =>
    getMapCategories(state)
  );
  const activeCategories = useSelector(
    (state: TRootState) => getWebinarCurrentSelector(state).category
  );
  useEffect(() => {
    if (mapCategory.length === 0) {
      dispatch(getCategoriesData());
    }
  }, [mapCategory]);

  useEffect(() => {
    if (activeCategories === null && selectedCategory !== null) {
      setSelectedCategory(null);
    }
    if (activeCategories !== null && selectedCategory === null) {
      setSelectedCategory(activeCategories);
    }
  }, [activeCategories]);

  const handleSelectCategory = useCallback((id: number) => {
    setSelectedCategory(id);
    dispatch(
      webinarsSetProcess({
        typeProcess: WebinarsTypeProcess.webinarLoading,
        value: 'pending',
      })
    );

    dispatch(categoriesSetActive(id));
  }, []);

  const handleRemoveFilter = useCallback(() => {
    setSelectedCategory(null);
    dispatch(categoriesSetActive(null));
  }, []);

  return (
    <ClipContainer>
      {mapCategory.map(({ id, name }) => (
        <ChipCategory
          key={id}
          label={name}
          variant="outlined"
          clickable
          active={selectedCategory === id}
          onClick={() => handleSelectCategory(id)}
        />
      ))}
      {selectedCategory && (
        <ChipCategory
          label="Usuń filtr"
          variant="outlined"
          clickable
          onClick={handleRemoveFilter}
          icon={<DeleteIcon />}
        />
      )}
    </ClipContainer>
  );
};

export default CategoryClip;
