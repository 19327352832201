import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoggedInProps } from './index';
import { TRootState } from '../../../types/reduxTypes';
import { getUserDetails } from '../../../store/user/selectors';

const LoggedIn: FC<LoggedInProps> = ({
  isUserLogin,
  LoggedInChildren = null,
  LoggedOutChildren = null,
}) => {
  const userDetails = useSelector((state: TRootState) => getUserDetails(state));
  const [isFullSetup, setIsFullSetup] = useState(false);

  useEffect(() => {
    if (userDetails?.agreementRegulations === true) {
      setIsFullSetup(true);
    }
  }, [userDetails]);

  if (isUserLogin === true && isFullSetup) {
    return <>{LoggedInChildren}</>;
  }
  return <>{LoggedOutChildren}</>;
};

export default LoggedIn;
