import { call, put, takeEvery } from 'redux-saga/effects';
import { WebinarsTypeProcess } from 'store/webinars/types';
import * as actions from './actions';
import * as actionsWebinars from '../webinars/actions';
import APICategory from '../../api/category';
import { ICategoriesSetActivePayload } from './types';

export function* getDataWebinarsSaga() {
  try {
    const response = yield call(APICategory.requestCategory);
    if (response.status === 200) {
      yield put(actions.categoriesDataDone(response.data));
    }
  } catch (error) {
    yield put(actions.getCategoriesDataFailed(error));
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* categoriesSetActiveMiddleware({
  payload,
}: ICategoriesSetActivePayload) {
  yield put(
    actionsWebinars.webinarSetCurrentSelector({
      typeState: 'category',
      value: payload.id,
    })
  );
}

export default [
  takeEvery(actions.getCategoriesData, getDataWebinarsSaga),
  takeEvery(actions.categoriesSetActive, categoriesSetActiveMiddleware),
];
