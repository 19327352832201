import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import { Hidden, IconButton, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Logo from '../../../common/Logo';
import theme from '../../../scss/themes';
import { NavBarContainer } from './styled';
import MenuStartContent from '../MenuStartContent/MenuStartContent';
import MenuEndContent from '../MenuEndContent/MenuEndContent';
import { INavBarProps } from './index';

const NavBar: FC<INavBarProps> = ({ onHandleClickMenu, isOpenMenu }) => {
  const isMd = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  return (
    <NavBarContainer>
      <Grid container justify="space-between">
        <Grid item>
          <Grid container spacing={isMd ? 1 : 3} alignItems="center">
            <Grid item>
              <Logo />
            </Grid>
            <Hidden smDown>
              <MenuStartContent />
            </Hidden>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={3} alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  aria-label="mobile menu"
                  onClick={onHandleClickMenu}
                >
                  {isOpenMenu ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <MenuEndContent />
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </NavBarContainer>
  );
};

export default NavBar;
