import { MouseEventHandler } from 'react';
import FormikControl from './FormikControl';

export enum EControlType {
  INPUT = 'input',
  TEXTAREA = 'textarea',
  TEXTEDITOR = 'textEditor',
  // SELECT = 'select',
  // RADIO = 'radio',
  CHECKBOX_GROUP = 'checkboxGroup',
  CHECKBOX = 'checkbox',
  // DATE = 'date',
  DATE_PICKER = 'datePicker',
  TIME_PICKER = 'timePicker',
  // CHAKRAINPUT = 'chakraInput',
}

export interface IFormikControlProps {
  controlType: EControlType;
  defaultValue?: any;
  type?: any;
  label?: any;
  name: any;
  size?: 'small' | 'normal';
  paddingBottom?: number;
  helperText?: string;
  options?: { id: number; name: string }[];
  isMultiselect?: boolean;
  rows?: number;
  multiline?: boolean;
  variant?: string;
  onChange?: any;
  onClick?: MouseEventHandler;
  onBlur?: any;
  lowerCase?: boolean;
}

export default FormikControl;
