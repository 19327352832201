import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { StyledFormContainer } from './styled';
import FormikControl, { EControlType } from '../FormikControl';
import { routes } from '../../../../constants/routes';

function RegisterAgreement() {
  const { values, setFieldValue } = useFormikContext();
  const {
    allAgreement,
    agreementNewsletter,
    agreementPersonalised,
    agreementRegulations,
  }: any = values;

  useEffect(() => {
    if (
      allAgreement === false &&
      agreementNewsletter === true &&
      agreementPersonalised === true &&
      agreementRegulations === true
    ) {
      setFieldValue('allAgreement', true);
    }
    if (
      allAgreement === true &&
      (!agreementNewsletter || !agreementPersonalised || !agreementRegulations)
    ) {
      setFieldValue('allAgreement', false);
    }
  }, [values]);

  const handleChange = () => {
    setFieldValue('agreementNewsletter', true);
    setFieldValue('agreementPersonalised', true);
    setFieldValue('agreementRegulations', true);
  };

  return (
    <StyledFormContainer>
      {!allAgreement && (
        <FormikControl
          controlType={EControlType.CHECKBOX}
          label={<>Zaznacz wszystkie zgody</>}
          onChange={handleChange}
          name="allAgreement"
        />
      )}
      <FormikControl
        controlType={EControlType.CHECKBOX}
        label={
          <>
            Akceptuję
            <Link target="_blank" to={routes.REGULATIONS.url}>
              {' regulamin '}
            </Link>
            i
            <Link target="_blank" to={routes.PRIVACY_POLICY.url}>
              {' politykę prywatności '}
            </Link>
            serwisu *
          </>
        }
        name="agreementRegulations"
      />
      <FormikControl
        controlType={EControlType.CHECKBOX}
        label={
          <>
            Chcę otrzymywać informacje o aktualnościach oraz promocjach w
            wiadomości e‑mail
            <Link target="_blank" to={routes.REGULATIONS.url}>
              {' Więcej '}
            </Link>
          </>
        }
        name="agreementNewsletter"
      />

      <FormikControl
        controlType={EControlType.CHECKBOX}
        label={
          <>
            Chcę otrzymywać ofertę dopasowaną do moich potrzeb
            <Link target="_blank" to={routes.REGULATIONS.url}>
              {' Więcej '}
            </Link>
          </>
        }
        name="agreementPersonalised"
      />
    </StyledFormContainer>
  );
}

export default RegisterAgreement;
