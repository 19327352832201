import { ReactNode, Dispatch } from 'react';
import MenuList from './MenuList';

export type TmenuRef = null | HTMLElement;

export interface IMenuListProps {
  children: ReactNode;
  menuRef: TmenuRef;
  setRef: Dispatch<TmenuRef>;
}

export default MenuList;
