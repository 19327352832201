import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import HeroLayout from 'layout/HeroLayout';
import HomeImage from 'routes/PageHome/HomeImage';

import AppLayout from 'layout/AppLayout';
import CategoryList from 'components/CategoryList';
import { routes } from 'constants/routes';
import ButtonShowAll from 'common/ButtonShowAll';
import HomeInformation from './HomeInformation';
import ContentJoinUs from '../../components/ContentJoinUs';
import Title from '../../common/Title';
import CategoryClip from '../../common/CategoryClip';
import CalendarButton from '../../components/CalendarButton';
import HorizontalAutoLayout from '../../layout/HorizontalAutoLayout';
import NewSlider from '../../common/NewSlider';

function PageHome() {
  return (
    <AppLayout>
      <Box display="flex" flexDirection="column">
        <HeroLayout
          layoutContent={<HomeInformation />}
          layoutImage={
            <Hidden smDown>
              <HomeImage />
            </Hidden>
          }
        />
        <Title title="Nadchodzące webinary" component="h2" variant="h2" />
        <HorizontalAutoLayout
          leftContent={<CategoryClip />}
          rightContent={<CalendarButton />}
        />
        <NewSlider />
        <Title title="Popularne kategorie" component="h2" variant="h2" />

        <CategoryList quantityToShow={8} />

        <ButtonShowAll
          buttonContent="Zobacz wszystkie"
          buttonLink={routes.CATEGORY.url}
        />
        <ContentJoinUs />
      </Box>
    </AppLayout>
  );
}

export default PageHome;
