import { categoryDescription } from '../constants/seoDescription';

const getCategoriesBySlug = (categorySlug: string) => {
  let findCategory: any = {
    url: null,
    title: null,
    description: null,
    keywords: null,
  };

  Object.entries(categoryDescription).forEach(
    ([id, { name, slug, title, description }]) => {
      if (slug === categorySlug) {
        findCategory = {
          url: null,
          slug,
          title,
          description,
        };
      }
    }
  );

  return findCategory;
};

export default getCategoriesBySlug;
