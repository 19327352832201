import { IRouterDetails, routes } from 'constants/routes';
import { useParams } from 'react-router-dom';
import getCategoriesBySlug from 'utils/getCategoriesBySlug';
import clearCategoryParams from './clearCategoryParams';

const getDetailsPage = (currentPathname: string): IRouterDetails => {
  let currentRouteDetails: IRouterDetails = {
    url: null,
    title: null,
    description: null,
    keywords: null,
  };

  const { categoryParams } = useParams<{ categoryParams: string }>();

  if (categoryParams) {
    return getCategoriesBySlug(clearCategoryParams(categoryParams));
  }

  Object.entries(routes).forEach(([pageName, pageDetails]: [string, any]) => {
    if (currentPathname === pageDetails.url) {
      currentRouteDetails = pageDetails;
    }
  });

  return currentRouteDetails;
};
export default getDetailsPage;
