import axios from 'axios';
import { fireBaseURL, endPoint } from '../constants/apiEndpoint';
import { axiosInstance } from './axiosInstance';

const chatAxiosInstance = axios.create({
  baseURL: fireBaseURL,
});

const initChat = ({ webinarSlug }: any) => {
  return chatAxiosInstance
    .get(`${endPoint.chat.chat}/${webinarSlug}.json`, {})
    .then((response) => response)
    .catch((err) => err.response);
};
const nextChatMessage = ({ webinarSlug, nextMessageId }: any) => {
  if (!nextMessageId) {
    return null;
  }
  return chatAxiosInstance
    .get(`${endPoint.chat.chat}/${webinarSlug}/${nextMessageId}.json`, {})
    .then((response) => response)
    .catch((err) => err.response);
};

const requestWebinarChat = ({ webinarSlug, lastMessageId }: any) => {
  let messageEndpoint = '.json';
  if (lastMessageId !== undefined) {
    messageEndpoint = `/${lastMessageId}.json`;
  }
  return chatAxiosInstance
    .get(`${endPoint.chat.chat}/${webinarSlug}${messageEndpoint}`, {})
    .then((response) => response)
    .catch((err) => err.response);
};

const sendMessage = ({
  message,
  webinar,
}: {
  message: string;
  webinar: string;
}) => {
  return axiosInstance
    .post(endPoint.chat.message, { webinar, text: message })
    .then((response) => response)
    .catch((err) => err.response);
};

export default { initChat, requestWebinarChat, sendMessage, nextChatMessage };
