import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, useMediaQuery } from '@material-ui/core';
import theme from '../../scss/themes';
import { ITitleProps } from './index';

const Title: FC<ITitleProps> = ({
  title,
  position = 'left',
  component,
  variant,
}) => {
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <Box
      display="flex"
      justifyContent={isSm || position === 'center' ? 'center' : 'flex-start'}
    >
      <Typography
        component={component}
        variant={variant}
        color="textPrimary"
        align={isSm || position === 'center' ? 'center' : 'inherit'}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default Title;
