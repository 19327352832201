import React, { ReactNode, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CookiesPopup from 'common/CookiesPopup';
import HeaderSeo from 'components/HeaderSeo';
import Snackbar from 'common/Snackbar';
import getDetailsPage from 'utils/getDetailsPage';
import NavBar from './NavBar';
import { BackgroundColor, Layout, MaxContentWrapper } from './styled';
import Footer from './Footer';
import MobileMenu from './MobileMenu';

export type AppLayoutType = {
  bgColor?: string;
  children: ReactNode;
};

function AppLayout({ bgColor = '#ffffff', children }: AppLayoutType) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const onHandleClickMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const { pathname } = useLocation();
  const pageDetails = getDetailsPage(pathname);

  return (
    <Layout>
      <HeaderSeo
        title={pageDetails.title}
        description={pageDetails.description}
      />
      <CookiesPopup />
      <Snackbar />
      {isOpenMenu && <MobileMenu />}
      <MaxContentWrapper>
        <NavBar
          onHandleClickMenu={() => onHandleClickMenu()}
          isOpenMenu={isOpenMenu}
        />
      </MaxContentWrapper>
      <BackgroundColor bgColor={bgColor}>
        <MaxContentWrapper>{children}</MaxContentWrapper>
      </BackgroundColor>
      <MaxContentWrapper>
        <Footer />
      </MaxContentWrapper>
    </Layout>
  );
}

export default AppLayout;
