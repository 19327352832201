import defaultState from './defaultState';
import { ChatActionTypes, IChatState } from './types';

const chat = (state: IChatState = defaultState, action: any) => {
  switch (action.type) {
    case ChatActionTypes.CHAT_WEBINARS_DATA:
      return { ...state, isLoading: true };
    case ChatActionTypes.CHAT_WEBINARS_DATA_DONE:
      return { ...state, isLoading: false, chat: action.payload.data };
    case ChatActionTypes.CHAT_UPDATE:
      return { ...state, chat: [...state.chat, action.payload] };
    case ChatActionTypes.CHAT_SET_DETAILS_DATA:
      return { ...state, ...action.payload };
    case ChatActionTypes.CHAT_RESET_DATA:
      return {};
    case ChatActionTypes.CHAT_SET_PROCESS:
      return {
        ...state,
        process: {
          ...state.process,
          [action.payload.typeProcess]: action.payload.value,
        },
      };
    case ChatActionTypes.CHAT_SET_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          [action.payload.typeStatus]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default chat;
