import React from 'react';
import CenterTitle from 'common/CenterTitle';
import FormikStep from 'components/Form/common/FormikStep';

const ThanksPage = () => (
  <FormikStep>
    <CenterTitle
      title="Dzięki za zapis!"
      subtitle="Na Twoją skrzynkę został wysłany e-mial z linkiem aktywacyjnym"
    />
  </FormikStep>
);

export default ThanksPage;
