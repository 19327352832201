import getStatTimeInDay from 'utils/getStatTimeInDay';
import getEndTimeInDay from 'utils/getEndTimeInDay';
import { ISelectedWebinars } from '../store/webinars/types';
import { endPoint } from '../constants/apiEndpoint';
import { axiosInstance } from './axiosInstance';

const createWebinars = (payload: any) => {
  const {
    description,
    title,
    // TODO change to category
    idInterest,
    startTime,
    endTime,
    eventPage,
    mainImageSrc,
    originInternal,
  } = payload;
  // TODO:split image for thumb and main image

  const formData = new FormData();

  formData.append('start_time', startTime);
  formData.append('end_time', endTime);
  formData.append('description', description);
  formData.append('title', title);
  formData.append('category', idInterest);
  formData.append('event_page', eventPage);
  formData.append('thumbnail_image_src', 'placeholder_in_code_main_image_alt');
  formData.append('thumbnail_image_alt', 'placeholder_in_code_main_image_alt');
  formData.append('main_image_alt', 'placeholder_in_code_main_image_alt');
  if (mainImageSrc) {
    if (typeof mainImageSrc !== 'object') {
      // TODO block by backend
      formData.append('image', mainImageSrc);
    } else {
      formData.append('main_image_src', mainImageSrc, '[PROXY]');
    }
  } else {
    formData.append('main_image_src', '');
  }
  formData.append('originInternal', originInternal);

  return axiosInstance
    .post(endPoint.webinars, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response)
    .catch((err) => err);
};

const getWebinars = () =>
  axiosInstance
    .get(endPoint.webinars, {
      params: {
        startTimeMin: getStatTimeInDay(new Date(Date.now())),
        startTimeMax: '2029-09-08T23:59:59Z',
      },
    })
    .then((response) => response)
    .catch((err) => err);

const getSelectedWebinars = ({
  id = '',
  startTimeMin = '',
  startTimeMax = '',
  author = '',
  category = '',
  tags = [],
}: ISelectedWebinars) =>
  axiosInstance
    .get(endPoint.webinars, {
      params: {
        id,
        startTimeMin,
        startTimeMax,
        author,
        category,
        tags,
      },
    })
    .then((response) => response)
    .catch((err) => err);

export default { getWebinars, createWebinars, getSelectedWebinars };
