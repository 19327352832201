import React from 'react';
import { Field } from 'formik';
import { Box } from '@material-ui/core';
import EditorField from './EditorField';

function TextEditor({ label, name, paddingBottom, helperText, ...rest }: any) {
  return (
    <Box paddingBottom={paddingBottom}>
      <Field
        fullWidth
        name={name}
        component={EditorField}
        label={label}
        helperText={helperText}
        {...rest}
      />
    </Box>
  );
}

export default TextEditor;
