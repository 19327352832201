import FormData from 'form-data';
import { endPoint } from '../constants/apiEndpoint';
import {
  IUserCheckExist,
  IVerifyingEmailRequestPayload,
} from '../store/user/types';
import { axiosInstance } from './axiosInstance';

const registerUser = (payload: any) => {
  const {
    email,
    username,
    password,
    idInterest,
    image = '',
    agreementRegulations,
    agreementNewsletter,
    agreementPersonalised,
  } = payload;

  const formData = new FormData();

  formData.append('email', email || '');
  formData.append('username', username || '');
  formData.append('password1', password || '');
  formData.append('password2', password || '');
  idInterest.forEach((id: number, index: number) => {
    formData.append(`interests[${index}]`, id);
  });
  if (image) {
    formData.append('image', image.file, '[PROXY]');
  }
  formData.append('agreementRegulations', agreementRegulations || false);
  formData.append('agreementNewsletter', agreementNewsletter || false);
  formData.append('agreementPersonalised', agreementPersonalised || false);

  return axiosInstance
    .post(endPoint.register, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response)
    .catch((err) => err.response);
};

const signToNewsletter = (payload: any) => {
  const { email } = payload;
  return axiosInstance
    .post(endPoint.newsletter, { email })
    .then((response) => response)
    .catch((err) => err);
};

const verifyingEmail = ({ payload }: IVerifyingEmailRequestPayload) => {
  const { verificationKey: key } = payload;
  return axiosInstance
    .post(endPoint.verifyingEmail, { key })
    .then((response) => response)
    .catch((err) => err.response);
};

const registerUserCheck = ({ typeCheck, value }: IUserCheckExist) =>
  axiosInstance
    .get(endPoint.checkUserEmail, { params: { [typeCheck]: value } })
    .then((response) => response)
    .catch((err) => err.response);

export default {
  registerUser,
  signToNewsletter,
  verifyingEmail,
  registerUserCheck,
};
