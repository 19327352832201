const userAuthToken = 'userAuthToken';
const userRefreshToken = 'userRefreshToken';
const userAccessTokenExpiration = 'userAccessTokenExpiration';
// TODO:// only for developing mode
const userGoogleResp = 'userGoogleResp';

export default {
  userAuthToken,
  userRefreshToken,
  userAccessTokenExpiration,
  userGoogleResp,
};
