import { connect } from 'react-redux';
import Component from './RegisterForm';
import { userRegister } from '../../../store/user/actions';
import { TProcessState } from '../../../types/state/IAppState';
import { getUserRegisterState } from '../../../store/user/selectors';
import { getCategoriesData } from '../../../store/categories/actions';
import { getMapCategories } from '../../../store/categories/selectors';
import { ICategories } from '../../../types/category/ICategories';
import { TRootState } from '../../../types/reduxTypes';

export interface IRegisterFormProps {
  userRegister: any;
  userRegisterState: TProcessState;
  getCategoryDataRequested: any;
  mapCategories?: ICategories[];
}

export interface IRegisterUserValueForm {
  email: string;
  username: string;
  password: string;
  idInterest: number[];
  image: string;
  agreementRegulations: boolean;
  agreementNewsletter: boolean;
  agreementPersonalised: boolean;
}

export interface RegisterFormInitialValues {
  description: string;
  email: string;
  username: string;
  password: string;
  passwordConfirmation: string;
  idInterest: number[];
  image: string;
  allAgreement: boolean;
  agreementRegulations: boolean;
  agreementNewsletter: boolean;
  agreementPersonalised: boolean;
}

const mapStateToProps = (state: TRootState) => ({
  userRegisterState: getUserRegisterState(state),
  mapCategories: getMapCategories(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  userRegister({
    email,
    username,
    password,
    idInterest,
    image,
    agreementRegulations,
    agreementNewsletter,
    agreementPersonalised,
  }: IRegisterUserValueForm) {
    return dispatch(
      userRegister(
        email,
        username,
        password,
        idInterest,
        image,
        agreementRegulations,
        agreementNewsletter,
        agreementPersonalised,
      ),
    );
  },
  getCategoryDataRequested: () => dispatch(getCategoriesData()),
});
const RegisterForm = connect(mapStateToProps, mapDispatchToProps)(Component);

export default RegisterForm;
