import { FormikConfig, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import DetailsUserStep from './FormStep/DetailsUserStep';
import PhotoUploadStep from './FormStep/PhotoUploadStep';
import {
  IRegisterFormProps,
  IRegisterUserValueForm,
  RegisterFormInitialValues,
} from './index';
import { FormikStepper } from './FormikStepper';
import ProgressBar from '../../../common/ProgressBar';
import FormCard from '../../../common/FormCard';
import { ErrorValidation } from '../common/const';
import { regexPassword } from '../../../constants/regexpPassword';
import UserInterestsStep from '../common/Step/UserInterestsStep';
import theme from '../../../scss/themes';
import { routes } from '../../../constants/routes';
import { TRootState } from '../../../types/reduxTypes';
import { getUserRegister } from '../../../store/user/selectors';

const RegisterForm = ({
  userRegisterState,
  userRegister,
  getCategoryDataRequested,
  mapCategories,
}: IRegisterFormProps) => {
  const registerValidatedObject = useSelector((state: TRootState) =>
    getUserRegister(state),
  );

  useEffect(() => {
    getCategoryDataRequested();
  }, []);

  const initialValues: RegisterFormInitialValues | any = {
    email: '',
    username: '',
    password: '',
    passwordConfirmation: '',
    idInterest: [],
    image: '',
    allAgreement: false,
    agreementRegulations: false,
    agreementNewsletter: false,
    agreementPersonalised: false,
  };

  const [step, setStep] = useState(0);

  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  return (
    <FormCard
      maxWidth={732}
      layoutHeader={(
        <ProgressBar
          progress={(100 / 3) * (step + 1)}
          stepText={`Krok ${step + 1} z 3`}
          isSkip={!(step === 0 || step === 2)}
        />
      )}
    >
      {userRegisterState === 'done' ? (
        <Redirect to={routes.EMAIL_VERIFICATION.url} />
      ) : null}
      <Box width={isSm ? '100%' : '540px'}>
        <Grid container spacing={2} alignItems="center" direction="column">
          <Grid item xs>
            <FormikStepper
              step={step}
              setStep={setStep}
              initialValues={initialValues}
              onSubmit={async (values: any) => {
                const {
                  email,
                  username,
                  password,
                  idInterest,
                  image,
                  agreementRegulations,
                  agreementNewsletter,
                  agreementPersonalised,
                }: IRegisterUserValueForm = values;

                return new Promise(() =>
                  userRegister({
                    email,
                    username,
                    password,
                    idInterest,
                    image,
                    agreementRegulations,
                    agreementNewsletter,
                    agreementPersonalised,
                  }),
                );
              }}
            >
              <DetailsUserStep
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email(ErrorValidation.emailIncorrectValidateForm)
                    .required(ErrorValidation.required)
                    .test(
                      'target',
                      ErrorValidation.emailIsUnAvailable,
                      (target) => {
                        if (registerValidatedObject === null) {
                          return true;
                        }
                        if (
                          registerValidatedObject?.email?.isAvailable ===
                            false &&
                          registerValidatedObject?.email.value === target
                        ) {
                          return false;
                        }
                        return true;
                      },
                    ),
                  username: Yup.string()
                    .required(ErrorValidation.required)
                    .test(
                      'target',
                      ErrorValidation.usernameIsUnAvailable,
                      (target) => {
                        if (registerValidatedObject === null) {
                          return true;
                        }
                        if (
                          registerValidatedObject?.username?.isAvailable ===
                            false &&
                          registerValidatedObject?.username?.value === target
                        ) {
                          return false;
                        }
                        return true;
                      },
                    ),
                  password: Yup.string()
                    .required(ErrorValidation.required)
                    .matches(
                      regexPassword,
                      ErrorValidation.passwordPatternError,
                    ),
                  passwordConfirmation: Yup.string()
                    .oneOf(
                      [Yup.ref('password'), null],
                      ErrorValidation.passwordMatch,
                    )
                    .required(ErrorValidation.required),
                  agreementRegulations: Yup.bool().oneOf(
                    [true],
                    ErrorValidation.checkAgreement,
                  ),
                })}
              />
              <UserInterestsStep
                mapCategories={mapCategories}
                title="Wybierz kategorie"
                subtitle="Dzięki temu będziemy mogli lepiej dopasować webinary do twoich potrzeb i zainteresowań"
                isMultiselect
              />
              <PhotoUploadStep />
            </FormikStepper>
          </Grid>
        </Grid>
      </Box>
    </FormCard>
  );
};

export default RegisterForm;

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}
