import React, { FC } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Container, MenuContainer } from './styled';
import { routes } from '../../../constants/routes';
import UserMenu from '../../../components/UserMenu';
import LoggedIn from '../../../common/Guard/LoggedIn';

const MobileMenu: FC = () => (
  <Container>
    <MenuContainer>
      <List component="nav" aria-label="top navigation">
        <Link to={routes.CALENDAR.url}>
          <ListItem>
            <ListItemText primary="Kalendarz webinarów" />
          </ListItem>
        </Link>
        <Divider />

        {/* TODO: create page B2B */}
        {/* <Link to={routes.B2B.url}> */}
        {/*  <ListItem> */}
        {/*    <ListItemText primary="Promuj wydarzenia" /> */}
        {/*  </ListItem> */}
        {/* </Link> */}
        {/* <Divider /> */}

        <LoggedIn
          LoggedInChildren={null}
          LoggedOutChildren={(
            <>
              <Link to={routes.ADD_WEBINAR.url}>
                <ListItem>
                  <ListItemText>Dodaj webinar</ListItemText>
                </ListItem>
              </Link>
              <Divider />
            </>
          )}
        />

        <LoggedIn
          LoggedInChildren={(
            <Grid item>
              <UserMenu />
            </Grid>
          )}
          LoggedOutChildren={(
            <>
              <Link to={routes.LOGIN.url}>
                <ListItem>
                  <ListItemText primary="Zaloguj" />
                </ListItem>
              </Link>
              <Divider />
              <Link to={routes.REGISTER.url}>
                <ListItem>
                  <ListItemText primary="Zarejestruj" />
                </ListItem>
              </Link>
            </>
          )}
        />
      </List>
    </MenuContainer>
  </Container>
);

export default MobileMenu;
