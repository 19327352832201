import React, { memo, useState } from 'react';

import { Box, Paper } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/pl';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useDispatch } from 'react-redux';
import getStatTimeInDay from 'utils/getStatTimeInDay';
import getEndTimeInDay from 'utils/getEndTimeInDay';
import { StyledDatePicker } from './styled';
import { webinarSelectDate } from '../../store/webinars/actions';

moment.locale('pl');

const CalendarDatePicker = memo(() => {
  const dispatch = useDispatch();
  const [currentDate] = useState(new Date(Date.now()));
  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date) {
      const startTimeMax = moment(date);
      const startTimeMin = moment(date);
      startTimeMin.set('hour', 0);
      startTimeMin.set('minute', 0);
      startTimeMin.set('second', 0);
      startTimeMin.set('millisecond', 0);

      startTimeMax.set('hour', 23);
      startTimeMax.set('minute', 59);
      startTimeMax.set('second', 59);
      startTimeMax.set('millisecond', 59);

      dispatch(
        webinarSelectDate({
          startTimeMin: getStatTimeInDay(date),
          startTimeMax: getEndTimeInDay(date),
        })
      );
    }
  };

  return (
    <Box marginBottom={1} width={1}>
      <Paper elevation={3}>
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale="pl"
        >
          <StyledDatePicker>
            <DatePicker
              disableToolbar
              variant="static"
              autoOk
              open
              format="MM dd, yyyy"
              margin="normal"
              value={currentDate}
              onChange={handleDateChange}
            />
          </StyledDatePicker>
        </MuiPickersUtilsProvider>
      </Paper>
    </Box>
  );
});

export default CalendarDatePicker;
