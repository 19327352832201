import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { IHeaderSeoProps } from './index';
import { fileRoutes } from '../../constants/routes';

const HeaderSeo: FC<IHeaderSeoProps> = ({ title, description, children }) => {
  return (
    <Helmet>
      <title>{title || 'Darmowe Webinary'}</title>
      <meta name="description" content={description || 'Darmowe Webinary'} />
      {/* <meta name="twitter:card" content="summary_large_image" /> */}
      {/* <meta name="twitter:site" content="@user" /> */}
      {/* <meta name="twitter:creator" content="@user" /> */}
      {/* <meta name="twitter:title" content="Pets - Products" /> */}
      {/* <meta name="twitter:description" content="Best Products for your pet" /> */}
      {/* <meta name="twitter:image" content="url_to_image" /> */}
      <meta property="og:title" content={title || 'Darmowe Webinary'} />
      <meta
        property="og:description"
        content={description || 'Darmowe Webinary'}
        name="description"
      />
      <meta property="og:image" content={fileRoutes.OPEN_GRAPH.image} />
      <meta property="og:site_name" content="Pets - Products" />
      <meta property="og:locale" content="pl_PL" />
      <meta property="og:type" content="website" />
      {/* <meta property="fb:app_id" content="ID_APP_FACEBOOK" /> */}
      {children}
    </Helmet>
  );
};

export default HeaderSeo;
