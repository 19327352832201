import { all } from 'redux-saga/effects';
import auth from './auth/sagas';
import webinars from './webinars/sagas';
import categories from './categories/sagas';
import user from './user/sagas';
import chat from './chat/sagas';
import blogPost from './blogPost/sagas';

export function* rootSaga() {
  yield all([
    ...auth,
    ...webinars,
    ...categories,
    ...user,
    ...chat,
    ...blogPost,
  ]);
}
