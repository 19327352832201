import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core/';
// @ts-ignore
import InfiniteCarousel from 'react-leaf-carousel';
import ButtonShowAll from 'common/ButtonShowAll';
import { INewSliderProps } from './index';
import WebinarCard from '../WebinarCard';
import { IWebinar } from '../../types/webinar/IWebinar';
import { routes } from '../../constants/routes';
import { StyledInfiniteCarousel } from './styled';

const NewSlider = ({
  mapWebinars,
  getDataWebinarsRequested,
  buttonLink = routes.CALENDAR.url,
  alignButton = 'right',
}: INewSliderProps) => {
  useEffect(() => {
    if (mapWebinars.length === 0) {
      getDataWebinarsRequested();
    }
  }, []);

  return (
    <Box>
      {mapWebinars.length ? (
        <StyledInfiniteCarousel>
          <InfiniteCarousel
            breakpoints={[
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 1280,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
            ]}
            slidesSpacing={5}
            lazyLoad
            showSides
            animationDuration={200}
            sidesOpacity={0}
            sideSize={0.1}
            slidesToScroll={4}
            slidesToShow={4}
            scrollOnDevice
            swipe
            placeholderImageSrc="./logo192.png"
            nextArrow={null}
            prevArrow={null}
          >
            {/* @ts-ignore */}
            {mapWebinars.map(
              ({
                id,
                slug,
                title,
                thumbnail_image_src,
                thumbnail_image_alt,
                start_time,
                category,
              }: IWebinar | any) => (
                <Box key={`WebinarCard - ${id}`}>
                  <WebinarCard
                    title={title}
                    thumbnail_image_src={thumbnail_image_src}
                    thumbnail_image_alt={thumbnail_image_alt}
                    start_time={start_time}
                    categoryName={category}
                    webinarLink={`${routes.WEBINAR.url}/${slug || id}`}
                  />
                </Box>
              )
            )}
          </InfiniteCarousel>
        </StyledInfiniteCarousel>
      ) : (
        <CircularProgress />
      )}

      {buttonLink && (
        <ButtonShowAll
          buttonContent="Zobacz wszystkie"
          buttonLink={buttonLink}
          align={alignButton}
        />
      )}
    </Box>
  );
};

export default NewSlider;
