import React, { memo } from 'react';
import { StyledChip } from './styled';

const ChipCategory = memo(
  ({ active, label, variant, clickable, onClick, icon }: any) => (
    <StyledChip
      className={active ? 'active' : ''}
      label={label}
      variant={variant}
      clickable={clickable}
      onClick={onClick}
      icon={icon}
    />
  ),
);

export default ChipCategory;
