/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import isEqual from 'lodash/isEqual';

import { ISnackbarState } from 'types/snacksbar/ISnackbar';
import { popSnackbar, snackbarShow } from './actions';
import defaultState from './defaultState';
import { ISnackbar, ISnackbarShowPayload } from './types';

function addSnack(draft: ISnackbarState, { payload }: ISnackbarShowPayload) {
  const { message, severity, icon } = payload;
  if (
    !draft.snacks.find((currentSnack: ISnackbar) =>
      isEqual(payload, currentSnack),
    )
  ) {
    draft.snacks = [
      ...draft.snacks,
      {
        key: nanoid(),
        message,
        severity,
        icon,
      },
    ];
  }
}

const snackbar = createReducer(defaultState, (builder) => {
  builder.addCase(snackbarShow, (draft, { payload }) => {
    addSnack(draft, payload);
  });

  builder.addCase(popSnackbar, (draft) => {
    const newSnacks = [...draft.snacks];
    newSnacks.shift();
    draft.snacks = newSnacks;
  });
});

export default snackbar;
