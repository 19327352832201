import { TProcessState } from 'types/state/IAppState';
import { MouseEventHandler } from 'react';
import { ButtonProps } from '@material-ui/core';
import ButtonCallback from './ButtonCallback';

export interface IButtonCallbackProps extends ButtonProps {
  handleClick?: MouseEventHandler | undefined | any;
  state?: TProcessState | any;
  type?: string | 'submit' | 'reset' | 'button' | undefined | any;
}

export default ButtonCallback;
