import React from 'react';
import { Field, useField } from 'formik';
import { Box, TextField } from '@material-ui/core';

const StyledTextField = ({ field, label, type, size, props }: any) => {
  const [hookField, meta] = useField(field);

  const isError = () => !!(meta.error && meta.touched);

  return (
    <TextField
      {...field}
      {...props}
      fullWidth
      type={type}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 300, // 5 min
      }}
      label={label}
      size={size}
      error={isError()}
    />
  );
};

function TimePicker({ type, label, name, paddingBottom, size, ...props }: any) {
  return (
    <Box paddingBottom={paddingBottom}>
      <Field
        fullWidth
        name={name}
        component={StyledTextField}
        type={type}
        label={label}
        {...props}
      />
    </Box>
  );
}

export default TimePicker;
