import React, { FC, useState } from 'react';
import { Button } from '@material-ui/core/';

import { IButtonSigninWebinarReduxMap } from './index';
import LoggedIn from '../Guard/LoggedIn';

const ButtonSigninWebinar: FC<IButtonSigninWebinarReduxMap> = ({
  webinarID,
  title,
  userSignToWebinar,
  isSignedIn,
  userLeaveWebinar,
  fullWidth,
  eventPage,
}) => {
  const [isSignedInWebinar, setIsSignedInWebinar] = useState(isSignedIn);
  const handleSignToWebinar = () => {
    setIsSignedInWebinar(!isSignedInWebinar);
    return isSignedIn
      ? userLeaveWebinar(webinarID)
      : userSignToWebinar(webinarID);
  };

  return (
    <LoggedIn
      LoggedInChildren={
        <>
          {isSignedInWebinar ? (
            <Button
              type="button"
              variant="contained"
              size="large"
              color="primary"
              fullWidth={fullWidth}
              onClick={handleSignToWebinar}
            >
              Usuń z kalendarza
            </Button>
          ) : (
            <a target="_blank" href={eventPage} rel="noopener noreferrer">
              <Button
                type="button"
                variant="contained"
                size="large"
                color="primary"
                fullWidth={fullWidth}
                onClick={handleSignToWebinar}
              >
                {title}
              </Button>
            </a>
          )}
        </>
      }
      LoggedOutChildren={
        <a target="_blank" href={eventPage} rel="noopener noreferrer">
          <Button
            type="button"
            variant="contained"
            size="large"
            color="primary"
            fullWidth={fullWidth}
            onClick={handleSignToWebinar}
          >
            {title}
          </Button>
        </a>
      }
    />
  );
};

export default ButtonSigninWebinar;
