import styled from 'styled-components';
import { Box } from '@material-ui/core';
import theme from '../../../scss/themes';

export const NavBarContainer = styled(Box)`
  padding: 16px 0 10px;
  display: flex;
  width: 100%;

  ${theme.breakpoints.up('sm')} {
    padding: 48px 0;
  }

  ${theme.breakpoints.down('sm')} {
    position: fixed;
    padding: 16px;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 2;
  }
`;
