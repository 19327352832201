import React from 'react';
import { ArrayHelpers, ErrorMessage, FieldArray, useField } from 'formik';
import { Box, Typography } from '@material-ui/core';
import { Container, StyledChip } from './styled';

const CheckboxGroup = ({
  name,
  paddingBottom,
  options,
  isMultiselect,
  onClick,
  ...rest
}: any) => {
  const [hookField, meta] = useField(name);
  const isError = () => !!(meta.error && meta.touched);
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string | number,
    arrayHelpers: ArrayHelpers,
    currentValue: [string | number],
  ) => {
    if (event.target.checked) {
      if (isMultiselect) {
        arrayHelpers.push(value);
      } else {
        arrayHelpers.replace(0, value);
      }
    } else {
      const idx = currentValue.indexOf(value);
      arrayHelpers.remove(idx);
    }
  };

  return (
    <Box
      paddingBottom={paddingBottom}
      borderColor={isError() ? 'error.main' : ''}
      border={isError() ? 1 : 0}
      borderRadius={4}
      padding={2}
      style={{ marginBottom: '16px' }}
    >
      <FieldArray
        name={name}
        {...rest}
        render={(arrayHelpers) => {
          const currentValue = arrayHelpers.form.values[name];
          return (
            <Container>
              {options.map((oneOption: any) => {
                const { id: value, name: label }: any = oneOption;
                const isChecked = currentValue.includes(oneOption.id);
                return (
                  <label key={value}>
                    <input
                      name={name}
                      hidden
                      type="checkbox"
                      value={oneOption.id}
                      checked={isChecked}
                      onClick={onClick || null}
                      onChange={(event) =>
                        handleChange(event, value, arrayHelpers, currentValue)}
                    />
                    <StyledChip
                      variant="outlined"
                      label={label}
                      clickable
                      className={isChecked ? 'active' : ''}
                    />
                  </label>
                );
              })}
            </Container>
          );
        }}
      />
      <Typography variant="caption" color="error">
        <ErrorMessage name={name} />
      </Typography>
    </Box>
  );
};

export default CheckboxGroup;
