import { call, put, takeEvery } from 'redux-saga/effects';
import APILogin from 'api/login';
import APIUser from 'api/user';
import * as actionsUser from 'store/user/actions';
import * as actions from './actions';
import {
  IAuthGooglePayload,
  IAuthUserLoginPayload,
  TypeProcess,
  TypeStatus,
} from './types';
import { AlertIcon } from '../../constants/alertIcon';
import constLocalStorage from '../../constants/localStorage';
import { snackError } from '../utils/snackError';

export function* authLoginUserSaga({ payload }: IAuthUserLoginPayload) {
  yield put(
    actions.authSetProcess({
      typeProcess: TypeProcess.authUserLogin,
      value: 'pending',
    })
  );
  const response = yield call(APILogin.login, { payload });
  const { status, data } = response;
  if (status === 200) {
    const { access_token: accessToken, refresh_token: refreshToken } = data;
    yield put(actions.authRefreshToken({ refreshToken, accessToken }));
  } else if (status === 404 || status === 400) {
    yield put(
      actions.authSetStatus({
        typeStatus: TypeStatus.isUserLogin,
        value: false,
      })
    );
    yield put(
      actions.authSetProcess({
        typeProcess: TypeProcess.authUserLogin,
        value: 'error',
      })
    );
    yield put(actions.authClean());
  }
}

export function* authGoogleSaga({ payload }: IAuthGooglePayload) {
  yield put(
    actions.authSetProcess({
      typeProcess: TypeProcess.authUserLogin,
      value: 'pending',
    })
  );
  const response = yield call(APILogin.googleAuth, { payload });
  const { status, data } = response;
  if (status === 200) {
    const { access_token: accessToken, refresh_token: refreshToken } = data;
    yield put(actions.authRefreshToken({ refreshToken, accessToken }));
  } else if (status === 404 || status === 400) {
    yield put(
      actions.authSetStatus({
        typeStatus: TypeStatus.isUserLogin,
        value: false,
      })
    );
    yield put(
      actions.authSetProcess({
        typeProcess: TypeProcess.authUserLogin,
        value: 'error',
      })
    );
    yield put(actions.authClean());
    if (status === 400) {
      yield call(
        snackError,
        true,
        'Nieprawidłowe dane logowania',
        'warning',
        AlertIcon.ERROR
      );
    }
  }
}

export function* authLoginUserSuccessSaga() {
  const { data: initUserDetails, status } = yield call(APIUser.userGetDetails);
  if (status === 200 && initUserDetails.agreementRegulations) {
    yield put(actionsUser.userSetDetails(initUserDetails));
    yield put(
      actions.authSetStatus({ typeStatus: TypeStatus.isUserLogin, value: true })
    );
    yield put(
      actions.authSetProcess({
        typeProcess: TypeProcess.authUserLogin,
        value: 'done',
      })
    );
    yield call(snackError, true, 'Zalogowano', 'success', AlertIcon.SUCCESS);
  } else if (initUserDetails.agreementRegulations === false) {
    yield put(
      actions.authSetStatus({
        typeStatus: TypeStatus.isUserLogin,
        value: false,
      })
    );
    yield put(
      actions.authSetProcess({
        typeProcess: TypeProcess.authUserLogin,
        value: 'done',
      })
    );
    yield put(actionsUser.userSetDetails(initUserDetails));
    yield call(
      snackError,
      true,
      'Dokończ rejestracje',
      'warning',
      AlertIcon.WARNING
    );
  } else {
    yield put(
      actions.authSetStatus({
        typeStatus: TypeStatus.isUserLogin,
        value: false,
      })
    );
    yield put(
      actions.authSetProcess({
        typeProcess: TypeProcess.authUserLogin,
        value: 'error',
      })
    );
    yield put(actions.authClean());
  }
}

export function* authInitSaga() {
  const accessTokenExpiration: string | null = localStorage.getItem(
    constLocalStorage.userAccessTokenExpiration
  );
  if (!accessTokenExpiration) {
    return;
  }
  const refreshToken: string | null = localStorage.getItem(
    constLocalStorage.userRefreshToken
  );
  const accessToken: string | null = localStorage.getItem(
    constLocalStorage.userAuthToken
  );

  const dateNow = Date.now();
  const dateToken: Date = new Date(accessTokenExpiration);

  if (dateNow > dateToken.getTime()) {
    yield put(actions.authRefreshToken({ refreshToken, accessToken }));
  } else {
    yield put(actions.authLoginUserSuccess());
  }
}

export function* authCleanSaga() {
  yield put(actionsUser.userClean());
  localStorage.removeItem(constLocalStorage.userAuthToken);
  localStorage.removeItem(constLocalStorage.userAccessTokenExpiration);
  localStorage.removeItem(constLocalStorage.userRefreshToken);
}

export function* authLogoutSaga() {
  const response = yield call(APILogin.logout);
  if (response.status === 200) {
    yield put(actions.authClean());
    yield put(
      actions.authSetStatus({
        typeStatus: TypeStatus.isUserLogin,
        value: false,
      })
    );
    yield call(snackError, true, 'Wylogowano', 'success', AlertIcon.SUCCESS);
  }
}

export function* authRefreshTokenSaga() {
  const { status } = yield call(APILogin.refreshToken);
  if (status === 200) {
    yield put(actions.authLoginUserSuccess());
  } else {
    yield put(
      actions.authSetStatus({
        typeStatus: TypeStatus.isUserLogin,
        value: false,
      })
    );
    yield put(
      actions.authSetProcess({
        typeProcess: TypeProcess.authUserLogin,
        value: 'error',
      })
    );
    yield put(actions.authClean());
  }
}

export default [
  takeEvery(actions.authInit, authInitSaga),
  takeEvery(actions.authClean, authCleanSaga),
  takeEvery(actions.authLogout, authLogoutSaga),
  takeEvery(actions.authLoginUser, authLoginUserSaga),
  takeEvery(actions.authGoogle, authGoogleSaga),
  takeEvery(actions.authLoginUserSuccess, authLoginUserSuccessSaga),
  takeEvery(actions.authRefreshToken, authRefreshTokenSaga),
];
