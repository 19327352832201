import { TRootState } from '../../types/reduxTypes';
import {
  IConfirmEmailObject,
  IUser,
  IUserState,
  TUserRegisterObject,
} from '../../types/user/IUser';
import { IWebinar } from '../../types/webinar/IWebinar';
import { ICategories } from '../../types/category/ICategories';
import { TTypeProcess } from './types';
import { TProcessState } from '../../types/state/IAppState';

export const getUser = ({ user }: TRootState): IUserState => user;

export function getUserRegisterState(state: TRootState): TProcessState {
  const user = getUser(state);
  return user.userRegisterState;
}

export function getUserEditDetailsState(state: TRootState): TProcessState {
  const user = getUser(state);
  return user.userEditDetailsState;
}

export function getUserDetails(state: TRootState): IUser | null {
  const user = getUser(state);
  return user.userDetails;
}

export function selectUsername(state: TRootState): string | null | undefined {
  const user = getUser(state);
  return user.userDetails?.username;
}
export function getUserImage(state: TRootState): string | null | undefined {
  const user = getUser(state);
  return user.userDetails?.image;
}

export function getUserWebinar(
  state: TRootState,
  typeWebinar: 'chosenForYou' | 'likedWebinars' | 'organizedByYou'
): IWebinar[] {
  const user = getUser(state);
  return user.webinars[typeWebinar];
}

export function getUserSavedWebinars(state: TRootState): IWebinar[] | [] {
  const user = getUser(state);
  return user.userDetails?.webinars || [];
}

export function getImageUploadState(state: TRootState): TProcessState {
  const user = getUser(state);
  return user.userImageUploadState;
}

export function getUserRegister(state: TRootState): TUserRegisterObject {
  const user = getUser(state);
  return user.register;
}

export function getUserCheckEmailState(state: TRootState): TProcessState {
  const user = getUser(state);
  return user.register?.email.userCheckExistState || null;
}

export function getUserConfirmEmailObject(
  state: TRootState
): IConfirmEmailObject | null {
  const user = getUser(state);
  return user.register?.confirmEmail || null;
}
export function getUserInterests(state: TRootState): ICategories[] | [] {
  const userDetails = getUserDetails(state);

  if (userDetails) {
    return userDetails.interests;
  }

  return [];
}

export function getUserState(
  state: TRootState,
  userProcess: TTypeProcess
): TProcessState | undefined {
  const user = getUser(state);

  if (user.process) {
    return user.process[userProcess];
  }
  return undefined;
}
