import { IMessageChat } from 'types/chat/IChat';
import { TProcessState } from 'types/state/IAppState';

export enum ChatActionTypes {
  CHAT_INIT = 'CHAT_INIT',
  CHAT_WEBINARS_DATA = 'CHAT_WEBINARS_DATA',
  CHAT_WEBINARS_DATA_DONE = 'CHAT_WEBINARS_DATA_DONE',
  CHAT_WEBINARS_DATA_FAILED = 'CHAT_WEBINARS_DATA_FAILED',
  CHAT_SEND_MESSAGE = 'CHAT_SEND_MESSAGE',
  CHAT_SEND_MESSAGE_DONE = 'CHAT_SEND_MESSAGE_DONE',
  CHAT_SEND_MESSAGE_FAILED = 'CHAT_SEND_MESSAGE_FAILED',
  CHAT_RESET_DATA = 'CHAT_RESET_DATA',
  CHAT_SET_DETAILS_DATA = 'CHAT_SET_DETAILS_DATA',
  CHAT_REFRESH_DATA = 'CHAT_REFRESH_DATA',
  CHAT_SET_PROCESS = 'CHAT_SET_PROCESS',
  CHAT_SET_STATUS = 'CHAT_SET_STATUS',
  CHAT_UPDATE = 'CHAT_UPDATE',
}

export interface IChatState {
  chat: IMessageChat[];
  webinarSlug: string | null;
  isLoading: boolean | null;
  lastMessageId: number | null;
  process?:
    | undefined
    | {
        chatLoading: TProcessState;
        chatUpdating: TProcessState;
      };
  status?:
    | undefined
    | {
        isChatLoading: boolean;
      };
}

export enum ChatTypeProcess {
  chatLoading = 'chatLoading',
  chatUpdating = 'chatUpdating',
}

export type TChatTypeProcess = ChatTypeProcess;

export interface IChatSetProcess {
  typeProcess: TChatTypeProcess;
  value: TProcessState;
}

export interface IChatSetProcessPayload {
  payload: IChatSetProcess;
}

export enum ChatTypeStatus {
  isChatLoading = 'isChatLoading',
}

export type TChatTypeStatus = ChatTypeStatus;
export type TChatStatusState = boolean;

export interface IChatSetStatus {
  typeStatus: TChatTypeStatus;
  value: TChatStatusState;
}

export interface IChatSetStatusPayload {
  payload: IChatSetStatus;
}
