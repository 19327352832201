import { connect } from 'react-redux';
import Component from './CategoryList';
import { getMapCategories } from '../../store/categories/selectors';
import { ICategories } from '../../types/category/ICategories';
import { getCategoriesData } from '../../store/categories/actions';
import { TRootState } from '../../types/reduxTypes';

export interface ICategoryListProps {
  mapCategories: ICategories[];
  getCategoryDataRequested: any;
  quantityToShow?: number;
  setCardSize?: 3 | 6 | 12;
}

const mapStateToProps = (state: TRootState) => ({
  mapCategories: getMapCategories(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getCategoryDataRequested: () => dispatch(getCategoriesData()),
});

const CategoryList = connect(mapStateToProps, mapDispatchToProps)(Component);

export default CategoryList;
