import { ICategoriesState } from 'types/category/ICategories';
import { CategoriesActionTypes } from './types';

const initialCategories: ICategoriesState = {
  isLoading: false,
  isError: false,
  mapCategories: [],
  activeCategories: null,
};

const categories = (
  state: ICategoriesState = initialCategories,
  action: any,
) => {
  switch (action.type) {
    case CategoriesActionTypes.GET_CATEGORIES_DATA:
      return { ...state, isLoading: true };
    case CategoriesActionTypes.CATEGORIES_DATA_DONE:
      return { ...state, isLoading: false, mapCategories: action.payload.data };
    case CategoriesActionTypes.GET_CATEGORIES_DATA_FAILED:
      return { ...state, isLoading: false, isError: true };
    case CategoriesActionTypes.CATEGORIES_SET_ACTIVE:
      return { ...state, activeCategories: action.payload.data };
    case CategoriesActionTypes.CATEGORIES_CLEAN_ACTIVE:
      return { ...state, activeCategories: null };
    default:
      return state;
  }
};

export default categories;
