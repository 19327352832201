import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import Title from '../Title';

interface ICenterTitleProps {
  title?: string;
  subtitle?: string;
}

const CenterTitle: FC<ICenterTitleProps> = ({ title, subtitle }) => (
  <Box display="flex">
    <Grid
      container
      direction="column"
      wrap="nowrap"
      justify="space-between"
      alignItems="stretch"
      spacing={2}
    >
      {title && (
        <Grid item>
          <Title title={title} position="center" component="h1" variant="h1" />
        </Grid>
      )}
      {subtitle && (
        <Grid item>
          <Title
            title={subtitle}
            position="center"
            component="p"
            variant="body1"
          />
        </Grid>
      )}
    </Grid>
  </Box>
);

export default CenterTitle;
