import { findIndex } from 'lodash';
import { WebinarActionTypes, IWebinarState } from './types';
import { IWebinar } from '../../types/webinar/IWebinar';

const initialWebinars: IWebinarState = {
  webinarRequestState: null,
  isError: false,
  webinarAddingState: null,
  webinarSelectedState: null,
  mapWebinars: [],
  lastSelect: [],
  currentSelector: {
    category: null,
    startTimeMin: null,
    startTimeMax: null,
  },
  currentMapWebinars: [],
};

const findCurrentWebinar = (mapWebinars: IWebinar[], id: number): number => {
  const indexCurentWebinar = findIndex(mapWebinars, { id });
  return indexCurentWebinar;
};

const webinars = (state: IWebinarState = initialWebinars, action: any) => {
  switch (action.type) {
    case WebinarActionTypes.GET_WEBINARS_DATA:
      return { ...state };
    case WebinarActionTypes.WEBINARS_DATA_DONE:
      return {
        ...state,
        webinarRequestState: 'done',
        mapWebinars: action.payload.data,
      };
    case WebinarActionTypes.GET_WEBINARS_DATA_FAILED:
      return { ...state, webinarRequestState: 'error', isError: true };
    case WebinarActionTypes.WEBINARS_CREATE_SET_STATE:
      return { ...state, webinarAddingState: action.payload.data };
    case WebinarActionTypes.WEBINARS_SET_STATE:
      return {
        ...state,
        [action.payload.data.typeState]: action.payload.data.value,
      };
    case WebinarActionTypes.WEBINARS_SET_CURRENT_SELECTOR:
      return {
        ...state,
        currentSelector: {
          ...state.currentSelector,
          [action.payload.typeState]: action.payload.value,
        },
      };
    case WebinarActionTypes.WEBINARS_CLEAN_CURRENT_SELECTOR:
      return {
        ...state,
        currentSelector: {
          category: null,
          startTimeMin: null,
          startTimeMax: null,
        },
      };
    case WebinarActionTypes.WEBINARS_CREATE:
      return { ...state, webinarAddingState: 'pending' };
    case WebinarActionTypes.WEBINARS_CREATE_DONE:
      return { ...state, webinarAddingState: 'done' };
    case WebinarActionTypes.WEBINARS_CREATE_FAILED:
      return {
        ...state,
        webinarAddingState: 'error',
        isError: true,
      };
    case WebinarActionTypes.WEBINARS_SELECT:
      return { ...state, webinarSelectedState: 'pending' };
    case WebinarActionTypes.WEBINARS_SELECT_DONE:
      return {
        ...state,
        webinarSelectedState: 'done',
        lastSelect: action.payload.data,
      };
    case WebinarActionTypes.RESET_SELECTION_WEBINAR:
      return {
        ...state,
        lastSelect: [],
      };
    case WebinarActionTypes.WEBINARS_SELECT_FAILED:
      return {
        ...state,
        webinarSelectedState: 'error',
        isError: true,
      };
    case WebinarActionTypes.WEBINARS_SET_PROCESS:
      return {
        ...state,
        process: {
          ...state.process,
          [action.payload.typeProcess]: action.payload.value,
        },
      };
    case WebinarActionTypes.WEBINARS_SET_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          [action.payload.typeStatus]: action.payload.value,
        },
      };
    case WebinarActionTypes.WEBINARS_REFRESH_DATA_DONE:
      // eslint-disable-next-line no-case-declarations
      const newArray = () => {
        const { mapWebinars } = state;
        const currentId = findCurrentWebinar(
          mapWebinars,
          action.payload.data.id
        );
        const newMapWebinars = mapWebinars;
        newMapWebinars[currentId] = action.payload.data;
        return newMapWebinars;
      };

      return {
        ...state,
        mapWebinars: newArray(),
      };
    default:
      return state;
  }
};

export default webinars;
