import { connect } from 'react-redux';
import { getSnacks } from 'store/snackbar/selectors';
import { clearSnackbar, popSnackbar } from 'store/snackbar/actions';
import { Dispatch } from 'redux';
import Component from './Snackbar';
import { ISnackbar } from '../../store/snackbar/types';
import { TRootState } from '../../types/reduxTypes';

export interface ISnackbarProps {
  snacks: ISnackbar[];
  popSnackbar: any;
  clearSnackbar: any;
}

function mapStateToProps(state: TRootState) {
  return {
    snacks: getSnacks(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    popSnackbar: () => dispatch(popSnackbar()),
    clearSnackbar: () => dispatch(clearSnackbar()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
