import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authInit } from '../../store/auth/actions';

const UserProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authInit());
  }, []);
  return <>{children}</>;
};

export default UserProvider;
