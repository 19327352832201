import React from 'react';
import { Grid } from '@material-ui/core';
import CenterTitle from 'common/CenterTitle';
import FormikStep from 'components/Form/common/FormikStep';
import { useFormikContext } from 'formik';
import UserImage from '../../../../routes/PageUser/UserImage';

const PhotoUploadStep = () => {
  const { setFieldValue }: any = useFormikContext();
  const callbackImageData = (imageData: never[]) => {
    setFieldValue('image', imageData);
  };
  return (
    <FormikStep>
      <CenterTitle title="Dodaj swoje zdjęcie" />
      <Grid container spacing={2} alignItems="center" direction="column">
        <UserImage image={null} callbackImageData={callbackImageData} />
      </Grid>
    </FormikStep>
  );
};

export default PhotoUploadStep;
