import React from 'react';
import { Grid } from '@material-ui/core';
import CenterTitle from 'common/CenterTitle';
import FormikStep from 'components/Form/common/FormikStep';
import { useField } from 'formik';
import UserImage from '../../../../routes/PageUser/UserImage';

const PhotoUploadStep = () => {
  const [field, meta, helpers] = useField('image');
  const { setValue } = helpers;
  const { value } = field;

  const callbackImageData = (imageData: never[]) => {
    setValue(imageData);
  };
  return (
    <FormikStep>
      <CenterTitle title="Dodaj swoje zdjęcie" />
      <Grid container spacing={2} alignItems="center" direction="column">
        <UserImage
          image={value || null}
          callbackImageData={callbackImageData}
        />
      </Grid>
    </FormikStep>
  );
};

export default PhotoUploadStep;
