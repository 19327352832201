import { TAlertIconType } from '../../constants/alertIcon';

export interface ISnackbar {
  message?: string;
  severity?: string;
  icon?: TAlertIconType | undefined;
  key?: string;
}

export interface ISnackbarShowPayload extends ISnackbar {
  payload: ISnackbar;
}

export enum SnackbarTypes {
  SNACKBAR_SHOW = 'SNACKBAR_SHOW',
  SNACKBAR_POP = 'SNACKBAR_POP',
  SNACKBAR_CLEAR = 'SNACKBAR_CLEAR',
}
