import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import LoggedIn from '../../../common/Guard/LoggedIn';
import UserMenu from '../../../components/UserMenu';
import { routes } from '../../../constants/routes';

const MenuEndContent: FC = () => (
  <>
    <LoggedIn
      LoggedInChildren={(
        <Grid item>
          <UserMenu />
        </Grid>
      )}
      LoggedOutChildren={(
        <>
          <Grid item>
            <Link to={routes.LOGIN.url}>
              <Button variant="text" size="small">
                Zaloguj
              </Button>
            </Link>
          </Grid>
          <Grid item>
            <Link to={routes.REGISTER.url}>
              <Button variant="text" size="small">
                Zarejestruj
              </Button>
            </Link>
          </Grid>
        </>
      )}
    />
  </>
);

export default MenuEndContent;
