import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import { Box, List, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import { useDispatch } from 'react-redux';
import { ICalendarMenuProps } from './index';

const CalendarMenu = memo(({ menuContent }: ICalendarMenuProps) => {
  const dispatch = useDispatch();

  const clickRedux = (callback: any) => {
    dispatch(callback);
  };
  return (
    <Box marginBottom={1} width={1}>
      <Paper elevation={3}>
        <List component="nav" aria-label="top navigation">
          {menuContent.map(({ name, href, callback }) => {
            if (href === null) {
              return (
                <ListItem>
                  <Button
                    variant="text"
                    size="small"
                    onClick={
                      callback
                        ? () => {
                          clickRedux(callback);
                        }
                        : () => {}
                    }
                  >
                    {name}
                  </Button>
                </ListItem>
              );
            }

            return (
              <Link
                // @ts-ignore
                to={href}
              >
                <ListItem>
                  <Button
                    variant="text"
                    size="small"
                    onClick={
                      callback
                        ? () => {
                          clickRedux(callback);
                        }
                        : () => {}
                    }
                  >
                    {name}
                  </Button>
                </ListItem>
              </Link>
            );
          })}
        </List>
      </Paper>
    </Box>
  );
});

export default CalendarMenu;
