/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { Field } from 'formik';
import FormikStep from 'components/Form/common/FormikStep';
import SocialButtonAuth from '../../../../common/SocialButtonAuth';
import CenterTitle from '../../../../common/CenterTitle';

// @ts-ignore
const StyledTextFieldUserName = ({ field, form, ...props }) => (
  <TextField
    {...field}
    {...props}
    label="Nazwa Użytkownika"
    variant="outlined"
    size="small"
  />
);

// @ts-ignore
const StyledTextFieldPassword = ({ field, form, ...props }) => (
  <TextField
    {...field}
    {...props}
    label="Hasło"
    variant="outlined"
    size="small"
    type="password"
  />
);

const FormFirstStep = () => (
  <FormikStep>
    <CenterTitle title="Miło Cię widzieć!" />
    <Grid container spacing={2} alignItems="center" direction="column">
      <Grid item xs>
        <SocialButtonAuth title="Zaloguj się za pomocą" />
      </Grid>
      <Grid item xs>
        <Box
          margin="auto"
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <Typography variant="h4">Lub uzupełnij dane</Typography>
        </Box>
      </Grid>
    </Grid>
    <Box paddingBottom={2}>
      <Field
        fullWidth
        name="username"
        component={StyledTextFieldUserName}
        label="Nazwa Użytkownika"
      />
    </Box>
    <Box paddingBottom={2}>
      <Field
        fullWidth
        name="password"
        component={StyledTextFieldPassword}
        label="Hasło użytkownika"
      />
    </Box>
  </FormikStep>
);

export default FormFirstStep;
