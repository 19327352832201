import React from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Box, Button, useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';

import theme from 'scss/themes';
import { ButtonShowAllProps } from '.';

const ButtonShowAll = ({
  buttonContent,
  buttonLink,
  align = 'right',
}: ButtonShowAllProps) => {
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });
  const setAlign = () => {
    if (align === 'right') {
      return 'flex-end';
    }
    return 'flex-start';
  };
  return (
    <Box
      paddingTop={4}
      paddingBottom={4}
      display="flex"
      justifyContent={isSm ? 'center' : setAlign()}
    >
      <Link to={buttonLink}>
        <Button
          variant="text"
          size="small"
          color="primary"
          endIcon={<ArrowForwardIcon />}
        >
          {buttonContent}
        </Button>
      </Link>
    </Box>
  );
};

export default ButtonShowAll;
