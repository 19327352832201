import styled from 'styled-components';
import { Box } from '@material-ui/core';
import theme from '../../scss/themes';

export const StyledInfiniteCarousel = styled(Box)`
  .InfiniteCarouselArrowNext {
    right: 0;
    height: 100%;
  }
  .InfiniteCarouselArrowPrev {
    left: 0;
    height: 100%;
  }

  .InfiniteCarouselArrowIcon {
    border-color: ${theme.palette.primary.main};
  }

  .InfiniteCarouselFrame {
    padding-bottom: ${theme.spacing(2)}px;
  }
`;
