import { connect } from 'react-redux';
import { getCategoryByName } from 'store/categories/selectors';
import { ICategories } from 'types/category/ICategories';
import Component from './WebinarCard';
import { TRootState } from '../../types/reduxTypes';

export interface IWebinarCardProps {
  title: string;
  thumbnail_image_src: string;
  thumbnail_image_alt: string;
  webinarLink: string;
  categoryName: string;
  mainTag?: string | undefined;
  start_time: string;
  category?: ICategories;
}

const mapStateToProps = (state: TRootState, ownProps: IWebinarCardProps) => ({
  category: getCategoryByName(state, ownProps.categoryName),
});

const WebinarCard = connect(mapStateToProps)(Component);

export default WebinarCard;
