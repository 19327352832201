import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { routes } from '../../../constants/routes';

const useStyles = makeStyles({
  root: {
    fontWeight: 700,
  },
});

const MenuStartContent: FC = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item>
        <Link to={routes.CALENDAR.url}>
          <Button variant="text" size="small">
            Kalendarz webinarów
          </Button>
        </Link>
      </Grid>
      {/* TODO: create page B2B */}
      {/* <Grid item> */}
      {/*  <Link to={routes.B2B.url}> */}
      {/*    <Button */}
      {/*      variant="text" */}
      {/*      size="small" */}
      {/*    > */}
      {/*      Promuj wydarzenia */}
      {/*    </Button> */}
      {/*  </Link> */}
      {/* </Grid> */}
      <Grid item>
        <Box marginLeft="12px">
          <Link to={routes.ADD_WEBINAR.url}>
            <Button
              className={classes.root}
              variant="text"
              size="small"
              color="primary"
              startIcon={<AddIcon />}
            >
              Dodaj webinar
            </Button>
          </Link>
        </Box>
      </Grid>
    </>
  );
};

export default MenuStartContent;
