import React, { FC } from 'react';
import { Grid, Box } from '@material-ui/core';
import { IHorizontalAutoLayoutProps } from './index';

const HorizontalAutoLayout: FC<IHorizontalAutoLayoutProps> = ({
  leftContent,
  rightContent,
}) => (
  <Box>
    <Grid justify="space-between" container alignItems="center" spacing={2}>
      <Grid item>{leftContent}</Grid>
      <Grid item>{rightContent}</Grid>
    </Grid>
  </Box>
);

export default HorizontalAutoLayout;
