import { endPoint } from '../constants/apiEndpoint';
import { IAuthGooglePayload, IAuthUserLoginPayload } from '../store/auth/types';
import constLocalStorage from '../constants/localStorage';
import { axiosInstance } from './axiosInstance';

const login = ({ payload }: IAuthUserLoginPayload) => {
  const { username, password } = payload;

  return axiosInstance
    .post(endPoint.login, { username, password })
    .then((response) => response)
    .catch((err) => err.response);
};

const logout = () =>
  axiosInstance
    .post(endPoint.logout)
    .then((response) => response)
    .catch((err) => err);

const requestLoginToken = (payload: any) => {
  const { username, password } = payload;
  return axiosInstance
    .post(endPoint.login, { username, password })
    .then((response) => response)
    .catch((err) => err.response);
};

const googleAuth = ({ payload }: IAuthGooglePayload) => {
  const { accessToken } = payload;

  // TODO: change to router and base url
  let REDIRECT_URI = 'https://darmowewebinary.pl/logowanie';
  if (process.env.NODE_ENV !== 'production') {
    REDIRECT_URI = 'https://localhost:3000/logowanie';
  }

  let ENDPOINT_AUTH = endPoint.googleAuth;
  if (process.env.NODE_ENV !== 'production') {
    ENDPOINT_AUTH = endPoint.googleAuthLocal;
  }

  return axiosInstance
    .post(ENDPOINT_AUTH, {
      access_token: accessToken,
      client_id: String(process.env.REACT_APP_CLIENT_ID_GOOGLE),
      redirect_uri: REDIRECT_URI,
      response_type: 'code',
      audience: [''],
      grant_type: [''],
    })
    .then((response) => response)
    .catch((err) => err);
};

const refreshToken = () => {
  const accessToken: string | null = localStorage.getItem(
    constLocalStorage.userAuthToken,
  );
  const refresh: string | null = localStorage.getItem(
    constLocalStorage.userRefreshToken,
  );
  return axiosInstance
    .post(
      endPoint.authRefresh,
      {
        refresh,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    )
    .then((response) => response)
    .catch((err) => err.response);
};

export default {
  requestLoginToken,
  logout,
  googleAuth,
  login,
  refreshToken,
};
