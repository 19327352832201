import React from 'react';
import { ReactComponent as CompactSVG } from 'assets/SVG/svg-compact.svg';
import { ReactComponent as AllInOnePlaceSVG } from 'assets/SVG/svg-all-in-one-place.svg';
import { ReactComponent as CalendarSVG } from 'assets/SVG/svg-calendar.svg';
import startStreamImage from 'assets/image/startStreamImage.png';
import takeParticipleImage from 'assets/image/takeParticipleImage.png';
import ContentJoinUs from './ContentJoinUs';
import { IContentLayoutProps } from '../../layout/ContentLayout';
import { ILayoutFeatureProps } from '../../layout/LayoutFeature';
import { routes } from '../../constants/routes';

export interface IContentJoinUsFeature extends ILayoutFeatureProps {
  id: number;
}

export interface IcontentBlock extends IContentLayoutProps {
  id: number;
}

export const contentFeature: IContentJoinUsFeature[] = [
  {
    id: 1,
    icon: <CompactSVG />,
    title: 'Wszystko w jednym miejscu',
    description:
      'Nie\u00A0musisz\u00A0już\u00A0przeczesywać\u00A0internetu w\u00A0poszukiwaniu\u00A0jakościowych\u00A0szkoleń -\u00A0my\u00A0robimy\u00A0to\u00A0za\u00A0Ciebie.',
  },
  {
    id: 2,
    icon: <CalendarSVG />,
    title: 'Wygodny kalendarz',
    description:
      'Z\u00A0nami\u00A0masz\u00A0pewność,\u00A0że\u00A0nic\u00A0Cię\u00A0nie ominie.\u00A0System\u00A0powiadomień przypomni\u00A0Ci\u00A0o\u00A0Twoich\u00A0wybranych szkoleniach.',
  },
  {
    id: 3,
    icon: <AllInOnePlaceSVG />,
    title: 'Skrojone na miarę',
    description:
      'Dajemy\u00A0Ci\u00A0możliwość\u00A0personalizacji swoich\u00A0zainteresowań.\u00A0Z\u00A0nami\u00A0nie musisz\u00A0się\u00A0bać\u00A0spamu.',
  },
];

export const contentBlock: IcontentBlock[] = [
  {
    id: 1,
    image: takeParticipleImage,
    altImage: 'Bierz udział w webinarach',
    title: 'Bierz udział w webinarach',
    textContent:
      'Znajdź darmowe szkolenia z sieci w jednym miejscu. Zarządzaj swoim kalendarzem i otrzymuj spersonalizowane powiadomienia.  Zacznij podnosić swoje kwalifikacje już dziś!',
    textButton: 'Załóż darmowe konto',
    linkTo: routes.REGISTER.url,
    layout: 'left',
    borderColor: '#FCC206',
  },
  {
    id: 2,
    image: startStreamImage,
    altImage: 'Streamuj i przekazuj wiedzę',
    title: 'Streamuj i przekazuj wiedzę',
    textContent:
      'Udostępniaj szkolenia szerokiemu gronu odbiorców, dziel się wiedzą oraz pasją i buduj swoją markę. Korzystaj z naszej platformy do publikowania lub promowania webinarów.',
    textButton: 'Załóż darmowe konto',
    linkTo: routes.REGISTER.url,
    layout: 'right',
    borderColor: '#FCC206',
  },
];

export default ContentJoinUs;
