import { Variant } from '@material-ui/core/styles/createTypography';
import Title from './Title';

export interface ITitleProps {
  title?: string;
  position?: 'left' | 'center' | undefined;
  component?: any;
  variant?: Variant;
}

export default Title;
