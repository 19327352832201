import { IWebinar, IWebinarCurrentSelector } from 'types/webinar/IWebinar';
import { IAppState, TProcessState } from '../../types/state/IAppState';
import { TRootState } from '../../types/reduxTypes';
import {
  IWebinarState,
  TWebinarsStatusState,
  TWebinarsTypeProcess,
  TWebinarsTypeStatus,
} from './types';

export const selectWebinars = ({ webinars }: IAppState) => webinars;

export function getWebinarsRequestState(state: IAppState): TProcessState {
  const webinars = selectWebinars(state);

  return webinars.webinarRequestState;
}
export function getWebinarSelectedState(state: IAppState): TProcessState {
  const webinars = selectWebinars(state);

  return webinars.webinarSelectedState;
}

export function getWebinarAddingState(state: IAppState): TProcessState {
  const webinars = selectWebinars(state);
  return webinars.webinarAddingState;
}

export function getMapWebinars(state: IAppState): IWebinar[] {
  const webinars = selectWebinars(state);
  const { mapWebinars }: IWebinarState = webinars;

  return mapWebinars;
}

export function getLastSelectedWebinars(state: IAppState): IWebinar[] | [] {
  const webinars = selectWebinars(state);
  const { lastSelect }: IWebinarState = webinars;

  return lastSelect;
}

export function getWebinarsById(
  state: TRootState,
  id: number | string | null = null
): IWebinar | null {
  if (id === null) {
    return null;
  }

  const searchId = Number(id);
  const mapWebinars = getMapWebinars(state);

  return mapWebinars.filter(
    (oneWebinars: { id: number }) => oneWebinars.id === searchId
  )[0];
}

export function getWebinarsBySlug(
  state: IAppState,
  slug: string | null = null
): IWebinar | null {
  if (slug === null) {
    return null;
  }

  const searchSlug = String(slug?.toLowerCase());
  const mapWebinars = getMapWebinars(state);

  return mapWebinars.filter(
    (oneWebinars) => oneWebinars.slug === searchSlug
  )[0];
}

// eslint-disable-next-line max-len
export function mapWebinarsByCategory(
  state: IAppState,
  categoryName: number | string | null = null
): IWebinar[] | null {
  if (categoryName === null) {
    return null;
  }
  const mapWebinars = getMapWebinars(state);

  // eslint-disable-next-line max-len
  return mapWebinars.filter(
    (oneWebinars: { category: string }) => oneWebinars.category === categoryName
  );
}

export function getWebinarCurrentSelector(
  state: TRootState
): IWebinarCurrentSelector {
  const webinars = selectWebinars(state);
  return webinars.currentSelector;
}

export const selectWebinarsProcess = (
  state: TRootState,
  typeProcess: TWebinarsTypeProcess
): TProcessState | undefined => {
  const webinars = selectWebinars(state);

  if (webinars.process) {
    return webinars.process[typeProcess];
  }

  return undefined;
};

export const selectWebinarsStatus = (
  state: TRootState,
  typeStatus: TWebinarsTypeStatus
): TWebinarsStatusState | undefined => {
  const webinars = selectWebinars(state);

  if (webinars.status) {
    return webinars.status[typeStatus];
  }

  return undefined;
};
