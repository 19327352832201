import { createAction } from '@reduxjs/toolkit';
import {
  ISelectAuthorWebinarsPayload,
  ISelectedWebinars,
  ISelectedWebinarsPayload,
  IWebinarSelectDate,
  IWebinarSelectDatePayload,
  IWebinarSetCurrentSelector,
  IWebinarSetCurrentSelectorPayload,
  IWebinarsSetProcess,
  IWebinarsSetStatus,
  IWebinarsSetProcessPayload,
  IWebinarsSetStatusPayload,
  WebinarActionTypes,
  IWebinarsRefreshVideoId,
  IWebinarsRefreshVideoIdPayload,
  IWebinarsRefreshData,
  IWebinarsRefreshDataPayload,
} from './types';

export const getWebinarsData = createAction(
  WebinarActionTypes.GET_WEBINARS_DATA
);

export const webinarsDataDone = createAction(
  WebinarActionTypes.WEBINARS_DATA_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  })
);

export const getWebinarsDataFailed = createAction(
  WebinarActionTypes.GET_WEBINARS_DATA_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('GET_WEBINARS_DATA_FAILED', error);
    return { payload: { error } };
  }
);

export const createWebinar = createAction(
  WebinarActionTypes.WEBINARS_CREATE,
  (
    description: string,
    title: string,
    idInterest: string,
    startTime: string,
    endTime: string,
    eventPage: string,
    mainImageSrc: string,
    originInternal: boolean
  ) => ({
    payload: {
      description,
      title,
      idInterest,
      startTime,
      endTime,
      eventPage,
      mainImageSrc,
      originInternal,
    },
  })
);

export const createWebinarDone = createAction(
  WebinarActionTypes.WEBINARS_CREATE_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  })
);

export const createWebinarFailed = createAction(
  WebinarActionTypes.WEBINARS_CREATE_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('WEBINARS_CREATE_FAILED', error);
    return { payload: { error } };
  }
);

export const createWebinarSetState = createAction(
  WebinarActionTypes.WEBINARS_CREATE_SET_STATE,
  (data = {}) => ({
    payload: {
      data,
    },
  })
);

export const webinarSetState = createAction(
  WebinarActionTypes.WEBINARS_SET_STATE,
  (data = {}) => ({
    payload: {
      data,
    },
  })
);
export const webinarSetCurrentSelector = createAction(
  WebinarActionTypes.WEBINARS_SET_CURRENT_SELECTOR,
  ({
    typeState,
    value,
  }: IWebinarSetCurrentSelector): IWebinarSetCurrentSelectorPayload => ({
    payload: {
      typeState,
      value,
    },
  })
);

export const webinarCleanCurrentSelector = createAction(
  WebinarActionTypes.WEBINARS_CLEAN_CURRENT_SELECTOR
);

export const selectWebinar = createAction(
  WebinarActionTypes.WEBINARS_SELECT,
  ({
    startTimeMin,
    startTimeMax,
    author,
    category,
    tags,
  }: ISelectedWebinars): ISelectedWebinarsPayload => ({
    payload: {
      startTimeMin,
      startTimeMax,
      author,
      category,
      tags,
    },
  })
);

export const selectAuthorWebinar = createAction(
  WebinarActionTypes.WEBINARS_SELECT_AUTHOR,
  ({ author = '' }: ISelectedWebinars): ISelectAuthorWebinarsPayload => ({
    payload: {
      author,
    },
  })
);

export const webinarSelectDate = createAction(
  WebinarActionTypes.WEBINARS_SELECT_DATE,
  ({
    startTimeMin,
    startTimeMax,
  }: IWebinarSelectDate): IWebinarSelectDatePayload => ({
    payload: {
      startTimeMin,
      startTimeMax,
    },
  })
);

export const selectWebinarDone = createAction(
  WebinarActionTypes.WEBINARS_SELECT_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  })
);

export const resetSelectionWebinar = createAction(
  WebinarActionTypes.RESET_SELECTION_WEBINAR,
  (data = {}) => ({
    payload: {
      data,
    },
  })
);

export const selectWebinarFailed = createAction(
  WebinarActionTypes.WEBINARS_SELECT_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('WEBINARS_SELECT_FAILED', error);
    return { payload: { error } };
  }
);

export const webinarsSetProcess = createAction(
  WebinarActionTypes.WEBINARS_SET_PROCESS,
  ({
    typeProcess,
    value,
  }: IWebinarsSetProcess): IWebinarsSetProcessPayload => ({
    payload: { typeProcess, value },
  })
);

export const webinarsSetStatus = createAction(
  WebinarActionTypes.WEBINARS_SET_STATUS,
  ({ typeStatus, value }: IWebinarsSetStatus): IWebinarsSetStatusPayload => ({
    payload: { typeStatus, value },
  })
);

export const webinarsRefreshData = createAction(
  WebinarActionTypes.WEBINARS_REFRESH_DATA,
  ({ id }: IWebinarsRefreshData): IWebinarsRefreshDataPayload => ({
    payload: { id },
  })
);

export const webinarsRefreshDataDone = createAction(
  WebinarActionTypes.WEBINARS_REFRESH_DATA_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  })
);

export const webinarsRefreshVideoId = createAction(
  WebinarActionTypes.WEBINARS_REFRESH_VIDEO_ID,
  ({
    selectedWebinar,
  }: IWebinarsRefreshVideoId): IWebinarsRefreshVideoIdPayload => ({
    payload: { selectedWebinar },
  })
);
