import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import Title from '../../common/Title';
import LayoutFeature from '../../layout/LayoutFeature';
import { contentFeature, contentBlock } from './index';
import ContentLayout from '../../layout/ContentLayout';

const ContentJoinUs: FC = () => (
  <Box>
    <Title
      title="Dołącz do nas juz dziś"
      position="center"
      component="h2"
      variant="h2"
    />
    <Box
      paddingTop={5}
      paddingBottom={5}
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      width={1}
    >
      <Grid container spacing={4} alignItems="center">
        {contentFeature.map(({ id, icon, title, description }) => (
          <LayoutFeature
            key={id}
            icon={icon}
            title={title}
            description={description}
          />
        ))}
      </Grid>
    </Box>
    <Box
      paddingTop={5}
      paddingBottom={5}
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      width={1}
    >
      <Grid container alignItems="center">
        {contentBlock.map(
          ({
            id,
            image,
            altImage,
            title,
            textContent,
            textButton,
            linkTo,
            layout,
            borderColor,
          }) => (
            <ContentLayout
              key={id}
              image={image}
              altImage={altImage}
              title={title}
              textContent={textContent}
              textButton={textButton}
              linkTo={linkTo}
              layout={layout}
              borderColor={borderColor}
            />
          ),
        )}
      </Grid>
    </Box>
  </Box>
);

export default ContentJoinUs;
