/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import FormikControl, {
  EControlType,
} from 'components/Form/common/FormikControl';
import FormikStep, {
  IFormikStepProps,
} from 'components/Form/common/FormikStep';

const NewsletterEmail: FC<IFormikStepProps> = ({ validationSchema }) => (
  <FormikStep validationSchema={validationSchema}>
    <FormikControl
      controlType={EControlType.INPUT}
      type="text"
      label="Adres email"
      helperText="* Żadnego spamu, obiecujemy!"
      name="email"
      size="normal"
    />
  </FormikStep>
);

export default NewsletterEmail;
