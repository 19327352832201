import { connect } from 'react-redux';
import Component from './RegisterSocialMediaForm';
import { getUserEditDetailsState } from '../../../store/user/selectors';
import { getCategoriesData } from '../../../store/categories/actions';
import { getMapCategories } from '../../../store/categories/selectors';
import { ICategories } from '../../../types/category/ICategories';
import { TRootState } from '../../../types/reduxTypes';

export interface IRegisterSocialMediaFormProps {
  userEditDetailsState: any;
  getCategoryDataRequested: any;
  mapCategories?: ICategories[];
}

export interface IRegisterUserValueForm {
  email: string;
  name: string;
  surname: string;
  idInterest: number[] | string[] | any;
  image: string;
  agreementRegulations: boolean | undefined;
  agreementNewsletter: boolean | undefined;
  agreementPersonalised: boolean | undefined;
}
export interface IUserSocialRegister {
  name?: string | undefined;
  surname?: string | undefined;
  interests?: number[] | string[] | undefined;
  image?: string | undefined;
  agreementRegulations?: boolean | undefined;
  agreementNewsletter?: boolean | undefined;
  agreementPersonalised?: boolean | undefined;
}

export interface RegisterSocialMediaFormInitialValues {
  email: string | null;
  username: string | null;
  name: string | null;
  surname: string | null;
  idInterest: number[] | string[] | any;
  image: string | null;
  allAgreement: boolean | undefined;
  agreementRegulations: boolean | undefined;
  agreementNewsletter: boolean | undefined;
  agreementPersonalised: boolean | undefined;
}

const mapStateToProps = (state: TRootState) => ({
  mapCategories: getMapCategories(state),
  userEditDetailsState: getUserEditDetailsState(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getCategoryDataRequested: () => dispatch(getCategoriesData()),
});
const RegisterSocialMediaForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default RegisterSocialMediaForm;
