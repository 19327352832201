import React, { FC } from 'react';
import { CircularProgress, FormGroup } from '@material-ui/core';
import FormikStep from 'components/Form/common/FormikStep';
import CenterTitle from 'common/CenterTitle';
import FormikControl, {
  EControlType,
} from 'components/Form/common/FormikControl';
import { IUserInterestsStepProps } from './index';

const UserInterestsStep: FC<IUserInterestsStepProps> = ({
  mapCategories,
  title,
  subtitle,
  isMultiselect = false,
  validationSchema,
}) => (
  <FormikStep validationSchema={validationSchema}>
    <CenterTitle title={title} subtitle={subtitle} />
    <FormGroup row>
      {mapCategories ? (
        <FormikControl
          controlType={EControlType.CHECKBOX_GROUP}
          isMultiselect={isMultiselect}
          name="idInterest"
          options={mapCategories}
        />
      ) : (
        <CircularProgress />
      )}
    </FormGroup>
  </FormikStep>
);

export default UserInterestsStep;
