import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { useDispatch, useSelector } from 'react-redux';
import { IUserImageProps } from './index';
import { StyledAvatar, CircleButton } from './styled';
import ButtonCallback from '../../../common/ButtonCallback';
import { userEditImage } from '../../../store/user/actions';
import { TRootState } from '../../../types/reduxTypes';
import { getUserState } from '../../../store/user/selectors';
import { TypeProcess } from '../../../store/user/types';

const UserImage = ({ image, callbackImageData }: IUserImageProps) => {
  const dispatch = useDispatch();
  const userImageChange = useSelector((state: TRootState) =>
    getUserState(state, TypeProcess.userImageChange),
  );
  const [uploadImage, setUploadImage] = useState([]);
  const [urlImages, setUrlImages] = useState<any>(null);

  const onChange = (imageList: ImageListType) => {
    setUploadImage(imageList as never[]);
  };
  const handleUploadImage = () => {
    if (uploadImage[0]) {
      if (callbackImageData) {
        callbackImageData(uploadImage[0]);
        setUrlImages(null);
      } else {
        dispatch(userEditImage(uploadImage[0]));
      }
    }
  };

  return (
    <ImageUploading value={uploadImage} onChange={onChange}>
      {({ onImageUpload, onImageUpdate, imageList }) => {
        const [currentImage, setCurrentImage] = useState(image);
        useEffect(() => {
          if (imageList[0]?.dataURL) {
            setCurrentImage(imageList[0]?.dataURL);
            setUrlImages(imageList[0]?.dataURL);
          }
        }, [imageList]);
        const handleOnUpload = () => {
          onImageUpload();
        };
        const handleReUploadImage = () => {
          onImageUpdate(0);
        };
        return (
          <Box
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <CircleButton
              onClick={currentImage ? handleReUploadImage : handleOnUpload}
            >
              <Button variant="text" size="small" color="primary">
                <StyledAvatar src={currentImage || ''}>
                  <PhotoCameraIcon />
                </StyledAvatar>
              </Button>
            </CircleButton>
            <Box margin={2}>
              <Grid
                container
                direction="row"
                justify="space-around"
                spacing={2}
              >
                <Grid item>
                  <Button
                    disabled={userImageChange === 'pending'}
                    variant="outlined"
                    color="primary"
                    size="large"
                    component="label"
                    onClick={
                      currentImage ? handleReUploadImage : handleOnUpload
                    }
                  >
                    {currentImage ? 'Zmień zdjęcie' : 'Dodaj zdjęcie'}
                  </Button>
                </Grid>
                {urlImages && (
                  <Grid item>
                    <ButtonCallback
                      handleClick={handleUploadImage}
                      state={userImageChange}
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Wgraj zdjęcie
                    </ButtonCallback>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        );
      }}
    </ImageUploading>
  );
};

export default UserImage;
