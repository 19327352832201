import { createAction } from '@reduxjs/toolkit';
import {
  CategoriesActionTypes,
  ICategoriesSetActive,
  ICategoriesSetActivePayload,
} from './types';

export const getCategoriesData = createAction(
  CategoriesActionTypes.GET_CATEGORIES_DATA,
);

export const categoriesDataDone = createAction(
  CategoriesActionTypes.CATEGORIES_DATA_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  }),
);

export const categoriesSetActive = createAction(
  CategoriesActionTypes.CATEGORIES_SET_ACTIVE,
  (id: ICategoriesSetActive): ICategoriesSetActivePayload => ({
    payload: {
      id,
    },
  }),
);

export const categoriesCleanActive = createAction(
  CategoriesActionTypes.CATEGORIES_CLEAN_ACTIVE,
);

export const getCategoriesDataFailed = createAction(
  CategoriesActionTypes.GET_CATEGORIES_DATA_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('GET_CATEGORIES_DATA_FAILED', error);
    return { payload: { error } };
  },
);
