import { connect } from 'react-redux';
import Component from './UserMenu';
import { getUserImage, selectUsername } from '../../store/user/selectors';
import { TRootState } from '../../types/reduxTypes';

export interface IUserMenuProps {
  username: string | null | undefined;
  userImage: string | null | undefined;
}

const mapStateToProps = (state: TRootState) => ({
  username: selectUsername(state),
  userImage: getUserImage(state),
});

const UserMenu = connect(mapStateToProps)(Component);

export default UserMenu;
