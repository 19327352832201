export const categoryDescription = {
  1: {
    name: 'Rozwój Osobisty',
    slug: 'rozwoj-osobisty',
    title: 'Rozwój osobisty - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat rozwoju osobistego. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  2: {
    name: 'Marketing',
    slug: 'marketing',
    title: 'Marketing - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat marketingu i reklamy. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  3: {
    name: 'Technologie',
    slug: 'technologie',
    title: 'IT i Technologie - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat IT oraz nowych technologii. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  4: {
    name: 'Zdrowie',
    slug: 'zdrowie',
    title: 'Zdrowie - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat zdrowia. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  5: {
    name: 'Sport i Fitness',
    slug: 'sport-i-fitness',
    title: 'Sport i fitness - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat sportu i fitness. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  6: {
    name: 'Uczenie się',
    slug: 'uczenie-sie',
    title: 'Nauka - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat nauki oraz nauczania. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  7: {
    name: 'Taniec',
    slug: 'taniec',
    title: 'Taniec - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat tańca. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  8: {
    name: 'Hobby',
    slug: 'hobby',
    title: 'Hobby - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat wielu rodzajów hobby i pasji. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  9: {
    name: 'Kariera i Biznes',
    slug: 'kariera-i-biznes',
    title: 'Kariera i biznes - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat kariery i biznesu. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  10: {
    name: 'It',
    slug: 'it',
    title: 'IT i Technologie - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat IT oraz nowych technologii. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  11: {
    name: 'Hr',
    slug: 'hr',
    title: 'HR, Zasoby Ludzkie  - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat HR - zasobów ludzkich. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  12: {
    name: 'Finanse',
    slug: 'finanse',
    title: 'Finanse - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat finansów i bankowości. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  13: {
    name: 'Fotografia',
    slug: 'fotografia',
    title: 'Fotografia - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat fotografii. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  14: {
    name: 'Rozwój biznesu',
    slug: 'rozwoj-biznesu',
    title: 'Rozwój biznesu - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat prowadzenia biznesu. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  15: {
    name: 'Prawo',
    slug: 'prawo',
    title: 'Prawo - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat prawa. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
  16: {
    name: 'Medycyna',
    slug: 'medycyna',
    title: 'Medycyna - Darmowe Webinary',
    description:
      'Najbliższe nadchodzące webinary na temat medycyny i leczenia. Sprawdź kalendarz, wybierz najciekawszy i weź udział!',
  },
};
