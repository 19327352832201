import { endPoint } from '../constants/apiEndpoint';
import { axiosInstance } from './axiosInstance';

const requestCategory = () =>
  axiosInstance
    .get(endPoint.categories, {})
    .then((response) => response)
    .catch((err) => err.response);

export default { requestCategory };
