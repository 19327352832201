import React, { FC } from 'react';
import { IMenuListProps } from './index';
import { StyledMenu } from './styled';

const MenuList: FC<IMenuListProps> = ({ children, menuRef, setRef }) => {
  const handleClose = () => {
    setRef(null);
  };

  return (
    <StyledMenu
      anchorEl={menuRef}
      open={Boolean(menuRef)}
      getContentAnchorEl={null}
      onClose={handleClose}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {children}
    </StyledMenu>
  );
};
export default MenuList;
