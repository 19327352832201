import axios from 'axios';
import store from 'store';
import { ErrorMessage } from 'formik';
import { baseURL, endPoint } from '../constants/apiEndpoint';
import { snackbarShow } from '../store/snackbar/actions';
import { AlertIcon } from '../constants/alertIcon';
import constLocalStorage from '../constants/localStorage';
import authService from '../utils/api/authService';

const authEndpoint = [
  endPoint.webinars,
  endPoint.logout,
  endPoint.user,
  endPoint.join,
  endPoint.leave,
  endPoint.chat.message,
];

const authGetEndpoint = [endPoint.stream.start, endPoint.stream.stop];

const loginAuthEndpoint = [
  endPoint.login,
  endPoint.googleAuth,
  endPoint.googleAuthLocal,
];

export const axiosInstance = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const newConfig = config;
    const responseEndPoint = config.url || '';
    const method = config.method || '';

    if (
      (authEndpoint.includes(responseEndPoint) && method === 'post') ||
      (authGetEndpoint.includes(responseEndPoint) && method === 'get')
    ) {
      const freshToken = await authService.getFreshToken();
      newConfig.headers.Authorization = `Bearer ${freshToken}`;
    }

    return newConfig;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    const { config, data } = response;
    const responseEndPoint = config.url || '';

    if (endPoint.authRefresh.includes(responseEndPoint)) {
      const {
        access: accessToken,
        refresh: refreshToken,
        access_token_expiration: accessTokenExpiration,
      } = data;
      localStorage.setItem(constLocalStorage.userAuthToken, accessToken);
      localStorage.setItem(constLocalStorage.userRefreshToken, refreshToken);
      localStorage.setItem(
        constLocalStorage.userAccessTokenExpiration,
        accessTokenExpiration
      );
    }

    if (loginAuthEndpoint.includes(responseEndPoint)) {
      const { access_token: accessToken, refresh_token: refreshToken } = data;
      localStorage.setItem(constLocalStorage.userAuthToken, accessToken || '');
      localStorage.setItem(
        constLocalStorage.userRefreshToken,
        refreshToken || ''
      );
    }

    return response;
  },
  (error) => {
    if (!error.response) {
      return Promise.reject(new Error('Network Error'));
    }

    const responseEndPoint = error.response.config.url || '';
    const { status, data } = error.response;
    let message = `[Błąd ${status}] - ${JSON.stringify(data)}`;

    if (responseEndPoint === endPoint.stream.webinarToken) {
      message = 'Kanał do streamu nie jest gotowy';
    }

    if (responseEndPoint === endPoint.login && status === 400) {
      message = 'Nieprawidłowe dane logowania';
    }
    if (responseEndPoint === endPoint.chat.message) {
      message = 'Zaloguj się, zanim wyślesz wiadomość';
    }
    store.dispatch(
      snackbarShow({
        payload: {
          message,
          severity: 'warning',
          icon: AlertIcon.ERROR,
        },
      })
    );
    // eslint-disable-next-line no-console
    console.log(error, error.response);

    return error.response;
  }
);
