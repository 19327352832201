import React, { FC } from 'react';
import AppLayout from 'layout/AppLayout';
import { Route, Switch } from 'react-router-dom';
import RegisterForm from 'components/Form/RegisterForm';
import RegisterSocialMediaForm from 'components/Form/RegisterSocialMediaForm';
import { routes } from 'constants/routes';
import { IPageRegisterProps } from './index';

const PageRegister: FC<IPageRegisterProps> = () => (
  <AppLayout bgColor="#ECF1FD">
    <Switch>
      <Route exact path={routes.REGISTER.url} render={() => <RegisterForm />} />
      <Route
        exact
        path={routes.REGISTER_SOCIAL.url}
        render={() => <RegisterSocialMediaForm />}
      />
    </Switch>
  </AppLayout>
);

export default PageRegister;
