import React, { FC } from 'react';
import { Grid, Box, Hidden } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import NewsletterForm from 'components/Form/NewsletterForm';
import {
  EmojiIcon,
  EmojiContainer,
  TitleContainer,
  HomeInformationContainer,
} from './styled';
import HomeImage from '../HomeImage';

const HomeInformation: FC = () => (
  <Box marginRight="auto">
    <HomeInformationContainer>
      <Grid container>
        <Grid item xs>
          <EmojiContainer>
            <EmojiIcon symbol="👋" label="hello" />
            <Typography component="p" variant="overline" color="textSecondary">
              dołącz do nas
            </Typography>
          </EmojiContainer>
          <TitleContainer>
            <Typography component="h1" variant="h1">
              Najlepsze webinary
              <br />
              <span>w jednym miejscu</span>
            </Typography>
          </TitleContainer>
          <Hidden mdUp>
            <Box marginBottom="24px">
              <HomeImage />
            </Box>
          </Hidden>
          <Box marginBottom="32px">
            <Typography component="p" variant="body1" color="textSecondary">
              Zdobywaj fachową wiedzę, nie wychodząc z domu.
              <br />
              W dodatku zupełnie za darmo! Zapisz się do naszego
              <br />
              newslettera, żeby nie ominęły Cię najciekawsze szkolenia.
            </Typography>
          </Box>

          <NewsletterForm />
        </Grid>
      </Grid>
    </HomeInformationContainer>
  </Box>
);

export default HomeInformation;
