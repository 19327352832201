import React, { useEffect, useState } from 'react';
import { Box, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import theme from '../../scss/themes';
import {
  StyledButtonGroup,
  LastButton,
  CenterButton,
  FirstButton,
} from './styled';
import { ISocialButtonAuthProps } from '.';
import { authGoogle } from '../../store/auth/actions';
import { TRootState } from '../../types/reduxTypes';
import { getUserDetails, getUserState } from '../../store/user/selectors';
import { routes } from '../../constants/routes';
import { TypeProcess } from '../../store/user/types';

const SocialButtonAuth = ({ title }: ISocialButtonAuthProps) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state: TRootState) => getUserDetails(state));
  const [isNewSocialUser, setIsNewSocialUser] = useState(false);
  const [isLogInSocialUser, setTsLogInSocialUser] = useState(false);
  const userRegisterState = useSelector((state: TRootState) =>
    getUserState(state, TypeProcess.userRegisterState),
  );

  useEffect(() => {
    if (
      userDetails?.agreementRegulations === false &&
      userRegisterState !== 'error' &&
      userRegisterState !== 'pending'
    ) {
      setIsNewSocialUser(true);
    }

    if (userDetails?.agreementRegulations === true) {
      setTsLogInSocialUser(true);
    }
  }, [userDetails]);

  const isXs = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  });

  const responseGoogle = (response: any) => {
    const { accessToken } = response;
    dispatch(authGoogle({ accessToken }));
  };

  const handleError = (error: any) => {
    // eslint-disable-next-line no-console
    console.log(error);
  };

  // @ts-ignore
  return (
    <Box width={1}>
      {isLogInSocialUser ? <Redirect to={routes.USER.url} /> : null}
      {isNewSocialUser ? <Redirect to={routes.REGISTER_SOCIAL.url} /> : null}
      <Box
        margin="auto"
        marginBottom={2}
        alignItems="center"
        display="flex"
        justifyContent="center"
      >
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
      <StyledButtonGroup
        size="large"
        color="primary"
        aria-label="outlined secondary button group"
        orientation={isXs ? 'vertical' : 'horizontal'}
      >
        <Tooltip
          title="Już wkrótce udostępnimy funkcje związane z Facebook"
          placement="top"
        >
          <Box>
            <FirstButton
              startIcon={<FacebookIcon />}
              color="primary"
              variant="outlined"
              disabled
            >
              Facebook
            </FirstButton>
          </Box>
        </Tooltip>
        <GoogleLogin
          clientId={String(process.env.REACT_APP_CLIENT_ID_GOOGLE)}
          onSuccess={responseGoogle}
          onFailure={handleError}
          cookiePolicy="single_host_origin"
          render={(renderProps) => (
            <CenterButton
              startIcon={<GTranslateIcon />}
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              color="primary"
              variant="outlined"
            >
              Google
            </CenterButton>
          )}
        />
        <Tooltip
          title="Już wkrótce udostępnimy funkcje związane z LinkedIn"
          placement="top"
        >
          <Box>
            <LastButton
              startIcon={<LinkedInIcon />}
              color="primary"
              variant="outlined"
              disabled
            >
              LinkedIn
            </LastButton>
          </Box>
        </Tooltip>
      </StyledButtonGroup>
    </Box>
  );
};

export default SocialButtonAuth;
