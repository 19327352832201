import React, { useMemo, useState } from 'react';
import { Box } from '@material-ui/core/';
import { IWebinarRowListProps } from './index';
import DayList from './DayList';
import { sortedWebinarsByDay } from '../../utils/sortedWebinarsByDay';

const WebinarRowList = ({ webinarsData }: IWebinarRowListProps) => {
  const sortedWebinars = useMemo(() => sortedWebinarsByDay(webinarsData), [
    webinarsData,
  ]);
  return (
    <Box height={1}>
      <DayList length={sortedWebinars.length} mapArray={sortedWebinars} />
    </Box>
  );
};

export default WebinarRowList;
