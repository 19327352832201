import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { userLeaveWebinar, userSignToWebinar } from 'store/user/actions';
import Component from './ButtonSigninWebinar';
import { IWebinar } from '../../types/webinar/IWebinar';
import { getUserSavedWebinars } from '../../store/user/selectors';
import { TRootState } from '../../types/reduxTypes';

export interface IButtonSigninWebinarProps {
  webinarID: number;
  title: string;
  eventPage: string;
  fullWidth?: boolean;
}

export interface IButtonSigninWebinarReduxMap
  extends IButtonSigninWebinarProps {
  userSignToWebinar: any;
  userLeaveWebinar: any;
  isSignedIn: boolean;
}

const mapStateToProps = (
  state: TRootState,
  { webinarID }: IButtonSigninWebinarProps,
) => {
  const savedWebinarList: IWebinar[] = getUserSavedWebinars(state);
  const isSignedIn = savedWebinarList.find(({ id }) => id === webinarID);
  return {
    isSignedIn: Boolean(isSignedIn),
    getUserSavedWebinars: getUserSavedWebinars(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  userSignToWebinar: (webinarID: number) =>
    dispatch(userSignToWebinar(webinarID)),
  userLeaveWebinar: (webinarID: number) =>
    dispatch(userLeaveWebinar(webinarID)),
});

const ButtonSigninWebinar = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);

export default ButtonSigninWebinar;
