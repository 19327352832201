import { createAction } from '@reduxjs/toolkit';
import { BlogpostTypes } from './types';

export const getBlogPostData = createAction(BlogpostTypes.GET_BLOG_POST_DATA);

export const getBlogPostDataDone = createAction(
  BlogpostTypes.GET_BLOG_POST_DATA_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  }),
);

export const getBlogPostDataFailed = createAction(
  BlogpostTypes.GET_BLOG_POST_DATA_FAILED,
  (error) => {
    console.error('GET_BLOG_POST_DATA_FAILED', error);
    return {
      payload: { error },
    };
  },
);
