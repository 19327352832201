import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';

import TagManager from 'react-gtm-module';
import { GlobalStyles } from './scss/styled';
import * as serviceWorker from './serviceWorker';
import Router from './routes/Router';
import theme from './scss/themes';

const tagManagerArgs = {
  gtmId: 'GTM-TPSC8C9',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&display=swap"
      rel="stylesheet"
    />
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
