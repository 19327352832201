import React, { FC } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { IProgressBarProps } from './index';

const ProgressBar: FC<IProgressBarProps> = ({
  progress,
  stepText,
  onClick,
  isSkip,
}) => (
  <Box position="relative">
    <Box padding={1}>
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <Typography variant="caption">{stepText}</Typography>
        </Grid>
        {isSkip && (
          <Box position="absolute" right={0} top={0}>
            <Grid item>
              <Button variant="text" color="secondary" onClick={onClick}>
                Pomiń
              </Button>
            </Grid>
          </Box>
        )}
      </Grid>
    </Box>
    <Box padding={1}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  </Box>
);

export default ProgressBar;
