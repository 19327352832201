import { TPopularTag } from 'types';

const initialPopularTag: TPopularTag[] = [
  'Rozwój osobisty',
  'Marketing',
  'Technologie',
  'Zdrowie',
  'Sport i fitness',
  'Uczenie się',
  'Taniec',
  'Hobby',
];

const popularTag = (state = initialPopularTag, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default popularTag;
