import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from 'assets/common/logo-sygnet.svg';
import { routes } from '../../constants/routes';

const LogoContainer = styled.div.attrs({
  className: 'w-auto mr-8',
})`
  max-width: 137px;
`;

export type AppLayoutType = {
  children: ReactNode;
};

function Logo() {
  return (
    <Link to={routes.HOME.url}>
      <LogoContainer>
        <LogoSVG />
      </LogoContainer>
    </Link>
  );
}

export default Logo;
