import { combineReducers } from 'redux';
import webinars from './webinars/reducer';
import categories from './categories/reducer';
import user from './user/reducer';
import blogPost from './blogPost/reducer';
import popularTag from './popularTag/reducer';
import snackbar from './snackbar/reducer';
import chat from './chat/reducer';
import auth from './auth/reducer';

const reducers = combineReducers({
  auth,
  webinars,
  user,
  blogPost,
  categories,
  popularTag,
  snackbar,
  chat,
});

export default reducers;
