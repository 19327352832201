import ContentLayout from './ContentLayout';

export interface IContentLayoutProps {
  image: string;
  altImage: string;
  title: string;
  textContent: string;
  textButton: string;
  linkTo: string;
  layout: 'left' | 'right' | undefined;
  borderColor: string;
}

export default ContentLayout;
