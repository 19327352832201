import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Grid,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Chip from '@material-ui/core/Chip';
// import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import { Link } from 'react-router-dom';
import date from 'date-and-time';
import { IWebinarCardProps } from './index';
import { StyledCardContent, TextLimiter } from './styled';
import TimeDetails from '../TimeDetails';
import isOverHeightTitle from '../../utils/isOverHeightTitle';
import { routes } from '../../constants/routes';
import theme from '../../scss/themes';

const WebinarCard = ({
  title,
  thumbnail_image_src,
  thumbnail_image_alt,
  category,
  webinarLink,
  start_time,
}: IWebinarCardProps) => {
  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });
  const titleElement: any = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipHandleClose = () => {
    setTooltipOpen(false);
  };

  const tooltipHandleOpen = () => {
    if (isOverHeightTitle(titleElement.current)) {
      setTooltipOpen(true);
    }
  };

  return (
    <Card elevation={3}>
      <Box position="relative">
        <Box padding={isSm ? 1 : 2} position="absolute">
          <Link to={`${routes.CATEGORY.url}/${category?.slug || category?.id}`}>
            <Chip label={category?.name} size="small" clickable />
          </Link>
        </Box>
        <Link to={webinarLink}>
          <CardMedia
            component="img"
            alt={thumbnail_image_alt}
            height="200"
            image={thumbnail_image_src}
            title={thumbnail_image_alt}
          />
        </Link>
      </Box>
      <Link to={webinarLink}>
        <StyledCardContent>
          <Grid container spacing={1} direction="column">
            <Grid item xs>
              <Tooltip
                open={tooltipOpen}
                title={title}
                onClose={tooltipHandleClose}
                onOpen={tooltipHandleOpen}
                placement="bottom"
                arrow
              >
                <TextLimiter variant="h4" ref={titleElement}>
                  {title}
                </TextLimiter>
              </Tooltip>
            </Grid>
            <Grid item xs>
              <TimeDetails
                icon
                date={date.format(new Date(start_time), 'D.M.Y')}
                startTime={date.format(new Date(start_time), 'H:MM')}
              />
            </Grid>
          </Grid>
        </StyledCardContent>
      </Link>

      <CardActions>
        <Grid container justify="space-between">
          <Grid item>
            <Link to={webinarLink}>
              <Button
                variant="text"
                size="small"
                color="primary"
                endIcon={<ArrowForwardIcon />}
              >
                Więcej
              </Button>
            </Link>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default WebinarCard;
