import { createAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  ISocialAuthenticatePayload,
  IUserCheckExist,
  IUserCheckExistDone,
  IUserCheckExistPayload,
  IUserCheckExistPayloadDone,
  IUserPushDetails,
  IUserPushDetailsPayload,
  IUserSetProcess,
  IUSerSetProcessPayload,
  IVerifyingEmailRequest,
  IVerifyingEmailRequestPayload,
  UserActionTypes,
} from './types';
import { IUser } from '../../types/user/IUser';

export const userFacebookAuthenticate = createAction(
  UserActionTypes.USER_FACEBOOK_AUTHENTICATE,
  (token: string): ISocialAuthenticatePayload => ({
    payload: {
      token,
    },
  }),
);

export const userFacebookAuthenticateDone = createAction(
  UserActionTypes.USER_FACEBOOK_AUTHENTICATE_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  }),
);

export const userFacebookAuthenticateFailed = createAction(
  UserActionTypes.USER_FACEBOOK_AUTHENTICATE_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('USER_FACEBOOK_AUTHENTICATE_FAILED', error);
    return { payload: { error } };
  },
);

export const userGoogleAuthenticate = createAction(
  UserActionTypes.USER_GOOGLE_AUTHENTICATE,
  (response: AxiosResponse) => ({
    payload: {
      response,
    },
  }),
);

export const userGoogleAuthenticateDone = createAction(
  UserActionTypes.USER_GOOGLE_AUTHENTICATE_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  }),
);

export const userGoogleAuthenticateFailed = createAction(
  UserActionTypes.USER_GOOGLE_AUTHENTICATE_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('USER_FACEBOOK_AUTHENTICATE_FAILED', error);
    return { payload: { error } };
  },
);

export const userGetDetailsDone = createAction(
  UserActionTypes.USER_GET_DETAILS_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  }),
);

export const userGetDetailsFailed = createAction(
  UserActionTypes.USER_GET_DETAILS_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('USER_GET_DETAILS_FAILED', error);
    return { payload: { error } };
  },
);

export const userRegister = createAction(
  UserActionTypes.USER_REGISTER,
  (
    email: string,
    username: string,
    password: string,
    idInterest: number[],
    image: string,
    agreementRegulations: boolean,
    agreementNewsletter: boolean,
    agreementPersonalised: boolean,
  ) => ({
    payload: {
      email,
      username,
      password,
      idInterest,
      image,
      agreementRegulations,
      agreementNewsletter,
      agreementPersonalised,
    },
  }),
);

export const userRegisterDone = createAction(
  UserActionTypes.USER_REGISTER_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  }),
);

export const userRegisterFailed = createAction(
  UserActionTypes.USER_REGISTER_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('USER_REGISTER_FAILED', error);
    return { payload: { error } };
  },
);

export const verifyingEmail = createAction(
  UserActionTypes.USER_VERIFYING_EMAIL,
  ({
    verificationKey,
  }: IVerifyingEmailRequest): IVerifyingEmailRequestPayload => ({
    payload: {
      verificationKey,
    },
  }),
);

export const verifyingEmailDone = createAction(
  UserActionTypes.USER_VERIFYING_EMAIL_DONE,
  ({ detail } = {}) => ({
    payload: {
      detail,
    },
  }),
);

export const verifyingEmailFailed = createAction(
  UserActionTypes.USER_VERIFYING_EMAIL_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('USER_VERIFYING_EMAIL_FAILED', error);
    return { payload: { error } };
  },
);

export const userEditDetails = createAction(
  UserActionTypes.USER_EDIT_DETAILS,
  (value) => ({
    payload: {
      ...value,
    },
  }),
);

export const userEditImage = createAction(
  UserActionTypes.USER_EDIT_IMAGE,
  (image: string) => ({
    payload: {
      image,
    },
  }),
);
export const userEditImageDone = createAction(
  UserActionTypes.USER_EDIT_IMAGE_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  }),
);

export const userEditImageFailed = createAction(
  UserActionTypes.USER_EDIT_IMAGE_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('USER_EDIT_IMAGE_FAILED', error);
    return { payload: { error } };
  },
);

export const userEditDetailsDone = createAction(
  UserActionTypes.USER_EDIT_DETAILS_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  }),
);

export const userEditDetailsFailed = createAction(
  UserActionTypes.USER_EDIT_DETAILS_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('USER_EDIT_DETAILS_FAILED', error);
    return { payload: { error } };
  },
);

export const userDataWebinarSet = createAction(
  UserActionTypes.USER_DATA_WEBINAR_SET,
  (data = {}, typeWebinar) => ({
    payload: {
      data,
      typeWebinar,
    },
  }),
);

export const userLogoutDone = createAction(
  UserActionTypes.USER_LOGOUT_DONE,
  () => ({
    payload: {},
  }),
);

export const userSignToNewsletter = createAction(
  UserActionTypes.USER_SING_TO_NEWSLETTER,
  (email: string) => ({
    payload: {
      email,
    },
  }),
);

export const userSignToWebinar = createAction(
  UserActionTypes.USER_SIGNIN_WEBINAR,
  (webinarID: number) => ({
    payload: {
      webinarID,
    },
  }),
);

export const userWebinarSignupDone = createAction(
  UserActionTypes.USER_SIGNIN_WEBINAR_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  }),
);

export const userWebinarSignupFailed = createAction(
  UserActionTypes.USER_SIGNIN_WEBINAR_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('USER_SIGNIN_WEBINAR_FAILED', error);
    return { payload: { error } };
  },
);

export const userLeaveWebinar = createAction(
  UserActionTypes.USER_LEAVE_WEBINAR,
  (webinarID: number) => ({
    payload: {
      webinarID,
    },
  }),
);

export const userLeaveWebinarDone = createAction(
  UserActionTypes.USER_LEAVE_WEBINAR_DONE,
  (data = {}) => ({
    payload: {
      data,
    },
  }),
);

export const userLeaveWebinarFailed = createAction(
  UserActionTypes.USER_LEAVE_WEBINAR_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('USER_LEAVE_WEBINAR_FAILED', error);
    return { payload: { error } };
  },
);

export const userCheckExist = createAction(
  UserActionTypes.USER_CHECK_EXIST,
  ({ typeCheck, value }: IUserCheckExist): IUserCheckExistPayload => ({
    payload: {
      typeCheck,
      value,
    },
  }),
);

export const userCheckExistDone = createAction(
  UserActionTypes.USER_CHECK_EXIST_DONE,
  ({
    typeCheck,
    value,
    isAvailable,
  }: IUserCheckExistDone): IUserCheckExistPayloadDone => ({
    payload: {
      typeCheck,
      value,
      isAvailable,
    },
  }),
);

export const userCheckExistFailed = createAction(
  UserActionTypes.USER_CHECK_EXIST_FAILED,
  (error) => {
    // eslint-disable-next-line no-console
    console.error('USER_CHECK_EXIST_FAILED', error);
    return { payload: { error } };
  },
);

export const userSetDetails = createAction(
  UserActionTypes.USER_SET_DETAILS,
  (data: IUser) => ({
    payload: {
      data,
    },
  }),
);

export const userPushDetails = createAction(
  UserActionTypes.USER_PUSH_DETAILS,
  ({ userProperty, value }: IUserPushDetails): IUserPushDetailsPayload => ({
    payload: {
      userProperty,
      value,
    },
  }),
);

export const userClean = createAction(UserActionTypes.USER_CLEAN);

export const userSetProcess = createAction(
  UserActionTypes.USER_SET_PROCESS,
  ({ typeProcess, value }: IUserSetProcess): IUSerSetProcessPayload => ({
    payload: { typeProcess, value },
  }),
);
export const userSocialRegister = createAction(
  UserActionTypes.USER_SOCIAL_REGISTER,
  (value) => ({
    payload: {
      ...value,
    },
  }),
);
