import { takeEvery, put, call } from 'redux-saga/effects';
import * as actions from 'store/user/actions';
import {
  IUserRegisterPayload,
  IUserEditDetailsPayload,
  IUserSignToNewsletterPayload,
  IUserWebinarSignupRequestPayload,
  IUserEditImagePayload,
  IUserWebinarLEaveRequestPayload,
  IVerifyingEmailRequestPayload,
  IUserCheckExistPayload,
  IUserPushDetailsPayload,
  TypeProcess,
} from 'store/user/types';
import APIUser from 'api/user';
import APIRegister from 'api/register';
import { snackbarShow } from '../snackbar/actions';
import { AlertIcon } from '../../constants/alertIcon';

export function* userEditDetailsRequest({
  payload,
}: IUserEditDetailsPayload | any) {
  yield put(
    actions.userSetProcess({
      typeProcess: TypeProcess.editUserDetails,
      value: 'pending',
    }),
  );
  try {
    const response = yield call(APIUser.userEditDetails, { payload });

    const { data, status } = response;
    if (status === 200 || status === 201) {
      yield put(
        actions.userSetProcess({
          typeProcess: TypeProcess.editUserDetails,
          value: 'done',
        }),
      );
      yield put(actions.userEditDetailsDone(data));
    }
    if (status === 400 || status === 401) {
      yield put(
        actions.userSetProcess({
          typeProcess: TypeProcess.editUserDetails,
          value: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      actions.userSetProcess({
        typeProcess: TypeProcess.editUserDetails,
        value: 'error',
      }),
    );
    yield put(actions.userEditDetailsFailed(error));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function* userSocialRegisterSaga({
  payload,
}: IUserEditDetailsPayload | any) {
  try {
    yield put(
      actions.userSetProcess({
        typeProcess: TypeProcess.userRegisterState,
        value: 'pending',
      }),
    );
    const response = yield call(APIUser.userEditDetails, { payload });
    yield put(actions.userEditDetailsDone(response.data));
    yield put(
      actions.userSetProcess({
        typeProcess: TypeProcess.userRegisterState,
        value: 'done',
      }),
    );
  } catch (error) {
    yield put(actions.userEditDetailsFailed(error));
    yield put(
      actions.userSetProcess({
        typeProcess: TypeProcess.userRegisterState,
        value: 'error',
      }),
    );
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function* userPushDetailsSaga({ payload }: IUserPushDetailsPayload) {
  try {
    const response = yield call(APIUser.userPushDetails, { payload });
    yield put(actions.userEditDetailsDone(response.data));
  } catch (error) {
    yield put(actions.userEditDetailsFailed(error));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function* userEditImageRequest({ payload }: IUserEditImagePayload) {
  yield put(
    actions.userSetProcess({
      typeProcess: TypeProcess.userImageChange,
      value: 'pending',
    }),
  );
  try {
    const response = yield call(APIUser.userEditImage, { payload });
    const { status } = response;
    if (status === 200 || status === 201) {
      yield put(
        actions.userSetProcess({
          typeProcess: TypeProcess.userImageChange,
          value: 'done',
        }),
      );
      yield put(actions.userEditImageDone(response));
    }
    if (status === 400 || status === 401) {
      yield put(
        actions.userSetProcess({
          typeProcess: TypeProcess.userImageChange,
          value: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      actions.userSetProcess({
        typeProcess: TypeProcess.userImageChange,
        value: 'error',
      }),
    );
    yield put(actions.userEditImageFailed(error));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function* userRegisterRequest({ payload }: IUserRegisterPayload) {
  try {
    const response = yield call(APIRegister.registerUser, payload);
    if (response.status === 200 || response.status === 201) {
      yield put(actions.userRegisterDone(response));
    }
    if (
      response.status === 400 ||
      response.status === 401 ||
      response.status === 404
    ) {
      const message = JSON.stringify(response.data);
      yield put(actions.userRegisterFailed(response.data));
      yield put(
        snackbarShow({
          payload: {
            message,
            severity: 'warning',
            icon: AlertIcon.INFO,
          },
        }),
      );
    }
  } catch (error) {
    yield put(actions.userRegisterFailed(error));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function* verifyingEmailRequest({
  payload,
}: IVerifyingEmailRequestPayload) {
  try {
    const response = yield call(APIRegister.verifyingEmail, { payload });
    if (response.status === 200) {
      yield put(actions.verifyingEmailDone(response.data));
    }

    if (
      response.status === 400 ||
      response.status === 401 ||
      response.status === 404
    ) {
      const message = 'Błędny link aktywacyjny';
      yield put(actions.verifyingEmailFailed(response.data));
      yield put(
        snackbarShow({
          payload: {
            message,
            severity: 'warning',
            icon: AlertIcon.INFO,
          },
        }),
      );
    }
  } catch (error) {
    yield put(actions.userRegisterFailed(error));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function* userSignToNewsletterRequest({
  payload,
}: IUserSignToNewsletterPayload) {
  yield put(
    actions.userSetProcess({
      typeProcess: TypeProcess.addToNewsletter,
      value: 'pending',
    }),
  );
  try {
    const response = yield call(APIRegister.signToNewsletter, payload);
    const { status } = response;
    if (status === 200 || status === 201) {
      yield put(
        actions.userSetProcess({
          typeProcess: TypeProcess.addToNewsletter,
          value: 'done',
        }),
      );
    }
    if (status === 400 || status === 401) {
      yield put(
        actions.userSetProcess({
          typeProcess: TypeProcess.addToNewsletter,
          value: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      actions.userSetProcess({
        typeProcess: TypeProcess.addToNewsletter,
        value: 'error',
      }),
    );
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function* userSignToWebinarRequest({
  payload,
}: IUserWebinarSignupRequestPayload) {
  try {
    const response = yield call(APIUser.signToWebinar, { payload });
    if (response.status === 200) {
      yield put(actions.userWebinarSignupDone(payload));
    }
  } catch (error) {
    yield put(actions.userWebinarSignupFailed(error));
    // eslint-disable-next-line no-console
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function* userLeaveWebinarRequest({
  payload,
}: IUserWebinarLEaveRequestPayload) {
  try {
    const response = yield call(APIUser.leaveWebinar, { payload });
    if (response.status === 200) {
      yield put(actions.userLeaveWebinarDone(payload));
    }
  } catch (error) {
    yield put(actions.userLeaveWebinarFailed(error));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export function* userCheckExistRequest({ payload }: IUserCheckExistPayload) {
  try {
    const response = yield call(APIRegister.registerUserCheck, payload);
    if (response.status === 200 || response.status === 201) {
      let isValidate = false;
      if (response.data.Result === 'Not exists') {
        isValidate = true;
      }
      yield put(
        actions.userCheckExistDone({
          typeCheck: payload.typeCheck,
          value: payload.value,
          isAvailable: isValidate,
        }),
      );
    }

    if (
      response.status === 400 ||
      response.status === 401 ||
      response.status === 404
    ) {
      const message = JSON.stringify(response.data);

      yield put(actions.userCheckExistFailed(response.data));
      yield put(
        snackbarShow({
          payload: {
            message,
            severity: 'warning',
            icon: AlertIcon.INFO,
          },
        }),
      );
    }
  } catch (error) {
    yield put(actions.userRegisterFailed(error));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default [
  takeEvery(actions.userRegister, userRegisterRequest),
  takeEvery(actions.verifyingEmail, verifyingEmailRequest),
  takeEvery(actions.userEditDetails, userEditDetailsRequest),
  takeEvery(actions.userSocialRegister, userSocialRegisterSaga),
  takeEvery(actions.userEditImage, userEditImageRequest),
  takeEvery(actions.userSignToNewsletter, userSignToNewsletterRequest),
  takeEvery(actions.userSignToWebinar, userSignToWebinarRequest),
  takeEvery(actions.userLeaveWebinar, userLeaveWebinarRequest),
  takeEvery(actions.userCheckExist, userCheckExistRequest),
  takeEvery(actions.userPushDetails, userPushDetailsSaga),
];
