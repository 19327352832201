// import styled from 'styled-components';
// import Avatar from '@material-ui/core/Avatar/Avatar';
// import theme from '../../../scss/themes';
//
// export const StyledAvatar = styled(Avatar)`
//     margin-bottom: 16px;
//     width: 136px;
//     height: 136px;
//
//   ${theme.breakpoints.up('md')} {
//     width: 272px;
//     height: 272px;
//   }
// `

import styled from 'styled-components';
import { Avatar, Box } from '@material-ui/core';
import theme from '../../../scss/themes';

export const CircleButton = styled(Box)`
  .MuiButtonBase-root {
    border-radius: 50%;
  }
`;
export const StyledAvatar = styled(Avatar)`
  width: 232px;
  height: 232px;

  .MuiSvgIcon-root {
    font-size: 45px;
    color: ${theme.palette.primary.main};
  }
`;
