export const routes = {
  HOME: {
    url: '/',
    title: 'Najlepsze Darmowe Webinary w jednym miejscu - darmowewebinary.pl',
    description:
      'Zdobywaj fachową wiedzę nie wychodząc z domu i zupełnie za darmo lub zorgranizuj oraz rozpromuj własne webinarium. Zobacz!',
    keywords: ['sep'],
  },
  B2B: {
    url: '/promuj-wydarzenie',
    title: 'Promuj webinar i zwiększ grupę odbiorców - Darmowe Webinary',
    description:
      'Docieraj do większej grupy odbiorców z kontem dla firm. Twórz razem z nami swój wizerunek eksperckiej i innowacyjnej firmy używając nowoczesnych kanałów!',
    keywords: ['sep'],
  },
  CALENDAR: {
    url: '/kalendarz',
    title: 'Kalendarz nadchodzących webinarów - darmowewebinary.pl',
    description:
      'Aktualna lista nadchodzących, darmowych webinarów. Znajdź wydarzenie odpowiednie dla siebie i dodaj je do swojego kalendarza!',
    keywords: ['sep'],
  },
  WEBINAR: {
    url: '/webinar',
    title: '',
    description: '',
    keywords: ['sep'],
  },
  WEBINAR_ROUTER: {
    url: '/webinar/:webinarParams',
    title: '',
    description: '',
    keywords: ['sep'],
  },
  SAVED_WEBINAR: {
    url: '/moj-profil/zapisane-webinary',
    title: 'Zapisane webinary - darmowewebinary.pl',
    description: '',
    keywords: ['sep'],
  },
  LIKED_WEBINAR: {
    url: '/moj-profil/polubione-webinary',
    title: 'Polubione webinary - darmowewebinary.pl',
    description: '',
    keywords: ['sep'],
  },
  LOGIN: {
    url: '/logowanie',
    title: 'Logowanie - darmowewebinary.pl',
    description:
      'Zaloguj się do swojego konta. Sprawdź swój kalendarz oraz twórz i promuj własne wydarzenia online!',
    keywords: ['sep'],
  },
  LOGOUT: {
    url: '/wyloguj',
    title: 'Wylogowanie - darmowewebinary.pl',
    description: '',
    keywords: ['sep'],
  },
  EMAIL_VERIFICATION: {
    url: '/weryfikacja',
    title: 'Rejestracja - Planuj udział w webinarach lub twórz własne!',
    description:
      'Załóż darmowe konto i zarządzaj wygodnie kalendarzem webinarów. Planuj i przeprowadzaj webinary samodzielnie lub z naszym wsparciem!',
    keywords: ['sep'],
  },
  EMAIL_VERIFICATION_ROUTER: {
    url: '/weryfikacja/:verificationKey',
    title: 'Rejestracja - Planuj udział w webinarach lub twórz własne!',
    description:
      'Załóż darmowe konto i zarządzaj wygodnie kalendarzem webinarów. Planuj i przeprowadzaj webinary samodzielnie lub z naszym wsparciem!',
    keywords: ['sep'],
  },
  REGISTER: {
    url: '/rejestracja',
    title: 'Rejestracja - Planuj udział w webinarach lub twórz własne!',
    description:
      'Załóż darmowe konto i zarządzaj wygodnie kalendarzem webinarów. Planuj i przeprowadzaj webinary samodzielnie lub z naszym wsparciem!',
    keywords: ['sep'],
  },
  REGISTER_SOCIAL: {
    url: '/rejestracja/social-media',
    title: 'Rejestracja - Planuj udział w webinarach lub twórz własne!',
    description:
      'Załóż darmowe konto i zarządzaj wygodnie kalendarzem webinarów. Planuj i przeprowadzaj webinary samodzielnie lub z naszym wsparciem!',
    keywords: ['sep'],
  },
  ADD_WEBINAR: {
    url: '/dodaj-webinar',
    title: 'Dodaj webinar - darmowewebinary.pl',
    description:
      'Dodaj webinar do naszej bazy i dotrzyj do naszych odbiorców. Podziel się informacją o nadchodzącym wydarzeniu lub stwórz własne i opublikuj je w naszym kalendarzu!',
    keywords: ['sep'],
  },
  CATEGORY: {
    url: '/kategorie',
    title: 'Kategorie webinarów - darmowewebinary.pl',
    description:
      'Przeglądaj nadchdodzące webinary w podziale na kategorie tematyczne. Znajdź wydarzenie odpowiednie dla siebie i dodaj je do swojego kalendarza!',
    keywords: ['sep'],
  },
  TAG: {
    url: '/tag',
    title: '',
    description: '',
    keywords: ['sep'],
  },
  CATEGORY_ROUTER: {
    url: '/kategorie/:categoryParams',
    title: '',
    description: '',
    keywords: ['sep'],
  },
  BLOG: {
    url: '/blog',
    title: 'Blog - darmowewebinary.pl',
    description:
      'Baza wiedzy na temat webinarów i wydarzeń online. Tutaj znajdziesz informacje i porady na temat organizacji, realizacji oraz promocji. Sprawdź!',
    keywords: ['sep'],
  },
  BLOG_ROUTER: {
    url: '/blog/:blogPostSlug',
    title: '',
    description: '',
    keywords: ['sep'],
  },
  REGULATIONS: {
    url: '/regulamin',
    title: '',
    description: '',
    keywords: ['sep'],
  },
  REGULATIONS_FILE: {
    url: '/regulamin.pdf',
    title: '',
    description: '',
    keywords: ['sep'],
  },
  PRIVACY_POLICY: {
    url: '/polityka-prywatnosci',
    title: '',
    description: '',
    keywords: ['sep'],
  },
  PRIVACYPOLICY_FILE: {
    url: '/polityka-prywatnosci.pdf',
    title: '',
    description: '',
    keywords: ['sep'],
  },
  USER: {
    url: '/moj-profil',
    title: '',
    description: '',
    keywords: ['sep'],
  },
  USER_DEACTIVATE: {
    url: '/moj-profil/deaktywuj',
    title: '',
    description: '',
    keywords: ['sep'],
  },
  PLAYER: {
    url: '/odtwarzacz',
    title: '',
    description: '',
    keywords: ['sep'],
  },
};

export const fileRoutes = {
  OPEN_GRAPH: {
    image: '/baner.png',
  },
  REGULATIONS_FILE: {
    url: '/regulamin.pdf',
    title: '',
    description: '',
    keywords: ['sep'],
  },
  PRIVACYPOLICY_FILE: {
    url: '/polityka-prywatnosci.pdf',
    title: '',
    description: '',
    keywords: ['sep'],
  },
};

export interface IRouterDetails {
  url: string | null;
  title: string | null;
  description: string | null;
  keywords: string[] | null;
}
