import React, { FC } from 'react';
import Input from '../Input';
import { IFormikControlProps } from './index';
import CheckboxGroup from '../CheckboxGroup';
import Checkbox from '../Checkbox';
import TimePicker from '../TimePicker';
import DatePicker from '../DatePicker';
import Textarea from '../Textarea';
import TextEditor from '../TextEditor';

const FormikControl: FC<IFormikControlProps> = ({
  controlType,
  defaultValue = '',
  type,
  label,
  name,
  size = 'small',
  paddingBottom = 2,
  helperText = '',
  isMultiselect = false,
  rows,
  multiline,
  variant,
  onChange,
  onClick,
  onBlur,
  lowerCase,
  ...rest
}) => {
  switch (controlType) {
    case 'input':
      return (
        <Input
          type={type}
          label={label}
          name={name}
          defaultValue={defaultValue}
          paddingBottom={paddingBottom}
          helperText={helperText}
          size={size}
          onBlur={onBlur}
          lowerCase={lowerCase}
        />
      );
    case 'textarea':
      return (
        <Textarea
          type={type}
          label={label}
          name={name}
          defaultValue={defaultValue}
          paddingBottom={paddingBottom}
          helperText={helperText}
          size={size}
          rows={rows}
          multiline={multiline}
          variant={variant}
          {...rest}
        />
      );
    case 'textEditor':
      return (
        <TextEditor
          label={label}
          name={name}
          paddingBottom={paddingBottom}
          helperText={helperText}
          {...rest}
        />
      );
    case 'checkboxGroup':
      return (
        <CheckboxGroup
          name={name}
          paddingBottom={paddingBottom}
          isMultiselect={isMultiselect}
          onClick={onClick}
          {...rest}
        />
      );
    case 'timePicker':
      return (
        <TimePicker
          type={type}
          label={label}
          name={name}
          paddingBottom={paddingBottom}
          helperText={helperText}
          size={size}
          {...rest}
        />
      );
    case 'datePicker':
      return (
        <DatePicker
          type={type}
          label={label}
          name={name}
          paddingBottom={paddingBottom}
          helperText={helperText}
          size={size}
          {...rest}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          onChange={onChange}
          name={name}
          label={label}
          helperText={helperText}
          paddingBottom={paddingBottom}
          {...rest}
        />
      );
    default:
      return null;
  }
};

export default FormikControl;
