import React, { FC } from 'react';
import AppLayout from 'layout/AppLayout';
import LoginForm from 'components/Form/LoginForm';
import { IPageLoginProps } from './index';

const PageLogin: FC<IPageLoginProps> = () => (
  <AppLayout bgColor="#ECF1FD">
    <LoginForm />
  </AppLayout>
);

export default PageLogin;
