import styled from 'styled-components';
import { CardContent, Typography } from '@material-ui/core';
import theme from '../../scss/themes';

const mobileFontSize = 14;
const mobileLineHeight = 1.4;
const mobileLinesToShow = 4;

const fontSize = 18;
const lineHeight = 1.8;
const linesToShow = 3;

const getHeight = (
  _fontSize: number,
  _lineHeight: number,
  _linesToShow: number,
) => _fontSize * _lineHeight * _linesToShow;

export const TextLimiter = styled(Typography)`
  display: block;
  display: -webkit-box;
  height: ${getHeight(mobileFontSize, mobileLineHeight, mobileLinesToShow)}px;
  min-height: ${getHeight(
    mobileFontSize,
    mobileLineHeight,
    mobileLinesToShow,
  )}px;
  font-size: ${mobileFontSize}px;
  line-height: ${mobileLineHeight};
  -webkit-line-clamp: ${mobileLinesToShow};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${theme.breakpoints.up('md')} {
    height: ${getHeight(fontSize, lineHeight, linesToShow)}px;
    min-height: ${getHeight(fontSize, lineHeight, linesToShow)}px;
    font-size: ${fontSize}px;
    line-height: ${lineHeight};
    -webkit-line-clamp: ${linesToShow};
  }
`;

export const StyledCardContent = styled(CardContent)`
  padding: 8px 8px 0;
  ${theme.breakpoints.up('md')} {
    padding: 16px 16px 0;
  }
`;
