export const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://darmowe-webinary.herokuapp.com'
    : 'https://darmowe-webinary.herokuapp.com';
export const fireBaseURL =
  'https://darmowewebinary-default-rtdb.europe-west1.firebasedatabase.app';
export const blogBaseURL = 'https://blog.darmowewebinary.pl/wp-json/wp/v2/';
export const endPoint = {
  categories: '/categories/',
  logout: '/api/logout/',
  login: '/api/login/',
  facebookAuth: '/social-login/facebook/',
  googleAuth: '/social-login/google/',
  googleAuthLocal: '/social-login/google-local/',
  linkedInAuth: '/social-login/linkedin/',
  authRefresh: '/auth/token/refresh/',
  webinars: '/webinars/',
  user: '/user/',
  join: '/join/',
  leave: '/leave/',
  register: '/auth/registration/',
  verifyingEmail: '/auth/registration/verify-email/',
  checkUserEmail: '/check-email-username/',
  newsletter: '/newsletter/',
  stream: {
    webinarToken: '/webinar-token/',
    videoId: '/video-id/',
    start: '/start-stream/',
    stop: '/stop-stream/',
  },
  chat: {
    message: '/send-chat-message/',
    chat: '/chat/public',
  },
  blog: {
    post: '/posts',
  },
};
