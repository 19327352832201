import axios from 'axios';
import { blogBaseURL, endPoint } from '../constants/apiEndpoint';

const axiosInstance = axios.create({
  baseURL: blogBaseURL,
});

const getBlogPost = () =>
  axiosInstance
    .get(endPoint.blog.post)
    .then((response) => response)
    .catch((err) => err);

export default { getBlogPost };
