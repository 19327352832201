import React from 'react';
import { Field, useField } from 'formik';
import { Box, TextField } from '@material-ui/core';

const StyledTextField = ({
  helperText,
  field,
  label,
  type,
  size,
  defaultValue,
  onBlur,
  lowerCase,
}: any) => {
  const [hookField, meta, helpers] = useField(field);
  const { setValue } = helpers;

  const isError = () => !!(meta.error && meta.touched);
  return (
    <TextField
      fullWidth
      {...hookField}
      defaultValue={defaultValue}
      label={label}
      type={type}
      variant="outlined"
      size={size}
      error={isError()}
      helperText={isError() ? meta.error : helperText}
      onBlur={onBlur}
      onChange={(event) => {
        if (lowerCase === true) {
          setValue(event.target.value.toLowerCase());
        } else {
          setValue(event.target.value);
        }
      }}
    />
  );
};

function Input({
  type,
  label,
  name,
  paddingBottom,
  size,
  defaultValue,
  onBlur,
  lowerCase,
  ...rest
}: any) {
  return (
    <Box paddingBottom={paddingBottom}>
      <Field
        fullWidth
        name={name}
        component={StyledTextField}
        type={type}
        label={label}
        defaultValue={defaultValue}
        onBlur={onBlur}
        lowerCase={lowerCase}
        {...rest}
      />
    </Box>
  );
}

export default Input;
