import { ICategories, ICategoriesState } from 'types/category/ICategories';
import { TRootState } from 'types/reduxTypes';

export const getCategories = (state: TRootState) => state.categories;

export function getMapCategories(state: TRootState): ICategories[] {
  const categories = getCategories(state);
  const { mapCategories }: ICategoriesState = categories;

  return mapCategories;
}

// eslint-disable-next-line max-len
export function getCategoryById(
  state: TRootState,
  id: number | string | null = null
): ICategories | null {
  if (id === null) {
    return null;
  }

  const searchId = Number(id);
  const mapCategories = getMapCategories(state);

  return mapCategories.filter(
    (oneCategories: { id: number }) => oneCategories.id === searchId
  )[0];
}

// eslint-disable-next-line max-len
export function getCategoryBySlug(
  state: TRootState,
  slug: string | null = null
): ICategories | null {
  if (slug === null) {
    return null;
  }

  const searchSlug = String(slug.toLowerCase());
  const mapCategories = getMapCategories(state);

  return mapCategories.filter(
    (oneCategories) => oneCategories.slug === searchSlug
  )[0];
}

// eslint-disable-next-line max-len
export function getCategoryByName(
  state: TRootState,
  categoryName: number | string | null = null
): any | null {
  if (categoryName === null) {
    return null;
  }

  const mapCategories = getMapCategories(state);
  // eslint-disable-next-line max-len
  const findCategory = mapCategories.filter(
    (oneCategories: { name: string }) => oneCategories.name === categoryName
  )[0];

  return findCategory || null;
}

// eslint-disable-next-line max-len
export function getCategoryIdByName(
  state: TRootState,
  categoryName: number | string | null = null
): any | null {
  if (categoryName === null) {
    return null;
  }

  const mapCategories = getMapCategories(state);
  // eslint-disable-next-line max-len
  const findCategory = mapCategories.filter(
    (oneCategories: { name: string }) => oneCategories.name === categoryName
  )[0];

  // TODO: poczekać na dane i nie zwracać null
  return findCategory?.id || null;
}
